import axios from "axios";
import {
  CREATE_ORGANIZATION_FAIL,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  GET_BILL_BREAKDOWN_FAIL,
  GET_BILL_BREAKDOWN_REQUEST,
  GET_BILL_BREAKDOWN_SUCCESS,
  GET_BILL_INVOICES_FAIL,
  GET_BILL_INVOICES_REQUEST,
  GET_BILL_INVOICES_SUCCESS,
  GET_CURRENT_BILL_FAIL,
  GET_CURRENT_BILL_REQUEST,
  GET_CURRENT_BILL_SUCCESS,
  GET_ORG_AGENTS_FAIL,
  GET_ORG_AGENTS_REQUEST,
  GET_ORG_AGENTS_SUCCESS,
  GET_ORG_PATIENTS_FAIL,
  GET_ORG_PATIENTS_REQUEST,
  GET_ORG_PATIENTS_SUCCESS,
  GET_ORG_PAYMENT_DETAILS_FAIL,
  GET_ORG_PAYMENT_DETAILS_REQUEST,
  GET_ORG_PAYMENT_DETAILS_SUCCESS,
  GET_ORGANIZATION_FAIL,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  GET_PAYMENT_INVOICE_FAIL,
  GET_PAYMENT_INVOICE_REQUEST,
  GET_PAYMENT_INVOICE_SUCCESS,
  RESET_CREATE_ORGANIZATION,
  RESET_PAYMENT_INVOICE,
  UPDATE_ORGANIZATION_FAIL,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
} from "../../assets/constants/organizationConstants";
import Cookies from "js-cookie";
import { BASE_URL } from "..";

// ================= CREATE WORKSPACE/AGENCY REDUCER ==================
export const createOrganizationFunc =
  (organizationObj) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_ORGANIZATION_REQUEST });

      const token = Cookies.get("token");

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/organization/create`,
        organizationObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({ type: CREATE_ORGANIZATION_SUCCESS, payload: response?.data });
      Cookies.set("org_id_vita", response?.data?.organization?.id);
    } catch (error) {
      dispatch({
        type: CREATE_ORGANIZATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetCreateOrgFunc = () => (dispatch) =>
  dispatch({ type: RESET_CREATE_ORGANIZATION });

// ================= GET ORGANIZATION REDUCER ==================
export const getOrganizationFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ORGANIZATION_REQUEST });

    const token = Cookies.get("token");
    const orgId = Cookies.get("org_id_vita");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/organization/get?organization_id=${orgId}`,
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({ type: GET_ORGANIZATION_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({
      type: GET_ORGANIZATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================= GET ORGANIZATION AGENTS REDUCER ==================
export const getOrgAgentsFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ORG_AGENTS_REQUEST });

    const token = Cookies.get("token");
    const orgId = Cookies.get("org_id_vita");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/organization/get-agents?organization_id=${orgId}`,
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({ type: GET_ORG_AGENTS_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({
      type: GET_ORG_AGENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================= UPDATE ORGANIZATION REDUCER ==================
export const updateOrganizationFunc = (orgData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ORGANIZATION_REQUEST });

    const token = Cookies.get("token");
    const orgId = Cookies.get("org_id_vita");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${BASE_URL}/organization/update`,
      orgData,
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({ type: UPDATE_ORGANIZATION_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({
      type: UPDATE_ORGANIZATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================= GET ORGANIZATION PATIENTS COUNT REDUCER ==================
export const getOrgPatientsCountFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ORG_PATIENTS_REQUEST });

    const token = Cookies.get("token");
    const orgId = Cookies.get("org_id_vita");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/organization/get-patients-count?organization_id=${orgId}`,
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({ type: GET_ORG_PATIENTS_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({
      type: GET_ORG_PATIENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================= GET CURRENT MONTH BILL REDUCER ==================
export const getCurrentMonthBillFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CURRENT_BILL_REQUEST });

    const token = Cookies.get("token");
    const orgId = Cookies.get("org_id_vita");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/subscription/get-current-bill?organization_id=${orgId}`,
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({ type: GET_CURRENT_BILL_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({
      type: GET_CURRENT_BILL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================= GET CURRENT MONTH BILL REDUCER ==================
export const getOrgPaymentDetailsFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ORG_PAYMENT_DETAILS_REQUEST });

    const token = Cookies.get("token");
    const orgId = Cookies.get("org_id_vita");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/subscription/get-payment-details?organization_id=${orgId}`,
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({
      type: GET_ORG_PAYMENT_DETAILS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ORG_PAYMENT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================= GET BILL BREAKDOWN REDUCER ==================
export const getBillBreakdownFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_BILL_BREAKDOWN_REQUEST });

    const token = Cookies.get("token");
    const orgId = Cookies.get("org_id_vita");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/subscription/get-practice-breakdown?organization_id=${orgId}`,
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({
      type: GET_BILL_BREAKDOWN_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_BILL_BREAKDOWN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================= GET PAYMENT INVOICE REDUCER ==================
export const getPaymentInvoiceFunc =
  (agencyId, invoiceId, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: GET_PAYMENT_INVOICE_REQUEST });

      const token = Cookies.get("token");

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let response;

      if (invoiceId === null) {
        response = await axios.get(
          `${BASE_URL}/subscription/export-practice-billables?agency_id=${agencyId}&start_date=${startDate}&end_date=${endDate}`,
          config
        );
      } else {
        response = await axios.get(
          `${BASE_URL}/subscription/export-practice-billables?agency_id=${agencyId}&invoice_id=${invoiceId}&start_date=${startDate}&end_date=${endDate}`,
          config
        );
      }

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_PAYMENT_INVOICE_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PAYMENT_INVOICE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetPaymentInvoiceFunc = () => (dispatch) =>
  dispatch({ type: RESET_PAYMENT_INVOICE });

// ================= GET BILL INVOICES REDUCER ==================
export const getBillInvoicesFunc = () => async (dispatch) => {
  try {
    dispatch({ type: GET_BILL_INVOICES_REQUEST });

    const token = Cookies.get("token");
    const orgId = Cookies.get("org_id_vita");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/subscription/get-invoices?organization_id=${orgId}`,
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({
      type: GET_BILL_INVOICES_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: GET_BILL_INVOICES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
