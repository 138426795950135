import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BgBox from "../../../common/BgBox";
import CardUI from "../../../common/Card";
import { LoadingButton } from "@mui/lab";
import HeaderText from "../../../common/HeaderText";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SetupForm from "../../../components/SetupForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const BillingCardDetails = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;

  const options = {
    clientSecret: state?.client_secret,
  };

  useEffect(() => {
    if (state === null) {
      navigate(-1);
      return;
    }
    // Cookies.set("route", "/billing-details");
  }, []);

  if (state === null) {
    return;
  }

  return (
    <BgBox>
      <CardUI maxWidth={570} paddingTop="40px">
        {/* MCC LOGO */}
        {/* <MccLogo /> */}

        <Grid container component="main">
          <Stack xs={12} gap="16px" width="100%">
            <HeaderText
              variant="h3"
              fontSize={48}
              textContent="Billing Information"
              lineHeight="116.7%"
              textAlign="initial"
            />
            <Typography
              variant="body1"
              className="d-flex"
              fontSize={16}
              color="#566875"
            >
              To complete your registration, please provide your billing
              information. Your card details will be securely stored for future
              billing purposes.
            </Typography>

            <Typography variant="body2" fontWeight={500}>
              Fill your details below
            </Typography>

            {/* STRIPE COMPONENT COMES HERE */}
            {state?.client_secret && (
              <Elements stripe={stripePromise} options={options}>
                <SetupForm {...props} />
              </Elements>
            )}
          </Stack>
        </Grid>
      </CardUI>
    </BgBox>
  );
};

export default BillingCardDetails;
