import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BgBox from "../../../common/BgBox";
import CardUI from "../../../common/Card";
import MccLogo from "../../../components/MccLogo";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import HeaderText from "../../../common/HeaderText";
import { LoadingButton } from "@mui/lab";

const TermsOfService = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const agentData = state && state.agentData;

  const [checkedTos, setCheckedTos] = useState(false);

  useEffect(() => {
    if (state === null) {
      navigate(-1);
      return;
    }
  }, []);

  if (state === null) {
    navigate(-1);
    return;
  }

  const navigateWorkSpaceHandler = () => {
    if (!checkedTos) {
      return;
    }

    navigate("/create-workspace", {
      replace: true,
      state: { termsOfService: true, agentData: agentData },
    });
  };

  return (
    <BgBox>
      <CardUI>
        {/* MCC LOGO */}
        <MccLogo />

        <Grid container component="main">
          <Box
            component="form"
            onSubmit={navigateWorkSpaceHandler}
            xs={12}
            display="flex"
            gap="16px"
            flexDirection="column"
            width="100%"
          >
            <Box>
              <HeaderText
                variant="h3"
                fontSize={48}
                textContent="Terms of service"
              />
              <Typography variant="body1" fontSize={16} mt="16px">
                Please read and agree to the terms of service to continue.{" "}
              </Typography>

              <Typography variant="body1" fontSize={16} mt="16px">
                <Box
                  component="a"
                  href="https://vitatrax.co/practice-terms-of-services/"
                  target="_blank"
                  color="#0C80F3"
                  sx={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {" "}
                  Click here
                </Box>{" "}
                to read the terms of service{" "}
              </Typography>

              <Box my="16px">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={checkedTos}
                      onChange={(e) => setCheckedTos(e.target.checked)}
                    />
                  }
                  label="I have read and agree to the Terms of Service"
                />
              </Box>
            </Box>

            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              disabled={!checkedTos}
              sx={{ padding: "8px 22px", fontSize: 15 }}
            >
              Continue
            </LoadingButton>
          </Box>
        </Grid>
      </CardUI>
    </BgBox>
  );
};

export default TermsOfService;
