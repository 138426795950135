import React from "react";
import AvatarWithName from "../AvatarWithName";
import { Avatar, AvatarGroup, Box, Tooltip } from "@mui/material";
import {
  getRandomAvatarBackgroundColor,
  stringAvatar,
} from "../../utils/helpers";

const SingleAvatar = ({ team_member, avatarBgColor }) => {
  return (
    <Tooltip title={team_member?.name}>
      {team_member?.profile_picture_url ? (
        <Avatar
          alt={team_member?.name}
          src={team_member?.profile_picture_url}
        />
      ) : (
        <Avatar
          {...stringAvatar(team_member?.name)}
          sx={{
            backgroundColor: avatarBgColor,
          }}
        />
      )}
    </Tooltip>
  );
};

const AvatarGroups = ({
  assigned_to_details,
  avatarBgColor,
  fontSize = "0.875rem",
  color = "inherit",
  width = 32,
  height = 32,
  avatarFontSize = "16px",
  maxNumber,
}) => {
  return (
    <>
      {assigned_to_details?.length < 1 ? (
        "-"
      ) : assigned_to_details?.length === 1 ? (
        <AvatarWithName
          name={assigned_to_details[0]?.name}
          imageURL={assigned_to_details[0]?.profile_picture_url}
          avatarBgColor={assigned_to_details[0]?.avatar_color}
          fontSize={fontSize}
          color={color}
          size={width}
          avatarFontSize={avatarFontSize}
        />
      ) : (
        <Box sx={{ height: "100%" }}>
          <AvatarGroup
            max={maxNumber && maxNumber}
            sx={{
              justifyContent: "flex-end",
              height: "100%",
              alignItems: "center",
              "& .MuiAvatar-root": {
                width: width,
                height: height,
                fontSize: "0.875rem",
              },
            }}
          >
            {assigned_to_details?.map((team_member, i) => (
              <SingleAvatar
                team_member={team_member}
                key={i}
                avatarBgColor={team_member?.avatar_color}
              />
            ))}
          </AvatarGroup>
        </Box>
      )}
    </>
  );
};

export default AvatarGroups;
