import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ImportBoxIcon, ImportIcon } from "../../assets/constants";
import { useDropzone } from "react-dropzone";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { formatSizeUnits } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import ImportButtonActions from "../../components/ImportButtonActions";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  importPatientFileFunc,
  resetImportFile,
  setImportIdFunc,
} from "../../actions/ImportFileActions";
import styled from "@emotion/styled";

const StyledTypography = styled(Typography)(() => ({
  cursor: "pointer",
  color: "#0C80F3",
  textDecoration: "underline",
}));

const ImportPatients = () => {
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState(new FormData());
  const [showAssignTable, setShowAssignTable] = useState(false);

  const agencyId = Cookies.get("agency_id_vita");
  const dispatch = useDispatch();

  const rootClassName = `wfp--dropzone__input`;
  const navigate = useNavigate();

  const importPatientFile = useSelector((state) => state.importPatientFile);
  const {
    loading: loadingFile,
    error: errorFile,
    importedPatientFile,
  } = importPatientFile;

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/vnd.ms-excel": [".xls", ".xlsx"],
      "text/csv": [".csv"],
    },
    onDrop: (acceptedFiles) => {
      handleFiles(acceptedFiles);
    },
    maxFiles: 1,
    multiple: false,
  });

  const validFileTypes = [".xls", ".xlsx", ".csv"];

  const isValidFileType = (file) => {
    return validFileTypes.some((type) =>
      file.name.toLowerCase().endsWith(type)
    );
  };

  const handleFiles = (acceptedFiles) => {
    const filteredFiles = Array.from(acceptedFiles).filter((file) => {
      if (!isValidFileType(file)) {
        // setError(`Invalid file type: ${file.name}`);
        return false;
      }
      return true;
    });

    if (filteredFiles.length > 0) {
      // setError(null); // Clear any previous error
      const localFormData = new FormData();
      // filteredFiles.forEach((file) => {
      //   console.log("FFF", file);
      //   localFormData.append("file", file);
      // });
      const file = filteredFiles[0];
      localFormData.append("file", file);

      setFiles([file]);
      setFormData(localFormData);
    }
  };

  const hiddenFileInputRef = useRef(null);

  const handleFileClick = () => {
    hiddenFileInputRef?.current?.click();
  };

  const handleFileChange = (event) => {
    const filesFromInput = event.target.files;
    handleFiles(filesFromInput);
  };

  const removeFileHandler = (index) => {
    setFiles((prevFiles) => prevFiles?.filter((_, i) => i !== index));
  };

  const nextStepHandler = () => {
    if (!files.length) {
      return;
    }

    formData.set("agency_id", agencyId);
    dispatch(importPatientFileFunc(formData));
  };

  useEffect(() => {
    if (importedPatientFile?.ok) {
      dispatch(setImportIdFunc(importedPatientFile?.import_id));
      navigate("/assign-patients", { replace: true });
      dispatch(resetImportFile());
    }
  }, [importedPatientFile]);

  return (
    <Stack padding="20px 40px" paddingBottom="40px" height="100%" gap="24px">
      <Box>
        <Typography variant="h5" fontSize="30px" fontWeight={500}>
          Import Patients
        </Typography>
      </Box>

      <Stack
        height="100%"
        border="1px solid #D9D9D9"
        bgcolor="#fff"
        borderRadius="8px"
      >
        <Stack height="100%" width="100%" gap="32px" padding="32px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" gap="8px">
              <ImportIcon />
              <Typography variant="body1" fontSize="20px" fontWeight={500}>
                Import from a File
              </Typography>
            </Box>
            <ImportButtonActions
              nextStepHandler={nextStepHandler}
              disabled={!files?.length}
              loading={loadingFile}
            />
          </Box>

          {files?.length ? (
            <Stack gap="12px" height="100%">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="body2"
                  fontWeight={500}
                  color="rgba(0, 0, 0, 0.60)"
                >
                  Files
                </Typography>
                {/* <Button
                  variant="text"
                  endIcon={<AddIcon />}
                  onClick={handleFileClick}
                >
                  Add File
                </Button> */}
              </Box>
              <Stack gap="12px">
                {" "}
                {files?.map((item, index) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bgcolor="#F4F4F4"
                    gap="4px"
                    key={index}
                    padding="15px 16px"
                  >
                    <Stack>
                      <Typography variant="body2"> {item?.name}</Typography>
                      <Typography
                        variant="body2"
                        fontSize="12px"
                        color="#566875"
                      >
                        {" "}
                        {formatSizeUnits(item?.size)}
                      </Typography>
                    </Stack>
                    <input
                      ref={hiddenFileInputRef}
                      type="file"
                      multiple
                      accept=".xls,.xlsx,.csv"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <IconButton
                      sx={{ cursor: "pointer" }}
                      onClick={() => removeFileHandler(index)}
                    >
                      <CloseIcon sx={{ fontSize: "16px" }} />
                    </IconButton>
                  </Box>
                ))}
              </Stack>
            </Stack>
          ) : (
            <Stack className="wfp--dropzone" height="100%">
              <Stack
                height="100%"
                {...getRootProps({ className: rootClassName })}
              >
                <input {...getInputProps()} />
                <Stack
                  gap="16px"
                  bgcolor="#F4F6F8"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  sx={{ cursor: "pointer" }}
                  // onClick={importFilesHandler}
                >
                  <ImportBoxIcon />
                  <Stack alignItems="center">
                    <Typography variant="body1">
                      Click or drag file to this area to upload
                    </Typography>
                    <Typography variant="body2" color="rgba(0, 0, 0, 0.45)">
                      Max file size is 500mb. Supported file types are .xls and
                      .csv{" "}
                    </Typography>
                  </Stack>
                  <Typography variant="body2" color="rgba(0, 0, 0, 0.45)">
                    Download sample file{" "}
                    <StyledTypography
                      onClick={(e) => e.stopPropagation()}
                      component="a"
                      href="https://vitatraxprodstorage.blob.core.windows.net/public-resources/VT_Bulk_Sample_Data.csv"
                    >
                      CSV
                    </StyledTypography>{" "}
                    or{" "}
                    <StyledTypography
                      onClick={(e) => e.stopPropagation()}
                      component="a"
                      href="https://vitatraxprodstorage.blob.core.windows.net/public-resources/VT_Bulk_Sample_Data.xlsx"
                    >
                      XLS
                    </StyledTypography>{" "}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ImportPatients;
