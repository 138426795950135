import {
  ADD_SCAN_FAIL,
  ADD_SCAN_REQUEST,
  ADD_SCAN_SUCCESS,
  //   CREATE_AGENCY_FAIL,
  //   CREATE_AGENCY_REQUEST,
  //   CREATE_AGENCY_SUCCESS,
  CREATE_WORKSPACE_FAIL,
  CREATE_WORKSPACE_REQUEST,
  CREATE_WORKSPACE_SUCCESS,
  DOWNLOAD_QR_CODE_FAIL,
  DOWNLOAD_QR_CODE_REQUEST,
  DOWNLOAD_QR_CODE_SUCCESS,
  GET_AGENCY_EMAIL_FAIL,
  GET_AGENCY_EMAIL_REQUEST,
  GET_AGENCY_EMAIL_SUCCESS,
  GET_AGENCY_FAIL,
  GET_AGENCY_REQUEST,
  GET_AGENCY_SUCCESS,
  GET_ALL_AGENCIES_FAIL,
  GET_ALL_AGENCIES_REQUEST,
  GET_ALL_AGENCIES_SUCCESS,
  GET_ALL_CLIENTS_FAIL,
  GET_ALL_CLIENTS_REQUEST,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_UNPAGINATED_FAIL,
  GET_ALL_CLIENTS_UNPAGINATED_REQUEST,
  GET_ALL_CLIENTS_UNPAGINATED_SUCCESS,
  GET_QR_CODE_FAIL,
  GET_QR_CODE_REQUEST,
  GET_QR_CODE_SUCCESS,
  RESET_ALL_AGENCIES,
  RESET_ALL_CLIENTS,
  RESET_CREATE_WORKSPACE,
  RESET_RESHARE_AGENCY_CREDS,
  RESHARE_AGENCY_CREDS_FAIL,
  RESHARE_AGENCY_CREDS_REQUEST,
  RESHARE_AGENCY_CREDS_SUCCESS,
  UPDATE_AGENCY_FAIL,
  UPDATE_AGENCY_REQUEST,
  UPDATE_AGENCY_SUCCESS,
} from "../../assets/constants/agencyConstants";
import {
  ADD_NEW_CLIENT_FAIL,
  ADD_NEW_CLIENT_REQUEST,
  ADD_NEW_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  GET_AGENT_LISTS_FAIL,
  GET_AGENT_LISTS_REQUEST,
  GET_AGENT_LISTS_SUCCESS,
  GET_AGENT_PROFILE_FAIL,
  GET_AGENT_PROFILE_REQUEST,
  GET_AGENT_PROFILE_SUCCESS,
  GET_CLIENT_DATA_FAIL,
  GET_CLIENT_DATA_REQUEST,
  GET_CLIENT_DATA_SUCCESS,
  RESET_UPDATE_AGENT_TEAM_MEMBERS,
  RESET_DELETE_CLIENT,
  UPDATE_AGENT_PROFILE_FAIL,
  UPDATE_AGENT_PROFILE_REQUEST,
  UPDATE_AGENT_PROFILE_SUCCESS,
  UPDATE_AGENT_TEAM_MEMBERS_FAIL,
  UPDATE_AGENT_TEAM_MEMBERS_REQUEST,
  UPDATE_AGENT_TEAM_MEMBERS_SUCCESS,
  UPDATE_CLIENT_FAIL,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  RESET_AGENT_LISTS,
  INVITE_AGENT_REQUEST,
  INVITE_AGENT_SUCCESS,
  INVITE_AGENT_FAIL,
  CHECK_PATIENT_EMAIL_REQUEST,
  CHECK_PATIENT_EMAIL_SUCCESS,
  CHECK_PATIENT_EMAIL_FAIL,
  RESET_CHECK_PATIENT_EMAIL,
  RESET_INVITE_AGENT,
  CHECK_PATIENT_PHONE_REQUEST,
  CHECK_PATIENT_PHONE_SUCCESS,
  CHECK_PATIENT_PHONE_FAIL,
  RESET_CHECK_PATIENT_PHONE,
  DELETE_AGENT_REQUEST,
  DELETE_AGENT_SUCCESS,
  DELETE_AGENT_FAIL,
  RESET_DELETE_AGENT,
} from "../../assets/constants/agentConstants";

// INVITE AGENT REDUCER
export const inviteAgentReducer = (state = {}, action) => {
  switch (action.type) {
    case INVITE_AGENT_REQUEST:
      return { loading: true };
    case INVITE_AGENT_SUCCESS:
      return { loading: false, invitedAgent: action.payload };
    case INVITE_AGENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_INVITE_AGENT:
      return {};
    default:
      return state;
  }
};

// GET AGENT PROFILE REDUCER
export const getAgentProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AGENT_PROFILE_REQUEST:
      return { loading: true };
    case GET_AGENT_PROFILE_SUCCESS:
      return { loading: false, agentUser: action.payload };
    case GET_AGENT_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ================ UPDATE AGENT REDUCER  ====================
export const agentUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AGENT_PROFILE_REQUEST:
      return { loading: true };
    case UPDATE_AGENT_PROFILE_SUCCESS:
      return { loading: false, success: true, agentInfo: action.payload };
    case UPDATE_AGENT_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ================ DELETE AGENT REDUCER  ====================
export const deleteAgentReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_AGENT_REQUEST:
      return { loading: true };
    case DELETE_AGENT_SUCCESS:
      return { loading: false, agentDeleted: action.payload };
    case DELETE_AGENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_DELETE_AGENT:
      return {};
    default:
      return state;
  }
};

//================   CREATE WORKSPACE REDUCER ================
export const createWorkspaceReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_WORKSPACE_REQUEST:
      return { loading: true };
    case CREATE_WORKSPACE_SUCCESS:
      return { loading: false, workspace: action.payload };
    case CREATE_WORKSPACE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CREATE_WORKSPACE:
      return {};
    default:
      return state;
  }
};

//================   GET ALL AGENTS LISTS REDUCER ================
export const getAgentsListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AGENT_LISTS_REQUEST:
      return { loading: true };
    case GET_AGENT_LISTS_SUCCESS:
      return { loading: false, allAgents: action.payload };
    case GET_AGENT_LISTS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_AGENT_LISTS:
      return {};
    default:
      return state;
  }
};

//================  UPDATE ASSIGNED AGENT TEAM MEMBERS REDUCER ================
export const updateAgentTeamMembersReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AGENT_TEAM_MEMBERS_REQUEST:
      return { loading: true };
    case UPDATE_AGENT_TEAM_MEMBERS_SUCCESS:
      return { loading: false, updatedAgentTeamMembers: action.payload };
    case UPDATE_AGENT_TEAM_MEMBERS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UPDATE_AGENT_TEAM_MEMBERS:
      return {};
    default:
      return state;
  }
};

//================  GET AGENCY REDUCER ================
export const getAgencyReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AGENCY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_AGENCY_SUCCESS:
      return { ...state, loading: false, agencyDetail: action.payload };
    case GET_AGENCY_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//================  UPDATE AGENCY REDUCER ================
export const updateAgencyReducer = (
  state = { loading: false, updatedAgency: {}, error: null },
  action
) => {
  switch (action.type) {
    case UPDATE_AGENCY_REQUEST:
      return {
        ...state,
        loading: true,
        updatedAgency: {},
      };
    case UPDATE_AGENCY_SUCCESS:
      return { ...state, loading: false, updatedAgency: action.payload };
    case UPDATE_AGENCY_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//================  GET QR CODE REDUCER ================
export const getQrCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_QR_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_QR_CODE_SUCCESS:
      return { ...state, loading: false, qrCodeImage: action.payload };
    case GET_QR_CODE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//================  DOWNLOAD QR CODE REDUCER ================
export const downloadQrCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case DOWNLOAD_QR_CODE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DOWNLOAD_QR_CODE_SUCCESS:
      return { ...state, loading: false, downloadedQrCode: action.payload };
    case DOWNLOAD_QR_CODE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//================  ADD SCAN CODE REDUCER ================
export const addScanCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_SCAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_SCAN_SUCCESS:
      return { ...state, loading: false, addedScanCode: action.payload };
    case ADD_SCAN_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//================  GET AGENCY EMAIL REDUCER ================
export const getAgencyEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AGENCY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_AGENCY_EMAIL_SUCCESS:
      return { ...state, loading: false, agencyEmail: action.payload };
    case GET_AGENCY_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        agencyEmail: null,
      };
    default:
      return state;
  }
};

//================  RESHARE AGENCY CREDS REDUCER ================
export const reshareAgencyCredsReducer = (state = {}, action) => {
  switch (action.type) {
    case RESHARE_AGENCY_CREDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESHARE_AGENCY_CREDS_SUCCESS:
      return { ...state, loading: false, resharedCreds: action.payload };
    case RESHARE_AGENCY_CREDS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_RESHARE_AGENCY_CREDS:
      return {};
    default:
      return state;
  }
};

// ================ GET ALL AGENCIES REDUCER  ====================
export const getAllAgenciesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_AGENCIES_REQUEST:
      return { loading: true };
    case GET_ALL_AGENCIES_SUCCESS:
      return { loading: false, allAgencies: action.payload };
    case GET_ALL_AGENCIES_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ALL_AGENCIES:
      return {};
    default:
      return state;
  }
};

//================   GET ALL CLIENTS REDUCER ================
export const getAllClientsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CLIENTS_REQUEST:
      return { loading: true };
    case GET_ALL_CLIENTS_SUCCESS:
      return { loading: false, clients: action.payload };
    case GET_ALL_CLIENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//================   GET ALL CLIENTS UNPAGINATED REDUCER ================
export const getAllClientsUnpaginatedReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CLIENTS_UNPAGINATED_REQUEST:
      return { loading: true };
    case GET_ALL_CLIENTS_UNPAGINATED_SUCCESS:
      return { loading: false, clients: action.payload };
    case GET_ALL_CLIENTS_UNPAGINATED_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ALL_CLIENTS:
      return {};
    default:
      return state;
  }
};

//================  CHECK PATIENT EMAIL REDUCER ================
export const checkPatientEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_PATIENT_EMAIL_REQUEST:
      return { loading: true };
    case CHECK_PATIENT_EMAIL_SUCCESS:
      return { loading: false, patientEmailExists: action.payload };
    case CHECK_PATIENT_EMAIL_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CHECK_PATIENT_EMAIL:
      return {};
    default:
      return state;
  }
};

//================  CHECK PATIENT EMAIL REDUCER ================
export const checkPatientPhoneReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_PATIENT_PHONE_REQUEST:
      return { loading: true };
    case CHECK_PATIENT_PHONE_SUCCESS:
      return { loading: false, patientPhoneExists: action.payload };
    case CHECK_PATIENT_PHONE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CHECK_PATIENT_PHONE:
      return {};
    default:
      return state;
  }
};

//================   GET CLIENT DATA REDUCER ================
export const getClientDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLIENT_DATA_REQUEST:
      return { loading: true };
    case GET_CLIENT_DATA_SUCCESS:
      return { loading: false, clientInfo: action.payload };
    case GET_CLIENT_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//================   UPDATE CLIENT REDUCER ================
export const updateClientReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_REQUEST:
      return { loading: true };
    case UPDATE_CLIENT_SUCCESS:
      return { loading: false, updatedClient: action.payload };
    case UPDATE_CLIENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//================   ADD NEW CLIENT REDUCER ================
export const addNewClientReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NEW_CLIENT_REQUEST:
      return { loading: true };
    case ADD_NEW_CLIENT_SUCCESS:
      return { loading: false, newClient: action.payload };
    case ADD_NEW_CLIENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//================   DELETE CLIENT DATA REDUCER ================
export const deleteClientReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CLIENT_REQUEST:
      return { loading: true };
    case DELETE_CLIENT_SUCCESS:
      return { loading: false, deletedClient: action.payload };
    case DELETE_CLIENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_DELETE_CLIENT:
      return {};
    default:
      return state;
  }
};
