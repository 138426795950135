import { Skeleton, Stack } from "@mui/material";
import React from "react";

const RectangleSkeletons = ({
  height = 67,
  totalRows = 3,
  gap = "8px",
  width = "100%",
  marginBottom,
  component = "div",
}) => {
  const skeletons = [];

  for (let i = 0; i < totalRows; i++) {
    skeletons.push(
      <Skeleton
        key={i}
        variant="rounded"
        width={width}
        height={height}
        sx={{ marginBottom: marginBottom && marginBottom }}
      />
    );
  }

  return (
    <Stack gap={gap} width="100%" component={component} flex="1">
      {skeletons}
    </Stack>
  );
};

export default RectangleSkeletons;
