import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import BgBox from "../../common/BgBox";
import MobileAppImage from "../../assets/images/mobile_app_image.png";
import MccLogo from "../../assets/images/mcc-logo.png";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from "@mui/lab";
import AppStoreImage from "../../assets/images/app_store_image.png";
import GooglePlayImage from "../../assets/images/google_play_image.png";
import { useDispatch } from "react-redux";
import { addScanCodeFunc } from "../../actions/Agency";
import styled from "@emotion/styled";

const StyledLink = styled(Typography)(() => ({
  color: "rgba(0, 0, 0, 0.38)",
  cursor: "pointer",
}));

const AgencyQrCode = () => {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const dispatch = useDispatch();

  const agencyId = searchParams.get("agency_id");
  const agencyName = searchParams.get("agency_name");

  useEffect(() => {
    const scanCodeObj = {
      agency_id: agencyId,
    };
    dispatch(addScanCodeFunc(scanCodeObj));
  }, []);

  return (
    <BgBox>
      <Box width="83px" height="80px">
        <img src={MccLogo} alt="mcc-logo" width="100%" height="100%" />
      </Box>

      <Box
        component="main"
        bgcolor="#fff"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="32px"
        height="100%"
        width="100%"
        boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
        borderRadius="16px"
        padding="92px 24px"
        sx={{
          [theme.breakpoints.down("md")]: {
            padding: "24px 24px",
            flexDirection: "column",
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="32px"
        >
          <Box
            width="408px"
            height="437px"
            sx={{
              [theme.breakpoints.down("md")]: {
                height: "388px",
              },
              [theme.breakpoints.down("sm")]: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <Box
              component="img"
              width="100%"
              sx={{
                [theme.breakpoints.down("sm")]: {
                  width: "85%",
                },
              }}
              height="100%"
              src={MobileAppImage}
              alt="vitatrax app"
            />
          </Box>
        </Box>

        <Stack gap="16px">
          <Typography
            variant="h1"
            color="#124273"
            fontSize="45px"
            sx={{
              [theme.breakpoints.down("sm")]: {
                fontSize: "38px",
              },
            }}
          >
            {agencyName}
          </Typography>
          <Typography
            variant="h5"
            color="rgba(0, 0, 0, 0.87)"
            fontSize="20px"
            fontWeight={500}
          >
            Follow the steps to get registered with us{" "}
          </Typography>
          <Stack padding="24px 0">
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <Box
                    width="24px"
                    height="24px"
                    borderRadius="50%"
                    bgcolor="#0C80F3"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="caption" color="#fff">
                      1
                    </Typography>
                  </Box>
                  <TimelineConnector sx={{ margin: "4px 0", width: "1px" }} />
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 8px",
                  }}
                >
                  <Typography variant="body2" fontWeight={500}>
                    Download and install our mobile application from below
                    options
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="12px"
                    marginBottom="16px"
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        flexDirection: "column",
                        margin: "16px 0",
                      },
                    }}
                  >
                    <Box
                      component="a"
                      href="https://apps.apple.com/us/app/vitatrax/id6587574641"
                    >
                      <img src={AppStoreImage} alt="apple store" />
                    </Box>
                    <Box
                      component="a"
                      href="https://play.google.com/store/apps/details?id=com.vitatrax"
                      sx={{
                        [theme.breakpoints.down("500px")]: {
                          width: "200px",
                        },
                      }}
                    >
                      <img src={GooglePlayImage} alt="google play" />
                    </Box>
                  </Box>
                </TimelineContent>
              </TimelineItem>

              <TimelineItem>
                <TimelineSeparator>
                  <Box
                    width="24px"
                    height="24px"
                    borderRadius="50%"
                    bgcolor="#0C80F3"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="caption" color="#fff">
                      2
                    </Typography>
                  </Box>
                </TimelineSeparator>
                <TimelineContent
                  sx={{
                    padding: "0 8px",
                  }}
                >
                  <Typography variant="body2" fontWeight={500}>
                    Signup using our Practice ID {agencyId}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Stack>
        </Stack>
      </Box>

      <Box component="footer" display="flex" alignItems="center" gap="8px">
        <StyledLink
          variant="caption"
          component="a"
          href="https://vitatrax.life"
        >
          {" "}
          &#169; 2025 Vitatrax
        </StyledLink>
        <Divider orientation="vertical" variant="middle" flexItem />
        <StyledLink
          variant="caption"
          component="a"
          href="https://vitatrax.life/contact"
        >
          {" "}
          Contact Support{" "}
        </StyledLink>
        <Divider orientation="vertical" variant="middle" flexItem />
        <StyledLink
          variant="caption"
          component="a"
          href="https://vitatrax.life/privacy-policy"
        >
          {" "}
          Privacy Policy
        </StyledLink>
      </Box>
    </BgBox>
  );
};

export default AgencyQrCode;
