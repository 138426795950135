import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import HeaderText from "../../common/HeaderText";
import styled from "@emotion/styled";
import { stringAvatar } from "../../utils/helpers";
import { AddPractiseIcon } from "../../assets/constants";

const StyledEmailedBtn = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: "12px",
  border: "1px solid rgba(192, 192, 192, 0.88)",
  textTransform: "none",
  color: "#000!important",
  fontWeight: "400",
  padding: "8px",
  borderRadius: "4px",
  cursor: "pointer",
}));

const ExistingPractices = ({
  existingAgencies,
  agencies,
  emailErrorMsg,
  showPractiseBox,
  showMorePractiseHandler,
  addingMorePractice,
}) => {
  return (
    <Stack gap="16px">
      {((existingAgencies && existingAgencies?.length) || agencies?.length) &&
      !emailErrorMsg ? (
        <HeaderText
          variant="subtitle2"
          fontSize="14px"
          textContent="Existing practices associated to your email"
          // lineHeight="24px"
        />
      ) : (
        <></>
      )}

      {agencies?.map((item, index) => (
        <StyledEmailedBtn key={index}>
          <Avatar
            variant="rounded"
            sx={{ bgcolor: "#B0D6FE" }}
            {...stringAvatar(item?.name)}
          />
          <Stack>
            <Typography variant="body1" fontWeight={500}>
              {item?.name}
            </Typography>
            <Typography variant="caption" color="secondary">
              {item?.primary_email && item?.primary_email} |{" "}
              {item?.id && ` Practice ID : ${item?.id}`}
            </Typography>
          </Stack>
        </StyledEmailedBtn>
      ))}

      {(!showPractiseBox || !addingMorePractice) && (
        <Button
          size="large"
          onClick={showMorePractiseHandler}
          color="secondary"
          sx={{
            bgcolor: "#F4F6F8",
            textTransform: "capitalize",
            fontSize: "16px",
          }}
          startIcon={<AddPractiseIcon />}
        >
          Add
        </Button>
      )}
    </Stack>
  );
};

export default ExistingPractices;
