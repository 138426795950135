import React from "react";
import { Box, Chip, Skeleton, Stack, Typography } from "@mui/material";

const OrgHomeCard = ({
  headerText,
  loading = false,
  number,
  imgName,
  chiplabel,
  color,
  bgColor,
}) => {
  return (
    <Box
      padding="24px"
      height="178px"
      borderRadius="8px"
      position="relative"
      overflow="hidden"
      border="1px solid #E7E7E7"
      // display="flex"
      // alignItems="flex-end"
      // justifyContent="space-between"
      // gap="4px"
      width="100%"
      bgcolor="#FFF"
    >
      <Stack
        alignItems="flex-start"
        justifyContent="flex-start"
        gap="12px"
        height="100%"
      >
        {loading ? (
          <Skeleton variant="rounded" width={87} height={36} />
        ) : (
          <Typography variant="subtitle1" fontSize="38px" color="secondary">
            {number}
          </Typography>
        )}
        <Typography
          zIndex="99"
          variant="body1"
          fontWeight="500"
          color="rgba(0, 0, 0, 0.38)"
        >
          {headerText}
        </Typography>

        {chiplabel && (
          <Box zIndex="99">
            <Chip
              label={chiplabel}
              variant="outlined"
              color="warning"
              sx={{
                marginLeft: 0,
                padding: "4px",
                borderRadius: "4px",
                color: color,
                bgcolor: bgColor,
                border: color,
              }}
            />
          </Box>
        )}
      </Stack>
      <Box
        position="absolute"
        bottom="22px"
        right="22px"
        sx={{
          width: "125px",
          height: "125px",
          padding: "24px",
          bottom: "0",
          right: "0",
        }}
      >
        {imgName}
      </Box>
    </Box>
  );
};

export default OrgHomeCard;
