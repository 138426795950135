import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CustomSelectFilter from "../../common/CustomSelectFilter";
import { reportMonthFilterOptions } from "../../utils/helpers";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

import { useSelector } from "react-redux";

const ExportEvidenceReportContent = ({
  selectedMonthValue,
  setSelectedMonthValue,
  enrolledOn,
  painLogsCheckedValue,
  setPainLogsCheckedValue,
  stepCountsCheckedValue,
  setStepCountsCheckedValue,
  DetailedChatCheckedValue,
  setDetailedChatCheckedValue,
  timeSheetCheckedValue,
  setTimeSheetCheckedValue,
  appointmentsCheckedValue,
  setAppointmentsCheckedValue,
  notesCheckedValue,
  setNotesCheckedValue,
  painRemindersCheckedValue,
  setPainRemindersCheckedValue,
  patientIds,
  bulkActions = false,
  emailChangeHandler,
  exportEmail,
  emailError,
  setExportEmail,
}) => {
  const agentProfile = useSelector((state) => state.agentProfile);
  const { agentUser } = agentProfile;

  const changeMonthFilterHandler = (e) => {
    setSelectedMonthValue(e.target.value);
  };

  useEffect(() => {
    if (bulkActions) {
      setExportEmail(agentUser?.agent?.email);
    }
  }, []);

  return (
    <Stack>
      {bulkActions && (
        <Alert severity="info" sx={{ marginBottom: "24px", color: "#212121" }}>
          Exporting Evidence of Care for
          <Box
            component="span"
            fontWeight={500}
            // sx={{ textDecoration: "underline" }}
          >
            {" "}
            {patientIds?.length}{" "}
            {patientIds?.length == 1 ? "patient" : "patients"}
          </Box>
        </Alert>
      )}

      <FormControl>
        <FormLabel
          id="export-report-radio-buttons-group"
          sx={{
            paddingBottom: "12px",
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: 500,
          }}
        >
          Output Configurations
        </FormLabel>
      </FormControl>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="16px"
      >
        <Typography variant="body2">Reporting Period</Typography>
        <CustomSelectFilter
          menuItems={reportMonthFilterOptions(enrolledOn, bulkActions)}
          iconColor="#0D0D0D"
          fontSize="12px"
          IconComponent={ExpandMoreRoundedIcon}
          changeSelectValueHandler={changeMonthFilterHandler}
          selectValue={selectedMonthValue}
          id="export-report-month"
          minWidth={124}
          size="small"
        />
      </Box>

      {bulkActions && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingBottom="16px"
        >
          <Typography variant="body2">Send EOC Download Link to :</Typography>
          <Box width="189px">
            <TextField
              name="email"
              variant="outlined"
              size="small"
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "12px",
                },
              }}
              fullWidth
              error={emailError}
              helperText={emailError && "Please enter valid email"}
              value={exportEmail}
              onChange={emailChangeHandler}
            />
          </Box>
        </Box>
      )}

      {/* PAIN LOGS */}
      <FormControlLabel
        control={
          <Checkbox
            checked={painLogsCheckedValue}
            onChange={(e) => setPainLogsCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={
          <span style={{ marginRight: "auto" }}>
            Include detailed daily pain logs
          </span>
        }
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />

      {/* STEP COUNT */}
      <FormControlLabel
        control={
          <Checkbox
            value={stepCountsCheckedValue}
            onChange={(e) => setStepCountsCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={
          <span style={{ marginRight: "auto" }}>
            Include detailed daily step count logs
          </span>
        }
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />

      {/* CHAT */}
      <FormControlLabel
        control={
          <Checkbox
            value={DetailedChatCheckedValue}
            onChange={(e) => setDetailedChatCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={
          <span style={{ marginRight: "auto" }}>Include detailed chat</span>
        }
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />
      {/* Include TIME SHEET  */}
      <FormControlLabel
        control={
          <Checkbox
            value={timeSheetCheckedValue}
            onChange={(e) => setTimeSheetCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={<span style={{ marginRight: "auto" }}>Include time sheet </span>}
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />
      {/* APPOINTMENT LOGS  */}
      <FormControlLabel
        control={
          <Checkbox
            value={appointmentsCheckedValue}
            onChange={(e) => setAppointmentsCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={
          <span style={{ marginRight: "auto" }}>
            Include detailed appointments logs
          </span>
        }
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />
      {/* INCLUDE NOTES */}
      <FormControlLabel
        control={
          <Checkbox
            value={notesCheckedValue}
            onChange={(e) => setNotesCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={<span style={{ marginRight: "auto" }}>Include notes</span>}
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />
      {/* PAIN REMINDER */}
      <FormControlLabel
        control={
          <Checkbox
            value={painRemindersCheckedValue}
            onChange={(e) => setPainRemindersCheckedValue(e.target.checked)}
            sx={{ ml: "auto" }}
          />
        }
        label={
          <span style={{ marginRight: "auto" }}>
            Include pain reminder logs
          </span>
        }
        sx={{ width: "100%", ml: 0 }}
        labelPlacement="start"
      />
    </Stack>
  );
};

export default ExportEvidenceReportContent;
