import * as React from "react";
import {
  Stack,
  Typography,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import Style from "./style";
import Button from "../../../../common/Button";
import InviteDrawer from "./InviteDrawer";
import CardUI from "../../../../common/Card";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import CircularProgressLoading from "../../../../common/CircularProgress";
import AlertDialog from "../../../../components/AlertDialog";
import AvatarWithName from "../../../../components/AvatarWithName";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../../../common/AlertMessage";
import { getAgencyDetailFunc } from "../../../../actions/Agency";
import { segmentTrack } from "../../../../utils/helpers/tracking_utils";
import SkeletonGrid from "../../../../components/SkeletonGrid";
import CaseTableSkeleton from "../../../../components/CaseTableSkeleton";
import { debounce } from "lodash";

export default function Team() {
  const [state, setState] = useState({
    right: false,
  });
  // let userInfo = localStorage.getItem("userInfo");

  const [openDrawer, setOpenDrawer] = useState(false);
  const [modalContentData, setModalContentData] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorRowEl, setAnchorRowEl] = React.useState(null);
  const [rowData, setRowData] = useState({});

  const [agentID, setAgentID] = useState(0);
  const [agentsList, setAgentsList] = useState([]);
  const [totalAgents, setTotalAgents] = useState(0);
  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState();
  const [update, setUpdate] = useState(false);
  const [message, setMessage] = useState(null);
  // const [clientsList, setClientsList] = useState([]);
  const [status, setStatus] = useState({ type: "", message: "" });
  const [query, setQuery] = useState("");

  const [alertOpen, setAlertOpen] = useState(true);
  const [permissionMessage, setPermissionMessage] = useState(null);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  // const handleAlertClick = () => {
  //   setAlertOpen(true);
  // };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
    setPermissionMessage(null);
    setMessage(null);
  };

  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const cookieAgentId = Cookies.get("agent_id_vita");
  const cookieAgencyId = Cookies.get("agency_id_vita");

  const inviteAgent = useSelector((state) => state.inviteAgent);
  const {
    loading: loadingAgent,
    error: errorAgent,
    invitedAgent,
  } = inviteAgent;

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentProfile,
    error: errorAgentProfile,
    agentUser,
  } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgencyDetail,
    error: errorAgencyDetail,
    agencyDetail,
  } = getAgencyDetail;

  const changedAgencyId = useSelector((state) => state.changedAgencyId);
  const { changed } = changedAgencyId;

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, [cookieAgencyId]);

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "settings_team_tab",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  const agentUserRef = React.useRef();

  useEffect(() => {
    agentUserRef.current = agentUser;
  }, [agentUser]);

  // console.log("AGENT INFO", agentUser);

  useEffect(() => {
    if (token) {
      setAgentID(cookieAgentId);
    }
  }, [token]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const modalOpenHandler = () => {
    setOpenModal(true);
  };

  const debouncedDispatch = React.useCallback(
    debounce((q, page, pageSize) => {
      getAgentsList(q, page, pageSize);
    }, 500),
    []
  );

  useEffect(() => {
    if (token) {
      if (query) {
        debouncedDispatch(
          query,
          paginationModel?.page + 1,
          paginationModel?.pageSize
        );
      } else {
        debouncedDispatch.cancel();
        getAgentsList(
          query,
          paginationModel?.page + 1,
          paginationModel?.pageSize
        );
      }
    }
  }, [
    token,
    cookieAgencyId,
    changed,
    invitedAgent,
    paginationModel,
    query,
    debouncedDispatch,
  ]);

  useEffect(() => {
    return () => {
      debouncedDispatch.cancel();
    };
  }, [debouncedDispatch]);

  // FETCH AGENTS LISTS
  const getAgentsList = async (query, page, pageSize) => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      let response;

      if (query) {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/agent/get-list?agency_id=${cookieAgencyId}&search=${query}&page=${page}&pageSize=${pageSize}`,
          config
        );
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/agent/get-list?agency_id=${cookieAgencyId}&page=${page}&pageSize=${pageSize}`,
          config
        );
      }

      if (!response?.data?.ok) {
        throw new Error("Could not fetched agents list!");
      }

      setTotalAgents(response?.data?.count);

      const allAgents = response?.data?.agents_list?.filter(
        (singleAgent) => singleAgent?.agent_type !== "mcc_support"
      );

      setAgentsList(allAgents);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // OPEN AGENT DELETE CONFIRM MODAL
  const documentDeleteHandler = (item) => {
    const access = true;
    // agentUserRef.current?.agent?.permissions?.access_agent_details?.delete;

    if (!access) {
      setPermissionMessage(
        "You do not have permission to delete a team member!"
      );
      setAlertOpen(true);
      return;
    } else {
      if (item?.id === agentUser?.agent?.id) {
        setMessage(
          "You cannot delete your own account. Please contact info@vitatrax.co to delete your account."
        );
        setAlertOpen(true);
        return;
      }

      if (item?.agent?.role === "Owner") {
        setMessage(
          "Account Owner cannot be deleted. Please contact info@vitatrax.co to delete the account."
        );
        setAlertOpen(true);
        return;
      }

      if (item?.agent?.role === "Organization Admin") {
        setMessage(
          "Organization Admin cannot be deleted. Please contact info@vitatrax.co to delete the account."
        );
        setAlertOpen(true);
        return;
      }

      if (
        agentUser?.agent?.role !== "Owner" &&
        agentUser?.agent?.role !== "Organization Admin"
      ) {
        setMessage(
          "Only Owner or Organization Admin can delete other team member's account"
        );
        setAlertOpen(true);
        return;
      }

      setAlertOpen(false);
      setRowData(item);
      setModalContentData({
        title: "Are you sure?",
        subtitle: "Are you sure you want to delete this team member?",
      });
      modalOpenHandler();
      setAnchorRowEl(null);

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "delete_member_clicked",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const modalCloseHandler = () => {
    setOpenModal(false);
  };

  // DELETING ROW DATA
  const agentDeleteHandler = async () => {
    try {
      setLoading(true);
      modalCloseHandler();

      const deletingData = {
        agent_id: rowData?.id,
      };

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/agent/delete`,
        deletingData,
        config
      );

      // console.log("DELETING DATA", res);

      setLoading(false);

      setRowData({});
      getAgentsList(
        query,
        paginationModel?.page + 1,
        paginationModel?.pageSize
      );

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "member_deleted",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      modalCloseHandler();
      setRowData({});
    }
  };

  // console.log("AGENTS LIST", agentsList);

  const editDrawerHandler = (item) => {
    const access = true;
    // agentUserRef.current?.agent?.permissions?.access_agent_details?.edit;
    if (!access) {
      setPermissionMessage("You do not have permission to edit a team member!");
      setAlertOpen(true);
      return;
    } else {
      if (agentUser?.agent?.id === item?.id) {
        setMessage(
          "You cannot edit your own account. Please contact info@vitatrax.co to edit the account."
        );
        setAlertOpen(true);
        return;
      }

      if (item?.row?.agent?.role === "Owner") {
        setMessage(
          `Account Owner cannot be edited. Please contact \n info@vitatrax.co to edit the account.`
        );
        setAlertOpen(true);
        setOpenDrawer(false);
        return;
      }

      if (
        agentUser?.agent?.role !== "Owner" &&
        agentUser?.agent?.role !== "Organization Admin"
      ) {
        setMessage("Only Owner can edit other team member's account");
        setAlertOpen(true);
        return;
      }

      setAgent(item.row.agent);
      setUpdate(true);
      setOpenDrawer(true);

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "edit_member_clicked",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  // TEAM/AGENT ROWS

  // const regex = new RegExp(query, "i");

  const rows = React.useMemo(() => {
    if (!agentsList?.length) return [];

    return (
      agentsList
        // .filter(
        //   ({ name, email }) =>
        //     name.search(regex) !== -1 || email.search(regex) !== -1
        // )
        .map((agent) => ({
          id: agent?.id,
          name_with_picture: {
            name: agent?.name,
            profile_picture_url: agent?.profile_picture_url,
            avatarBgColor: agent?.avatar_color,
          },
          user_type: agent?.role ? agent?.role : "Not found",
          email: agent?.email,
          agent: agent,
        }))
    );
  }, [agentsList]);

  // TEAM COLUMNS
  const columns = React.useMemo(
    () => [
      {
        field: "name_with_picture",
        headerName: "User",
        width: 220,
        flex: 1,
        renderCell: ({ value }) => (
          <AvatarWithName
            name={value.name}
            imageURL={value.profile_picture_url}
            avatarBgColor={value.avatarBgColor}
          />
        ),
        sortable: false,
      },
      {
        field: "email",
        headerName: "Email",
        width: 300,
        flex: 1,
      },
      {
        field: "user_type",
        headerName: "User Type",
        width: 256,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Action",
        headerAlign: "right",
        align: "right",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label="Edit"
            onClick={() => editDrawerHandler(params)}
          />,
          <GridActionsCellItem
            icon={<DeleteOutlinedIcon />}
            label="Delete"
            onClick={() => {
              documentDeleteHandler(params.row);
              // setRowData(params.row);

              // console.log(params.row);
              // setTeam(params.row)
              // setOpen(true)
            }}
          />,
        ],
        sortable: false,
      },
    ],
    []
  );

  const paginationModelHandler = (newModel) => {
    setPaginationModel(newModel);
  };

  return (
    <>
      {!loadingAgentProfile && message && (
        <AlertMessage
          message={message}
          severity="error"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {!loadingAgentProfile && permissionMessage && (
        <AlertMessage
          message={permissionMessage}
          severity="error"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {openDrawer && (
        <InviteDrawer
          anchor={"right"}
          update={update}
          setUpdate={setUpdate}
          open={openDrawer}
          getAgentsList={getAgentsList}
          agent={agent}
          onClose={() => setOpenDrawer(false)}
          status={status}
          setStatus={setStatus}
        />
      )}

      {/* ALERT MODAL */}
      {openModal && (
        <AlertDialog
          isLoading={loading}
          openModal={openModal}
          closeModal={modalCloseHandler}
          data={modalContentData}
          formSubmitFunction={agentDeleteHandler}
          color="error"
        />
      )}

      <CardUI
        maxWidth="100%"
        dashboard="true"
        padding="32px"
        overflow="hidden"
        height="100%"
        paddingBottom="0"
      >
        <Stack>
          <Typography
            pb="24px"
            variant="boxHeading"
            color="disabled.boxHeading"
            sx={{ textTransform: "uppercase" }}
          >
            Manage Your team
          </Typography>
        </Stack>

        <Box
          display="flex"
          alignItems="center"
          gap="16px"
          justifyContent="flex-end"
          padding="16px 0"
          paddingRight="16px !important"
        >
          <Box display="flex" alignItems="center" gap="16px" width="100%">
            <FormControl
              size="small"
              variant="outlined"
              sx={{ minWidth: "219px" }}
            >
              <InputLabel htmlFor="search-agents">Search</InputLabel>
              <OutlinedInput
                id="search-agents"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                // endAdornment={
                //   <InputAdornment position="end">
                //     <SearchOutlinedIcon />
                //   </InputAdornment>
                // }
                placeholder="Name, email or phone no. "
                label="Search"
                size="small"
                sx={{ backgroundColor: "#FFF" }}
              />
            </FormControl>
          </Box>
          <Stack>
            <Button
              startIcon={<AddRoundedIcon />}
              title="Invite"
              color="primary"
              variant="contained"
              height="40px"
              width="102px"
              onClick={() => {
                const access = true;
                // agentUserRef.current?.agent?.permissions?.access_agent_details
                //   ?.create;
                if (!access) {
                  setPermissionMessage(
                    "You do not have permission to invite a new team member!"
                  );
                  setAlertOpen(true);
                  return;
                } else {
                  setUpdate(false);
                  setOpenDrawer(true);
                  if (agentUser?.agent && agencyDetail?.agency) {
                    segmentTrack({
                      event: "new_member_clicked",
                      agent: agentUser?.agent,
                      agency: agencyDetail?.agency,
                    });
                  }
                }
              }}
            />
          </Stack>
        </Box>

        {loading ? (
          <CaseTableSkeleton
            headers={["User", "Email", "User Type", "Action"]}
            numRows={4}
            cellWidths={[236, 236, 230, 60]}
            marginTop="0"
            height="calc(100% - 168px)"
          />
        ) : (
          <div
            style={{
              height: loading ? "calc(100% - 168px)" : "calc(100% - 128px)",
              width: "100%",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              rowCount={totalAgents || 0}
              // onRowClick={overviewDetailHandler}
              loading={loading}
              pagination
              paginationMode="server"
              onPaginationModelChange={paginationModelHandler}
              initialState={{
                pagination: {
                  paginationModel: paginationModel,
                },
              }}
              sx={{
                cursor: " pointer !important",
                "&": { border: "none" },
              }}
              pageSizeOptions={[5, 10]}
              disableRowSelectionOnClick
              getRowId={(row) => row.id}
              //   className={classes.dataGrid}
            />
          </div>
        )}

        {/* <Stack justifyContent="space-between" alignItems="center" direction="row">
        <Stack direction="row" spacing={2} className={classes.filter__width}>
          <Hidden mdDown>
            <div className="field-width">
              <Textfield
                placeholder="Enter Na"
                // error={isTouched && !!error}
                // helperText={error?.message}
                margin="dense"
                label="Search"
                variant="outlined"
                name="firmName"
                noHelperText
                // value={value || ''}
                // onChange={onChange}
              />
            </div>
            <div className="dropdown-width">
              <Dropdown noHelperText label="User Type" />
            </div>
          </Hidden>
        </Stack>
        <Stack alignItems="flex-end">
          <Button
            startIcon={<AddRoundedIcon />}
            title="Invite "
            color="primary"
            variant="contained"
            height="40px"
            width="102px"
            onClick={toggleDrawer("right", true)}
          />
        </Stack>
        <InviteDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        />
      </Stack> */}
        {/* <TableContainer component={Box}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={
                    selected.length > 0 && selected.length < rowCount
                  }
                  checked={rowCount > 0 && selected.length === rowCount}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              <TableCell>User</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>User Type</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              const isItemSelected = isSelected(row.name);
              const labelId = `enhanced-table-checkbox-${i}`;
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.name)}
                  key={i++}
                  role="checkbox"
                  selected={isItemSelected}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ mr: 1 }} />
                    Name
                  </TableCell>
                  <TableCell>henryarthur@gmail.com </TableCell>
                  <TableCell>
                    Admin{" "}
                    {i === 1 && (
                      <Chip
                        sx={{ ml: 1.2 }}
                        label="Modified"
                        size="small"
                        color="primary"
                        variant="outlined"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer> */}
      </CardUI>
    </>
  );
}
