import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { EditPracticeIcon } from "../../assets/constants";
import AvatarGroups from "../AvatarGroups";
import PractiseBoxCard from "../PractiseBoxCard";
import { useSelector } from "react-redux";
import CaseTableSkeleton from "../CaseTableSkeleton";
import EditPracticeDrawer from "../Organization/EditPracticeDrawer";

const StyledTableCell = styled(TableCell)(() => {
  return {
    padding: "8px 16px",
  };
});

const OrgHomePracticeTable = () => {
  const getOrganization = useSelector((state) => state.getOrganization);
  const {
    loading: loadingOrg,
    error: errorOrg,
    organizationDetail,
  } = getOrganization;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const rows = organizationDetail?.organization?.agencies;

  const columns = [
    { id: "name", label: "Name", minWidth: 300 },
    {
      id: "actions",
      label: "Actions",
      minWidth: 190,

      align: "right",
      // format: (value) => value.toFixed(2),
    },
  ];
  const editPracticeHandler = (row) => {
    setOpenDrawer(true);
    setSelectedRow(row);
  };

  return (
    <>
      {openDrawer && (
        <EditPracticeDrawer
          anchor={"right"}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      )}

      <Stack
        border="1px solid #E7E7E7"
        padding="24px"
        borderRadius="8px"
        maxWidth="647px"
        width="100%"
        bgcolor="#fff"
        gap="16px"
        height="350px"
      >
        <Typography variant="subtitle2" fontSize="18px" color="#0D0D0D">
          Practices
        </Typography>
        {loadingOrg ? (
          <CaseTableSkeleton
            padding="8px 16px"
            marginTop="0"
            headers={["Name", "Actions"]}
            numRows={4}
            cellWidths={[300, 190]}
          />
        ) : (
          <TableContainer sx={{ maxHeight: "100%", height: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <StyledTableCell>
                        <PractiseBoxCard
                          mb={0}
                          title={row?.name}
                          padding="0"
                          // open={open}
                          logoType={row?.logo_type}
                          iconName={row?.icon_name}
                          iconColor={row?.icon_color}
                          logoUrl={row?.logo_url}
                          organizationPortal={false}
                          // switchWorkspaceHandler={switchWorkspaceHandler}
                          practiseId={row?.id}
                          // icon={
                          //   item?.id === Number(currentAgencyId) && (
                          //     <TickCircleIcon />
                          //   )
                          // }
                        />
                        {/* {row?.logo_type === "custom" ? (
                    <Box
                      width="40px"
                      height="40px"
                      borderRadius="4px"
                      overflow="hidden"
                    >
                      <img
                        width="100%"
                        height="100%"
                        src={row?.logo_url}
                        alt="practise logo"
                      />
                    </Box>
                  ) : iconName === null || iconName === "avatar_icon" ? (
                    <Avatar
                      variant="rounded"
                      sx={{ background: getIconColor(iconColor) }}
                      {...stringAvatar(title)}
                    />
                  ) : (
                    <Box
                      width="40px"
                      height="40px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        background: getIconColor(iconColor),
                      }}
                      borderRadius="4px"
                    >
                      {getIconName(iconName)}
                    </Box>
                  )}
                  <Typography variant="body1"></Typography> */}
                        {/* {row?.name} */}
                      </StyledTableCell>

                      <StyledTableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <IconButton
                            sx={{ padding: "6px" }}
                            onClick={() => editPracticeHandler(row)}
                          >
                            <EditPracticeIcon />
                          </IconButton>
                          {/* <IconButton
                    sx={{ padding: "6px" }}
                    onClick={() => getAgencyEmailHandler(row)}
                  >
                    <Box
                      width="20px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        width="100%"
                        src={ReshareIcon}
                        alt="reshare_icon"
                      />
                    </Box>
                  </IconButton> */}

                          {/* <IconButton
                    sx={{ padding: "4px" }}
                    onClick={(event) => openMenuHandler(event, row)}
                  >
                    <MoreVertIcon />{" "}
                  </IconButton> */}
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {/* {Boolean(anchorEl) && (
        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={reshareCredsHandler}>
            Reshare Credentials
          </MenuItem>
          <MenuItem
            sx={{ color: "#FF2D2D" }}
            // onClick={() => openCaseDeleteHandler(selectedRow)}
          >
            Delete
          </MenuItem>
        </Menu>
      )} */}
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
};

export default OrgHomePracticeTable;
