import { Avatar, AvatarGroup, Box, Tooltip } from "@mui/material";
import React from "react";
import { getIconColor, getIconName, stringAvatar } from "../../utils/helpers";
import PractiseBoxCard from "../PractiseBoxCard";

const SinglePracticeAvatar = ({
  title,
  iconName,
  iconColor,
  logoType,
  logoUrl,
}) => {
  return (
    <Tooltip title={title}>
      <Box display="flex">
        {logoType === "custom" ? (
          <Box width="24px" height="24px" borderRadius="4px" overflow="hidden">
            <img width="100%" height="100%" src={logoUrl} alt="practise logo" />
          </Box>
        ) : iconName === null || iconName === "avatar_icon" ? (
          <Avatar
            variant="rounded"
            sx={{ background: getIconColor(iconColor) }}
            {...stringAvatar(title)}
          />
        ) : (
          <Box
            width="24px"
            height="24px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              background: getIconColor(iconColor),
            }}
            borderRadius="4px"
          >
            {getIconName(iconName)}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};

const PracticesAvatar = ({ allAgencies }) => {
  return (
    <>
      {allAgencies?.length < 1 ? (
        "-"
      ) : allAgencies?.length === 1 ? (
        <PractiseBoxCard
          mb={0}
          title={allAgencies[0]?.name}
          padding="0"
          // open={open}
          gap="6px"
          fontWeight={400}
          fontSize="14px"
          logoType={allAgencies[0]?.logo_type}
          iconName={allAgencies[0]?.icon_name}
          iconColor={allAgencies[0]?.icon_color}
          logoUrl={allAgencies[0]?.logo_url}
          organizationPortal={true}
          size={24}
          width="24px"
          height="24px"
          // switchWorkspaceHandler={switchWorkspaceHandler}
          // practiseId={item?.id}
          // icon={
          //   item?.id === Number(currentAgencyId) && (
          //     <TickCircleIcon />
          //   )
          // }
        />
      ) : (
        <Box sx={{ height: "100%" }}>
          <AvatarGroup
            max={3}
            variant="square"
            sx={{
              justifyContent: "flex-end",
              height: "100%",
              alignItems: "center",
              "& .MuiAvatar-root": {
                width: "24px",
                height: "24px",
                fontSize: "0.875rem",
              },
            }}
          >
            {allAgencies?.map((agency, i) => (
              <SinglePracticeAvatar
                key={i}
                title={agency?.name}
                iconName={agency?.icon_name}
                iconColor={agency?.icon_color}
                logoType={agency?.logo_type}
                logoUrl={agency?.logo_url}
              />
            ))}
          </AvatarGroup>
        </Box>
      )}
    </>
  );
};

export default PracticesAvatar;
