import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CardUI from "../../../../common/Card";
import {
  CopyIcon,
  DownloadingIcon,
  QrCodeDownloadIcon,
} from "../../../../assets/constants";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  downloadQrCodeFunc,
  getAgencyDetailFunc,
  getQrCodeImageFunc,
} from "../../../../actions/Agency";
import RectangleSkeletons from "../../../../components/RectangleSkeletons";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import axios from "axios";
import CircularProgressLoading from "../../../../common/CircularProgress";

const QrCode = () => {
  const dispatch = useDispatch();
  const [loadingQrPdf, setLoadingQrPdf] = useState(false);

  const cookieAgencyId = Cookies.get("agency_id_vita");
  const token = Cookies.get("token");

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const getQrCodeImage = useSelector((state) => state.getQrCodeImage);
  const {
    loading: loadingQrCode,
    error: errorQrCode,
    qrCodeImage,
  } = getQrCodeImage;

  // console.log("DDD", downloadedQrCode);

  const changedAgencyId = useSelector((state) => state.changedAgencyId);
  const { changed } = changedAgencyId;

  const baseAppUrl = window.location.origin;

  const qrItems = [
    { title: "Total Scans", value: agencyDetail?.agency?.total_scans || 0 },
    { title: "Total Signups", value: agencyDetail?.agency?.total_signups || 0 },
  ];

  const [textCopied, setTextCopied] = useState(false);

  const practiceId = agencyDetail?.agency?.id;
  const practiceName = agencyDetail?.agency?.name;
  const practiceWebsite = agencyDetail?.agency?.website;

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, [cookieAgencyId, changed]);

  useEffect(() => {
    if (agencyDetail) {
      const agencyUrl = {
        url: `${baseAppUrl}/practice_detail?agency_id=${practiceId}&agency_name=${practiceName}`,
      };

      dispatch(getQrCodeImageFunc(agencyUrl));
    }
  }, [agencyDetail]);

  const copyPracticeIdHandler = () => {
    navigator.clipboard.writeText(practiceId).then(() => {
      setTextCopied(true);
      setTimeout(() => setTextCopied(false), 2000);
    });
  };

  const downloadQrCodeHandler = async () => {
    setLoadingQrPdf(true);
    try {
      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        };

        const qrCodeObj = {
          agency_id: practiceId,
          url: `${baseAppUrl}/practice_detail?agency_id=${practiceId}&agency_name=${practiceName}`,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/agency/generate-qr-pdf`,
          qrCodeObj,
          config
        );

        if (response?.status !== 200) {
          throw new Error("Failed to generate PDF report");
        }

        const blob = response?.data;
        const url = window.URL.createObjectURL(
          new Blob([blob], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Qr-PDF.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setLoadingQrPdf(false);
      }
    } catch (error) {
      setLoadingQrPdf(false);
      console.log(
        "ERROR",
        error,
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  return (
    <Grid container spacing={2} width="100%">
      <Grid
        item
        gap={3}
        xs={12}
        md={8}
        // md={12}
        //   className={classes.grid_column}
      >
        {/* QR CODE SECTION */}
        <CardUI
          maxWidth="100%"
          dashboard="true"
          padding="32px"
          boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
        >
          <Grid item>
            <Stack gap="24px">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="8px"
              >
                <Typography color="secondary" variant="body1" fontWeight={500}>
                  QR CODE
                </Typography>
                <Box bgcolor="#F4F6F8" padding="4px" borderRadius="4px">
                  {loadingQrPdf ? (
                    <CircularProgressLoading size={21} />
                  ) : (
                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={downloadQrCodeHandler}
                    >
                      <QrCodeDownloadIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
              <Box display="flex" gap="18px" justifyContent="space-between">
                <Stack gap="18px">
                  <Stack gap="1px">
                    {practiceName && (
                      <Typography variant="body1" fontWeight={500}>
                        {practiceName}
                      </Typography>
                    )}
                    {practiceWebsite && (
                      <Typography variant="body2" color="secondary">
                        {practiceWebsite}
                      </Typography>
                    )}
                  </Stack>
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    gap="18px"
                  >
                    {qrItems.map((item, index) => (
                      <Stack
                        key={index}
                        alignItems="flex-start"
                        boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
                        padding="16px"
                        gap="18px"
                        borderRadius="8px"
                      >
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          borderRadius="8px"
                        >
                          {item?.title}
                        </Typography>
                        {loadingAgency ? (
                          <RectangleSkeletons totalRows="1" height={50} />
                        ) : (
                          <Typography
                            variant="body1"
                            fontWeight={700}
                            fontSize="50px"
                            color="secondary"
                            lineHeight={1}
                          >
                            {item?.value}
                          </Typography>
                        )}
                      </Stack>
                    ))}
                  </Box>
                </Stack>
                <Box
                  boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
                  borderRadius="19px"
                  border="9.5px solid #C8E2F4"
                  sx={{
                    borderImage: "linear-gradient(to top, #FCEFED, #C8E2F4) 1",
                    clipPath: "inset(0 round 9.5px)",
                  }}
                  overflow="hidden"
                  width="169px"
                  height="169px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {loadingAgency || loadingQrCode ? (
                    <Box width="100%" height="100%">
                      <RectangleSkeletons
                        totalRows={1}
                        height="169px"
                        width="100%"
                      />
                    </Box>
                  ) : (
                    <Box width="100%" height="100%">
                      <img
                        src={qrCodeImage?.file_url}
                        alt="qr code"
                        width="100%"
                        height="100%"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Stack>
          </Grid>
        </CardUI>
      </Grid>

      {/* PRACTICE ID SECTION  */}
      <Grid item xs={12} md={4}>
        <CardUI
          maxWidth="353px"
          dashboard="true"
          padding="32px"
          boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
        >
          <Stack gap="24px">
            <Typography
              color="secondary"
              variant="body1"
              fontWeight={500}
              textTransform="uppercase"
            >
              Practice ID
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              justifyContent="space-between"
            >
              {loadingAgency ? (
                <RectangleSkeletons totalRows={1} height={30} width="100px" />
              ) : (
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  fontSize="28px"
                >
                  {practiceId}
                </Typography>
              )}
              <Tooltip title={textCopied ? "Copied" : "Copy Practice ID"}>
                <IconButton
                  sx={{ padding: "4px" }}
                  onClick={copyPracticeIdHandler}
                >
                  {textCopied ? (
                    <CheckRoundedIcon color="success" />
                  ) : (
                    <CopyIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </CardUI>
      </Grid>
    </Grid>
  );
};

export default QrCode;
