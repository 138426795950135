import { Alert, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import React from "react";
import { ImportMaximiseIcon } from "../../assets/constants";
import OpenMaximiseIcon from "../../icons/OpenMaximiseIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToggleMinimize } from "../../actions/AppConfig";
import { useSelector } from "react-redux";

const MinimizingImport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const assignBulkMembers = useSelector((state) => state.assignBulkMembers);
  const { assignedBulkMembers } = assignBulkMembers;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={true}
      sx={{ width: "320px" }}
    >
      <Alert
        icon={<ImportMaximiseIcon />}
        action={
          <IconButton
            onClick={() => {
              dispatch(setToggleMinimize(false));
              navigate("/assign-patients");
            }}
          >
            <OpenMaximiseIcon />
          </IconButton>
        }
        severity="info"
        variant="standard"
      >
        <Stack gap="4px" color="#014361">
          <Typography fontWeight={500} variant="body1">
            Importing Patients
          </Typography>

          <Typography variant="body2">
            {assignedBulkMembers?.ok
              ? "Patients imported successfully."
              : "Patients are being imported in the background. We will notify you once it is done."}
          </Typography>
        </Stack>
      </Alert>
    </Snackbar>
  );
};

export default MinimizingImport;
