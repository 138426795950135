import React from "react";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

// const cellWidths = [87, 144, 150, 100, 50, 150, 150];

const CaseTableSkeleton = ({
  headers,
  numRows,
  cellWidths = [87, 144, 150, 100, 50, 150, 150],
  marginTop = "24px",
  height = 550,
  headerInclude = true,
  padding = "12px 10px !important",
  textAlign = "left",
}) => {
  return (
    <TableContainer sx={{ marginTop: marginTop, height: height }}>
      <Table>
        {headerInclude && (
          <TableHead>
            <TableRow>
              {headers?.map((header, index) => (
                <TableCell
                  sx={{
                    padding: padding,
                    textAlign:
                      index === 6 || index === 4
                        ? "right !important"
                        : textAlign,
                  }}
                  key={index}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {Array(numRows)
            .fill()
            .map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {headers.map((_, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    sx={{ minWidth: cellWidths[cellIndex] }}
                  >
                    <Skeleton variant="text" height={20} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CaseTableSkeleton;
