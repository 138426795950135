import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../../common/AlertMessage";
import RectangleSkeletons from "../RectangleSkeletons";
import Cookies from "js-cookie";
import { BASE_URL } from "../../actions";

const baseAppUrl = window.location.origin;

const SetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [elementVisible, setElementVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    setOpen(false);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // return_url: navigate("/password-created", {
        //   state: { billingDetailsSuccess: true },
        // }),
        // return_url: `http://localhost:3000/password-created?billingCompleted=true`,
        return_url: `${baseAppUrl}/password-created?billingCompleted=true`,
      },
    });

    Cookies.remove("route");
    setLoading(false);

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setOpen(true);
      setLoading(false);
    } else {
      //   navigate("/password-created", {
      //     state: { createAgentName: true },
      //   });
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setElementVisible(true);
    }, 1000);
  }, []);

  return (
    <>
      {open && (
        <AlertMessage
          message={errorMessage}
          autoHideDurationTime={9000}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}
      <form>
        {elementVisible && stripe ? (
          <PaymentElement />
        ) : (
          <RectangleSkeletons totalRows={1} height="300px" />
        )}
        <LoadingButton
          fullWidth
          loading={loading}
          onClick={handleSubmit}
          disabled={!stripe}
          //   loading={loading}
          variant="contained"
          sx={{ padding: "8px 22px", fontSize: 15, marginTop: "16px" }}
        >
          Next
        </LoadingButton>
      </form>
    </>
  );
};

export default SetupForm;
