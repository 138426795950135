import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Drawer,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import Textfield from "../../../common/Textfield";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  clearContact,
  formatPhoneNumber,
  validateEmail,
} from "../../../utils/helpers";
import { statesWithCities } from "../../../utils/helpers/states_with_cities";
import { useDispatch } from "react-redux";
import {
  resetUpdateAgencyFunc,
  updateAgencyFunc,
} from "../../../actions/Agency";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { getOrganizationFunc } from "../../../actions/OrganizationActions";

const states = Object.keys(statesWithCities);

const StyledStack = styled(Stack)(({ theme }) => ({
  width: "748px !important",
  [theme.breakpoints.down("md")]: {
    width: "500px !important",
    padding: "1rem !important",
  },
}));

const EditPracticeDrawer = ({
  anchor,
  open,
  onClose,
  selectedRow,
  setSelectedRow,
}) => {
  const [name, setName] = useState(selectedRow?.name || "");
  const [primaryEmail, setPrimaryEmail] = useState(
    selectedRow?.primary_email || ""
  );
  const [primaryContactNumber, setContactNumber] = useState(
    selectedRow?.primary_contact_number || ""
  );
  const [website, setWebsite] = useState(selectedRow?.website || "");
  const [address, setAddress] = useState(selectedRow?.address || "");
  const [city, setCity] = useState(selectedRow?.city || "");
  const [region, setRegion] = useState(selectedRow?.region || null); // state/region
  const [zipCode, setZipCode] = useState(selectedRow?.zipcode || "");
  const [emailError, setEmailError] = useState(false);
  const [country, setCountry] = useState("United States of America");

  const dispatch = useDispatch();

  const updateAgency = useSelector((state) => state.updateAgency);
  const {
    loading: loadingAgency,
    error: errorAgency,
    updatedAgency,
  } = updateAgency;

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;
    setPrimaryEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);

    if (emailInputValue && !emailIsValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  const emailBlurHandler = () => {
    if (!primaryEmail) {
      setEmailError(false);
    }
  };

  const contactNumberHandler = (e) => {
    const input = e.target.value;
    const num = clearContact(input);
    const formattedNumber = formatPhoneNumber(num);
    setContactNumber(formattedNumber);
  };

  const websiteHandler = (e) => {
    setWebsite(e.target.value);
  };

  const handleZipCodeChange = (e) => {
    // Only update if the input is a number or empty (to allow deletion)
    if (/^\d*$/g.test(e.target.value)) {
      setZipCode(e.target.value);
    }
  };

  const cookieAgencyId = Cookies.get("agency_id_vita");

  const editPracticeHandler = () => {
    const agencyData = {
      agency_id: selectedRow ? selectedRow?.id : cookieAgencyId,
      agency_settings: {
        name,
        primary_email: primaryEmail,
        primary_contact_number: primaryContactNumber,
        website,
        address,
        city,
        country,
        region: region,
        zipcode: zipCode,
      },
    };

    dispatch(updateAgencyFunc(agencyData));
  };

  useEffect(() => {
    if (updatedAgency?.ok) {
      setSelectedRow(null);
      dispatch(getOrganizationFunc());
      onClose();
      dispatch(resetUpdateAgencyFunc());
    }
  }, [updatedAgency]);

  return (
    <Drawer
      anchor={anchor}
      open={open}
      //   onClose={() => {
      //     onClose();
      //     setUpdate(false);
      //   }}
    >
      <StyledStack p={4} gap={3} height="100%">
        <Stack gap={3} flex="1" sx={{ overflowY: "auto" }}>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            pb="32px"
          >
            <Typography variant="h5">Edit Practice Details</Typography>
            <IconButton size="small" onClick={onClose}>
              <CancelOutlinedIcon />
            </IconButton>
          </Stack>
          <Typography
            variant="boxHeading"
            color="disabled.boxHeading"
            sx={{ textTransform: "uppercase" }}
          >
            Practice Details
          </Typography>

          <Box display="flex" gap="24px">
            <Textfield
              placeholder="Enter Name"
              label="Practice Name"
              variant="outlined"
              name="firmName"
              width="100%"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              // error={isTouched && !!error}
              // helperText={error?.message}
              // margin="dense"
            />
            <Textfield
              placeholder="contact@example.com"
              label="Email Address"
              variant="outlined"
              name="email"
              width="100%"
              value={primaryEmail}
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
              errorContent={emailError && "Enter valid email"}
              helperText={emailError && "Enter valid email"}
            />
          </Box>

          <Box display="flex" gap="24px">
            <Textfield
              placeholder="+1 7776665555"
              label="Contact Number"
              variant="outlined"
              name="contactNumber"
              width="100%"
              value={primaryContactNumber}
              onChange={contactNumberHandler}
              // error={isTouched && !!error}
              // helperText={error?.message}
              // margin="dense"
            />
            <Textfield
              placeholder="www.acme.com"
              label="Website"
              variant="outlined"
              name="contactNumber"
              width="100%"
              value={website}
              onChange={websiteHandler}
              // error={isTouched && !!error}
              // helperText={error?.message}
              // margin="dense"
            />
          </Box>
          <Box>
            <Textfield
              placeholder="90210 Broadway Blvd"
              label="Address"
              variant="outlined"
              name="address"
              fullWidth
              width="100%"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              // margin="dense"
              // error={isTouched && !!error}
              // helperText={error?.message}
            />
          </Box>
          <Box display="flex" gap="24px">
            {/* <TextField
                        placeholder="United States of America"
                        margin="dense"
                        label="Country"
                        variant="outlined"
                        name="country"
                        // error={isTouched && !!error}
                        // helperText={error?.message}
                        value="United States of America"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          style: {
                            backgroundColor: "transparent",
                            border: "none",
                            color: "black",
                            fontSize: "1em",
                          },
                        }}
                        // onChange={(e) => setCountry(e.target.value)}
                      /> */}

            <Textfield
              placeholder="San Mateo"
              label="City"
              variant="outlined"
              name="city"
              fullWidth
              width="100%"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              // margin="dense"
              // error={isTouched && !!error}
              // helperText={error?.message}
            />

            <Autocomplete
              options={states}
              fullWidth
              value={region}
              onChange={(event, newValue) => {
                setRegion(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State/Region"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
              )}
              sx={{ marginTop: -1 }}
            />
          </Box>
          <Box display="flex" gap="24px" width="48%" alignItems="center">
            <Textfield
              placeholder="91000"
              label="Zip Code"
              variant="outlined"
              name="zipcode"
              fullWidth
              width="100%"
              value={zipCode}
              onChange={handleZipCodeChange}
              // margin="dense"
              // error={isTouched && !!error}
              // helperText={error?.message}
            />
          </Box>
          {/* <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            //   className={clsx(classes.fields__space)}
            spacing={2}
          >
            <Textfield
              placeholder="Enter First Name"
              // error={isTouched && !!error}
              // helperText={error?.message}
              width="100%"
              label="First Name"
              variant="outlined"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              // value={value || ''}
              // onChange={onChange}
            />

            <Textfield
              placeholder="Enter Last Name"
              label="Last Name"
              variant="outlined"
              width="100%"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              // error={isTouched && !!error}
              // helperText={error?.message}
              // value={value || ''}
              // onChange={onChange}
            />
          </Stack>
          <Stack>
            <Textfield
              placeholder="Enter Email"
              margin="dense"
              label="Email Address"
              variant="outlined"
              name="email"
              value={email}
              onChange={emailChangeHandler}
              errorContent={emailError && "Enter valid email"}
              helperText={emailError && "Enter valid email"}
              readOnly={update && true}
              // onChange={onChange}
            />
          </Stack>
          <Typography
            variant="h4"
            color="disabled.customs"
            sx={{ fontWeight: 400 }}
          >
            Auto-generated password shared on invite, changeable after first
            login.
          </Typography> */}
        </Stack>
        <Stack alignItems="flex-end" pt={1.4}>
          <LoadingButton
            loading={loadingAgency}
            color="primary"
            variant="contained"
            onClick={editPracticeHandler}
            disabled={!name || emailError}
            // height="40px"
            // width="140px"
          >
            Save Changes
          </LoadingButton>
        </Stack>
      </StyledStack>
    </Drawer>
  );
};

export default EditPracticeDrawer;
