import React, { useEffect, useMemo, useState } from "react";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import PracticesTab from "../../components/Organization/PracticesTab";
import { getOrganizationFunc } from "../../actions/OrganizationActions";
import { useSelector } from "react-redux";
import { getAllAgenciesListFunc } from "../../actions/Agency";
import RectangleSkeletons from "../../components/RectangleSkeletons";
import BillingTab from "../../components/Organization/BillingTab";
import General from "../Settings/Components/General";
import HomeTab from "../../components/Organization/HomeTab";
import TeamTab from "../../components/Organization";

const tabMapping = {
  home: 0,
  practices: 1,
  team: 2,
  billing: 3,
  settings: 4,
};

const allTabs = [
  {
    // icon: <SettingPatientIcon />,
    title: "Home",
    component: <HomeTab />,
  },
  {
    // icon: <SettingPatientIcon />,
    title: "Practices",
    component: <PracticesTab />,
  },
  {
    // icon: <SettingPatientIcon />,
    title: "Team",
    component: <TeamTab />,
  },
  {
    title: "Billing",
    component: <BillingTab />,
  },
  {
    title: "Settings",
    component: <General orgPortal={true} />,
  },

  //   {
  //     // icon: <TeamTabIcon />,
  //     title: "Billing",
  //     // component: <Team />,
  //   },
  // {
  //   icon: <CasesTabIcon />,
  //   title: "Case",
  //   component: <Cases />,
  // },
];

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: "transparent",
  // boxShadow: 'none',
  padding: "0",
  zIndex: "1",
  position: "relative",
  borderRadius: "0",
  minHeight: "30px !important",
  width: "100%",
  //   borderBottom: 1,
  //   borderColor: "divider",
  "& .MuiTabScrollButton-root": {
    color: "rgba(109, 125, 139, 1)",
    borderBottom: "3px solid #CCD2E3",
    opacity: "1 !important",
  },
  "& .MuiTabScrollButton-root.Mui-disabled": {
    color: "rgba(109, 125, 139, .3)",
  },
  "& .MuiTabs-scroller": {
    "& .MuiTabs-flexContainer": {
      justifyContent: "flex-start",
      "& .MuiTab-root": {
        minHeight: "30px",
        width: "max-content",
        maxWidth: "max-content",
        minWidth: "max-content",
        textTransform: "capitalize",
        color: "rgba(109, 125, 139, 1)",
        opacity: "1",
        // borderBottom: 1,
        fontSize: "13px",
        padding: ".8rem 1.4rem",
        margin: "0",
      },
      "& .Mui-selected": {
        color: theme.palette.primary.main,
        zIndex: 1,
        // "& svg": {
        //   "& path": {
        //     stroke: theme.palette.primary.main,
        //   },
        // },
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ paddingBottom: "20px", height: "100%" }}
      hidden={value !== index}
      id={`org-tabpanel-${index}`}
      aria-labelledby={`org-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, height: "100%" }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `org-tab-${index}`,
    "aria-controls": `org-tabpanel-${index}`,
  };
}

const OrganizationPortal = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams?.get("tab") || "practices";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getOrganization = useSelector((state) => state.getOrganization);
  const {
    loading: loadingOrg,
    error: errorOrg,
    organizationDetail,
  } = getOrganization;

  const getAllAgencies = useSelector((state) => state.getAllAgencies);
  const {
    loading: loadingAllAgencies,
    error: errorAllAgencies,
    allAgencies,
  } = getAllAgencies;

  const reverseTabMapping = useMemo(() =>
    Object.fromEntries(
      Object.entries(tabMapping)?.map(([key, value]) => [value, key])
    )
  );

  useEffect(() => {
    if (selectedTab in tabMapping) {
      setActiveTab(tabMapping[selectedTab]);
    }
  }, [selectedTab]);

  useEffect(() => {
    dispatch(getOrganizationFunc());
    // if (!allAgencies) {
    //   dispatch(getAllAgenciesListFunc());
    // }
  }, []);

  const handleChange = (event, newValue) => {
    const tabName = reverseTabMapping[newValue];
    if (tabName) {
      searchParams?.set("tab", tabName);
      navigate({ search: `?${searchParams.toString()}` });
      setActiveTab(newValue);
    }
  };

  return (
    <Stack padding="8px 106px 30px 149px" height="100%">
      <Stack padding="0 40px" height="100%">
        <Stack gap="4px" pb="36px">
          {loadingOrg ? (
            <RectangleSkeletons totalRows={1} height="40px" width="400px" />
          ) : (
            <Typography variant="h5" fontSize="30px" fontWeight={500}>
              {organizationDetail?.organization?.name}
            </Typography>
          )}
          <Typography variant="body2" color="secondary">
            Manage your organisation account effortlessly.{" "}
          </Typography>
        </Stack>

        {/* ORG TABS */}
        <Stack height="100%">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <StyledTabs
              value={activeTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {allTabs?.map(
                (tab, key) =>
                  tab.title && (
                    <Tab
                      // iconPosition="start"
                      icon={tab.icon}
                      label={tab.title}
                      key={tab.title}
                      {...a11yProps(key++)}
                    />
                  )
              )}
            </StyledTabs>
          </Box>
          <TabPanel
            value={activeTab}
            index={activeTab}
            //   className={clsx(classes.tab__panel)}
          >
            {allTabs[activeTab].component}
          </TabPanel>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OrganizationPortal;
