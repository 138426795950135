import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CardUI from "../../common/Card";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import CaseTableSkeleton from "../CaseTableSkeleton";
import { DeleteIcon, EditPracticeIcon } from "../../assets/constants";
import AvatarWithName from "../AvatarWithName";
import PracticesAvatar from "../PracticesAvatars";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import InviteDrawer from "../../pages/Settings/Components/Team/InviteDrawer";
import { use } from "react";
import { useDispatch } from "react-redux";
import { getOrgAgentsFunc } from "../../actions/OrganizationActions";
import AlertMessage from "../../common/AlertMessage";
import AlertDialog from "../AlertDialog";
import { deleteAgentFunc, resetDeleteAgent } from "../../actions/Agency";

const StyledTableCell = styled(TableCell)(() => {
  return {
    padding: "8px 16px",
  };
});

const TeamTab = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [status, setStatus] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [isOrgPortal, setIsOrgPortal] = useState(true);
  const [modalContentData, setModalContentData] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const getOrgAgents = useSelector((state) => state.getOrgAgents);
  const {
    loading: loadingOrgAgents,
    error: errorOrgAgents,
    orgAgents,
  } = getOrgAgents;

  const deleteAgent = useSelector((state) => state.deleteAgent);
  const {
    loading: loadingDeleteAgent,
    error: errorDeleteAgent,
    agentDeleted,
  } = deleteAgent;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { agentUser } = agentProfile;

  const columns = [
    { id: "user", label: "User", minWidth: 283 },
    { id: "email", label: "Email", minWidth: 219 },
    { id: "practice", label: "Practice", minWidth: 222 },
    { id: "user_type", label: "User Type", minWidth: 222 },
    {
      id: "actions",
      label: "Actions",
      //   minWidth: 190,

      align: "right",
      // format: (value) => value.toFixed(2),
    },
  ];

  const rows = orgAgents?.agents;

  const editAgentHandler = (selectedRow) => {
    setIsOrgPortal(false);
    setOpenDrawer(true);
    setUpdate(true);
    setSelectedAgent(selectedRow);
  };

  const openDeleteAgentModalHandler = (selectedRow) => {
    setOpenModal(true);
    setModalContentData({
      title: "Are you sure?",
      subtitle: "Are you sure you want to delete this team member?",
    });
    setSelectedAgent(selectedRow);
  };

  const agentDeleteHandler = () => {
    const agentData = {
      agent_id: selectedAgent?.id,
    };

    dispatch(deleteAgentFunc(agentData));
  };

  useEffect(() => {
    if (agentDeleted?.ok) {
      setOpenModal(false);
      dispatch(getOrgAgentsFunc());
      dispatch(resetDeleteAgent());
    }
  }, [agentDeleted]);

  const getAgentsList = () => {
    dispatch(getOrgAgentsFunc());
    setOpenAlert(true);
  };

  return (
    <>
      {openDrawer && (
        <InviteDrawer
          anchor={"right"}
          isOrgPortal={isOrgPortal}
          orgPortalUpdate={true}
          update={update}
          setUpdate={setUpdate}
          open={openDrawer}
          getAgentsList={getAgentsList}
          agent={selectedAgent}
          onClose={() => setOpenDrawer(false)}
          status={status}
          setStatus={setStatus}
        />
      )}

      {(status?.type === "success" || status?.type === "error") && (
        <AlertMessage
          message={status?.message}
          severity={status?.type}
          open={openAlert}
          handleClose={handleAlertClose}
        />
      )}

      {openModal && (
        <AlertDialog
          isLoading={loadingDeleteAgent}
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          data={modalContentData}
          formSubmitFunction={agentDeleteHandler}
          color="error"
        />
      )}

      <CardUI
        bgcolor="#fff"
        gap="16px"
        maxWidth="100%"
        paddingTop="32px"
        boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
        //   overflow="hidden"
        height="560px"
      >
        <Stack gap="16px" height="100%">
          <Box display="flex" alignSelf="flex-end">
            <Button
              startIcon={<AddRoundedIcon />}
              color="primary"
              variant="contained"
              height="40px"
              width="102px"
              sx={{
                background: "linear-gradient(90deg, #0C80F3 0%, #5865E1 100%)",
              }}
              onClick={() => {
                setIsOrgPortal(true);
                setUpdate(false);
                setOpenDrawer(true);
              }}
            >
              Invite{" "}
            </Button>
          </Box>
          <Stack height="100%">
            {loadingOrgAgents ? (
              <CaseTableSkeleton
                padding="8px 16px"
                marginTop="0"
                headers={["Name", "Email", "Practice", "User Type", "Actions"]}
                numRows={4}
                cellWidths={[283, 219, 222, 222]}
              />
            ) : (
              <TableContainer sx={{ maxHeight: "100%", height: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns?.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row, index) => {
                      return (
                        <TableRow tabIndex={-1} key={index}>
                          <StyledTableCell>
                            <AvatarWithName
                              name={row?.name}
                              imageURL={row?.profile_picture_url}
                              avatarBgColor={row?.avatar_color}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {row?.email ? row?.email : "-"}
                          </StyledTableCell>

                          <StyledTableCell>
                            {" "}
                            <PracticesAvatar allAgencies={row?.agencies} />
                          </StyledTableCell>
                          <StyledTableCell>
                            {" "}
                            {row?.role ? row?.role : "-"}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box
                              display="flex"
                              gap="6px"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <IconButton
                                sx={{
                                  padding: "6px",
                                  "&.Mui-disabled svg": {
                                    "& path": {
                                      stroke: "rgba(0, 0, 0, 0.33)",
                                    },
                                  },
                                }}
                                onClick={() => openDeleteAgentModalHandler(row)}
                                disabled={
                                  agentUser?.agent?.id === row?.id ||
                                  row?.role === "Organization Admin"
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                sx={{
                                  padding: "6px",
                                  "&.Mui-disabled svg": {
                                    "& path": {
                                      stroke: "rgba(0, 0, 0, 0.33)",
                                    },
                                  },
                                }}
                                onClick={() => editAgentHandler(row)}
                                disabled={
                                  agentUser?.agent?.id === row?.id ||
                                  row?.role === "Organization Admin"
                                }
                              >
                                <EditPracticeIcon />
                              </IconButton>

                              {/* <IconButton
                    sx={{ padding: "4px" }}
                    onClick={(event) => openMenuHandler(event, row)}
                  >
                    <MoreVertIcon />{" "}
                  </IconButton> */}
                            </Box>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {/* {Boolean(anchorEl) && (
        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={reshareCredsHandler}>
            Reshare Credentials
          </MenuItem>
          <MenuItem
            sx={{ color: "#FF2D2D" }}
            // onClick={() => openCaseDeleteHandler(selectedRow)}
          >
            Delete
          </MenuItem>
        </Menu>
      )} */}
                </Table>
              </TableContainer>
            )}
          </Stack>
        </Stack>
      </CardUI>
    </>
  );
};

export default TeamTab;
