import React, { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import BillingInfoPlan from "../../assets/images/info-billing-plan.png";
import BillingOrgImage from "../../assets/images/billingOrgImage.png";
import { useDispatch } from "react-redux";
import { getAgentsListFunc } from "../../actions/Agency";
import RectangleSkeletons from "../RectangleSkeletons";

const BillingManaged = () => {
  const dispatch = useDispatch();
  const getAgentsList = useSelector((state) => state.getAgentsList);
  const { loading, error, allAgents } = getAgentsList;

  useEffect(() => {
    dispatch(getAgentsListFunc());
  }, []);

  const getOrganization = useSelector((state) => state.getOrganization);
  const {
    loading: loadingOrg,
    error: errorOrg,
    organizationDetail,
  } = getOrganization;

  const orgAdmin = allAgents?.agents_list?.find(
    (item) => item?.role === "Organization Admin"
  );

  return (
    <Stack
      boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
      bgcolor="#fff"
      height="100%"
      alignItems="center"
      justifyContent="center"
      gap="24px"
    >
      <Box
        width="281px"
        height="218px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img
          width="100%"
          height="100%"
          src={BillingOrgImage}
          alt="organization"
        />
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        bgcolor="#F6F6FD"
        borderRadius="8px"
        gap="9px"
        padding="20px 24px"
        width="495px"
      >
        <Box width="18px" height="18px">
          <img
            width="100%"
            height="100%"
            src={BillingInfoPlan}
            alt="billing info"
          />
        </Box>
        <Stack gap="6px" flex="1">
          <Typography color="#014361" variant="body1" fontWeight={500}>
            Plan Details
          </Typography>
          <Typography variant="body2" color="#014361">
            <Typography component="span" fontWeight={500}>
              Your billing is managed by{" "}
              {organizationDetail?.organization?.name}.
            </Typography>{" "}
            For any changes or questions regarding your billing, please contact
            your organisation directly. <br />
            You can{" "}
            <Typography
              display="flex"
              alignItems="center"
              component="span"
              fontWeight={500}
              gap="4px"
            >
              reach your organisation admin:{" "}
              {loading ? (
                <RectangleSkeletons
                  component="span"
                  width="100%"
                  totalRows={1}
                  height={20}
                />
              ) : (
                orgAdmin?.name
              )}
              ,
            </Typography>{" "}
            or
            <br />
            contact us at{" "}
            <Typography component="span" fontWeight={500}>
              billing@vitatrax.life{" "}
            </Typography>{" "}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export default BillingManaged;
