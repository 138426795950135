import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import FsLightbox from "fslightbox-react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Box,
  Tooltip,
  Autocomplete,
  TextField,
  Select,
  InputLabel,
  FormControl,
  Paper,
  Button as MUIButton,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
  Snackbar,
  Alert,
  Skeleton,
  styled,
} from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import companyLogo from "../../assets/images/company-logo.jpg";
import pdfImg from "../../assets/images/pdfImg.png";
import pngImg from "../../assets/images/image-icon.png";
import docImg from "../../assets/images/docImg.png";
import { DocCircle, FolderIcon } from "../../assets/constants/index";

import Style from "./style";
import Dropdown from "../../common/Dropdown";
import Textfield from "../../common/Textfield";
import BoxMain from "../../common/Box";
import {
  EditIcon,
  SendClientIcon,
  ArrowLeftIcon,
} from "../../assets/constants";
import Button from "../../common/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderText from "../../common/HeaderText";
import AlertDialog from "../../components/AlertDialog";
import OverviewDrawer from "./OverviewDrawer";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import EmptyBox from "../../assets/images/empty-box.png";
import CircularProgressLoading from "../../common/CircularProgress";
import InviteDrawer from "./InviteDrawer";
import {
  dateFormat,
  isSupportedType,
  onlyImageName,
  removeLastExtension,
} from "../../utils/helpers";
import {
  disableAppAccessFunc,
  enableAppAccessFunc,
  resetAppAccessState,
} from "../../actions/AppConfig";
import AlertMessage from "../../common/AlertMessage";
import {
  deleteClientFunc,
  getClientDataFunc,
  getAgencyDetailFunc,
  resetDeleteClient,
} from "../../actions/Agency";
import {
  resendClientCredentialsFunc,
  resetResendClientCredsState,
} from "../../actions/Auth";
import { segmentError, segmentTrack } from "../../utils/helpers/tracking_utils";
import GridViewIcon from "../../icons/GridViewIcon";
import ListViewIcon from "../../icons/ListViewIcon";
import AddFolderIcon from "../../icons/AddFolderIcon";
import {
  cancelDocRequestFunc,
  createEmptyFolderFunc,
  deleteFileFunc,
  deleteFolderFunc,
  getClientCasesDocsFunc,
  innerFolderViewFunc,
  moveFileToFolderFunc,
  renameFileFunc,
  renameFolderFunc,
  resetCancelDocRequest,
  resetClientCasesDocs,
  resetCreateEmptyFolder,
  resetDeleteFile,
  resetDeleteFolder,
  resetInnerFolderView,
  resetMoveToFolder,
  resetRenameFile,
  resetRenameFolder,
  resetSendDocReminder,
  sendDocReminderFunc,
} from "../../actions/DocumentsActions";
import InnerFolderView from "../../components/InnerFolderView";
import GridViewLayout from "../../components/GridViewLayout";
import ListViewLayout from "../../components/ListViewLayout";
import DeleteIcon from "../../icons/DeleteIcon";
import MoveFileLayout from "../../components/MoveFileLayout";
import SkeletonGrid from "../../components/SkeletonGrid";
import RectangleSkeletons from "../../components/RectangleSkeletons";
import ClientOverviewSkeleton from "../../components/ClientOverviewSkeleton";
import { appInsights } from "../../utils/helpers/AppInsights";

const StyledImgBox = styled(Box)(() => ({
  width: "108px",
  height: "108px",
  position: "absolute",
  bottom: "-56px",
  left: "22px",
  borderRadius: "50%",
  "& .profile__img": {
    height: "100%",
    width: "100%",
    // position: "absolute",
    borderRadius: "50%",
  },
}));

const StyledContainerBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "16px",
  borderRadius: "8px",
  background: " #D9ECFF",
  padding: "8px 16px",
}));

export default function Overview() {
  // const classes = Styles();

  const params = useParams();
  const clientId = Number(params?.id);

  // let userInfo = localStorage.getItem("userInfo");

  const [toggler, setToggler] = useState(false);
  const [lightboxSources, setLightboxSources] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [menuFileIndex, setMenuFileIndex] = useState(null);
  const [menuFileDownloadURLs, setMenuFileDownloadURLs] = useState([]);

  const agentToken = Cookies.get("token");

  const [token, setToken] = useState(agentToken);
  const [agentID, setAgentID] = useState(0);
  const [agencyID, setAgencyID] = useState(0);

  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [fromClientOverview, setFromClientOverview] = useState(false);
  const [clientData, setClientData] = useState({});
  const [deleteItem, setDeleteItem] = useState(false);
  const [hobbiesTag, setHobbiesTag] = useState([]);
  const [hobbyInput, setHobbyInput] = useState("");
  const [frontHobbies, setFrontHobbies] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [anchorProfileEl, setAnchorProfileEl] = useState(null);
  const [anchorDocumentEl, setAnchorDocumentEl] = useState(null);
  const [anchorRequestDocEl, setAnchorRequestDocEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [modalContentData, setModalContentData] = useState({});
  const [overviewDrawerState, setOverviewDrawerState] = useState({
    right: false,
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [updateClient, setUpdateClient] = useState(false);
  const [selectedCase, setSelectedCase] = useState("all-documents");
  const [enabledAppAccess, setEnabledAppAccess] = useState(false);
  const [disableAppMessage, setDisableAppMessage] = useState("");
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [innerSelectedFile, setInnerSelectedFile] = useState(null);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [fileToMove, setFileToMove] = useState(null);
  const [moveFolderOpenModal, setMoveFolderOpenModal] = useState(false);
  const [moveFileDialog, setMoveFileDialog] = useState(false);
  const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false);
  const [showNewFolderBox, setShowNewFolderBox] = useState(false);
  const [onlyFolders, setOnlyFolders] = useState([]);

  const [selectedMoveFolderList, setSelectedMoveFolderList] = useState(null);
  const [newFileName, setNewFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [newFolderName, setNewFolderName] = useState("");
  const [moveFolderName, setMoveFolderName] = useState("");
  const [fieldIsTouched, setFieldIsTouched] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({});

  const [innerFolderContents, setInnerFolderContents] = useState([]);
  const [folderDownloadContents, setFolderDownloadContents] = useState([]);
  const [documentView, setDocumentView] = useState("grid");
  const [innerFolderView, setInnerFolderView] = useState(null);
  const [selectedCheckedDoc, setSelectedCheckedDoc] = useState([]);
  const [innerSelectedCheckedDoc, setInnerSelectedCheckedDoc] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState(["Home"]);

  const [openCreatedFolderMessage, setOpenCreatedFolderMessage] =
    useState(false);
  const [fileMovingMessage, setFileMovingMessage] = useState(false);
  const dispatch = useDispatch();

  const renameFolder = useSelector((state) => state.renameFolder);
  const {
    loading: loadingRenameFolder,
    error: errorRenameFolder,
    renameFolderName,
  } = renameFolder;

  const renameFile = useSelector((state) => state.renameFile);
  const {
    loading: loadingRenameFile,
    error: errorRenameFile,
    renameFileName,
  } = renameFile;

  const innerFolderViewContent = useSelector((state) => state.innerFolderView);
  const {
    loading: loadingFolderViewContent,
    errorFolderViewContent,
    folderContents,
  } = innerFolderViewContent;

  const deleteFile = useSelector((state) => state.deleteFile);
  const {
    loading: loadingDeleteFile,
    error: errorDeleteFile,
    deletedFile,
  } = deleteFile;

  const deleteFolder = useSelector((state) => state.deleteFolder);
  const {
    loading: loadingDeleteFolder,
    error: errorDeleteFolder,
    deletedFolder,
  } = deleteFolder;

  const createdEmptyFolder = useSelector((state) => state.createdEmptyFolder);
  const {
    loading: loadingCreateEmptyFolder,
    error: errorCreateEmptyFolder,
    emptyFolder,
  } = createdEmptyFolder;

  const moveFileToFolder = useSelector((state) => state.moveFileToFolder);
  const {
    loading: loadingMoveFileToFolder,
    error: errorMoveFileToFolder,
    moveFileToFolders,
  } = moveFileToFolder;

  const openProfileMenu = Boolean(anchorProfileEl);
  const openDocumentMenu = Boolean(anchorDocumentEl);
  const openRequestDocMenu = Boolean(anchorRequestDocEl);

  const getClientData = useSelector((state) => state.getClientData);
  const {
    loading: loadingClient,
    error: errorClient,
    clientInfo,
  } = getClientData;

  const getClientCasesDocs = useSelector((state) => state.getClientCasesDocs);
  const {
    loading: loadingGetClientCasesDocs,
    error: errorGetClientCasesDocs,
    clientCasesDocuments,
  } = getClientCasesDocs;

  const sendDocReminder = useSelector((state) => state.sendDocReminder);
  const {
    loading: loadingSendDocReminder,
    error: errorSendDocReminder,
    sentDocReminder,
  } = sendDocReminder;

  const cancelDocument = useSelector((state) => state.cancelDocument);
  const {
    loading: loadingcancelDocument,
    error: errorcancelDocument,
    cancelledDocument,
  } = cancelDocument;

  const disableAppAccess = useSelector((state) => state.disableAppAccess);
  const { loading, error, disabledApp } = disableAppAccess;

  const enableAppAccess = useSelector((state) => state.enableAppAccess);
  const {
    loading: loadingEnable,
    error: errorEnable,
    enabledApp,
  } = enableAppAccess;

  const deleteClient = useSelector((state) => state.deleteClient);
  const {
    loading: loadingDeleteClient,
    error: errorDeleteClient,
    deletedClient,
  } = deleteClient;

  const resendClientCredentials = useSelector(
    (state) => state.resendClientCredentials
  );
  const {
    loading: loadingCredentials,
    error: errorCredentials,
    clientCreds,
  } = resendClientCredentials;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "client_overview",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenCreatedFolderMessage(false);
    setFileMovingMessage(false);
    setOpen(false);
  };

  useEffect(() => {
    if (innerSelectedFile) {
      const { extension, nameWithoutExtension } = removeLastExtension(
        innerSelectedFile?.name
      );

      setFileExtension(extension);
      setNewFileName(nameWithoutExtension);
    } else if (selectedDocument?.type === "folder") {
      setNewFolderName(selectedDocument?.name);
    } else {
      const { extension, nameWithoutExtension } = removeLastExtension(
        selectedDocument?.name
      );

      setFileExtension(extension);
      setNewFileName(nameWithoutExtension);
      // setNewFileName(selectedDocument?.name);
    }
  }, [selectedDocument, innerSelectedFile]);

  const { state } = useLocation();

  const navigate = useNavigate();

  const rowData = state && state.rowData;

  useEffect(() => {
    document.title = `VitaTrax | ${rowData?.client?.name}`;
  }, [state]);

  const modalOpenHandler = () => {
    setOpenModal(true);
  };

  const modalCloseHandler = () => {
    setOpenModal(false);
    setOpenRenameModal(false);
    setOpenCreateFolderModal(false);
    setMoveFolderOpenModal(false);
    setMoveFileDialog(false);
    setFieldIsTouched(false);
    setShowNewFolderBox(false);
  };

  const cookieAgentId = Cookies.get("agent_id_vita");
  const cookieAgencyId = Cookies.get("agency_id_vita");

  useEffect(() => {
    if (agentToken) {
      setToken(agentToken);
      setAgentID(cookieAgentId);
      setAgencyID(cookieAgencyId);
    }
  }, [token, rowData?.client?.id]);

  useEffect(() => {
    if (clientData?.hobbies) {
      setHobbiesTag(JSON.parse(clientData?.hobbies) || []);
    }
  }, [clientData?.id]);

  // const getClientInfo = async () => {
  //   setDocsLoading(true);

  //   try {
  //     const config = {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };

  //     // setClientData(clientInfo?.client);
  //     // setFrontHobbies(clientInfo?.client?.hobbies || "[]");

  //     var client_documents = clientInfo?.client?.documents;

  //     client_documents = client_documents?.filter((value, index, self) => {
  //       return self?.map((doc) => doc.title)?.indexOf(value.title) === index;
  //     });

  //     const promises = client_documents.map((document) =>
  //       axios.get(
  //         `${process.env.REACT_APP_BASE_URL}/document/get-document-urls-by-title?client_id=${params?.id}&title=${document.title}`,
  //         config
  //       )
  //     );

  //     Promise.all(promises).then((responses) => {
  //       var documentsWithFiles = client_documents.map((document, index) => ({
  //         ...document,
  //         files: responses[index].data.files,
  //       }));

  //       documentsWithFiles = documentsWithFiles.filter(
  //         (dwf) => dwf.title && dwf.files?.length
  //       );

  //       for (const dwf of documentsWithFiles) {
  //         if (dwf.files.length === 1) {
  //           var file_extension = dwf.files[0].file_name
  //             .split(".")
  //             .pop()
  //             .toLowerCase();

  //           if (["jpg", "jpeg", "png"].includes(file_extension)) {
  //             dwf.icon = pngImg;
  //           } else if (file_extension === "pdf") {
  //             dwf.icon = pdfImg;
  //           } else if (
  //             ["docx", "doc", "rtf", "txt", "docs"].includes(file_extension)
  //           ) {
  //             dwf.icon = docImg;
  //           } else {
  //             dwf.icon = pngImg;
  //           }
  //         }
  //       }

  //       setDocuments(documentsWithFiles);
  //       setDocsLoading(false);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    let onlyFoldersList;
    if (selectedDocument && !selectedCheckedDoc?.length) {
      onlyFoldersList = documents?.filter(
        (doc) => doc?.type === "folder" && selectedDocument?.id !== doc?.id
      );
    } else {
      onlyFoldersList = documents?.filter((doc) => doc?.type === "folder");
    }

    setOnlyFolders(onlyFoldersList);
  }, [selectedDocument, selectedCheckedDoc, documents]);

  useEffect(() => {
    if (clientInfo?.ok) {
      setEnabledAppAccess(clientInfo?.client?.mobile_app_access);
    }
  }, [clientInfo]);

  useEffect(() => {
    // Clean up success state when component unmounts
    return () => {
      dispatch(resetAppAccessState());
      dispatch(resetResendClientCredsState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (clientId && token) {
      dispatch(getClientDataFunc(clientId));
      dispatch(getClientCasesDocsFunc(clientId));
      // getClientData();
    }
  }, [clientId, token]);

  useEffect(() => {
    if (clientCasesDocuments?.ok) {
      let folders = [];
      let files = [];

      clientCasesDocuments?.contents.forEach((doc) => {
        if (doc.type === "folder") {
          folders.push(doc);
        } else {
          files.push(doc);
        }
      });

      // Sort folders and files alphabetically
      folders.sort((a, b) => a.name.localeCompare(b.name));
      files.sort((a, b) => a.name.localeCompare(b.name));

      // Concatenate folders and files to get the desired order
      const sortedDocuments = folders.concat(files);

      setDocuments(sortedDocuments);
      // setDocuments([]);
      dispatch(resetClientCasesDocs());
    } else if (errorGetClientCasesDocs) {
      appInsights.trackTrace({
        message: `Error while getting documents in client: ${
          errorGetClientCasesDocs || "Getting documents failed in client!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_GETTING_CLIENT_DOCUMENTS",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${error || "Error getting client documents on backend!"} `,
      });
    }
  }, [clientCasesDocuments, errorGetClientCasesDocs]);

  useEffect(() => {
    if (clientInfo?.ok) {
      setClientData(clientInfo?.client);
      setFrontHobbies(clientInfo?.client?.hobbies || "[]");
      // getClientInfo();
    }
  }, [clientInfo]);

  useEffect(() => {
    if (clientData?.id) {
      getAgentData(clientData?.assigned_to);
    }
  }, [clientData]);

  const handleTitleClick = (file) => {
    if (selectedCheckedDoc?.length === 0) {
      setInnerFolderView(file);
      dispatch(innerFolderViewFunc(clientId, file?.id));
    }

    //   files.forEach((file) => {
    //     if (isSupportedType(file.file_name)) {
    //       lightboxFiles.push(file.file_url);
    //     } else {
    //       unsupportedFiles.push(file.file_url);
    //     }
    //   });
  };

  useEffect(() => {
    if (folderContents?.ok) {
      setInnerFolderContents(folderContents?.contents);
      setFolderDownloadContents(folderContents?.contents);
      resetInnerFolderView();
    }
  }, [folderContents]);

  const fsLightBoxViewHandler = (file) => {
    const lightboxFiles = [];
    const unsupportedFiles = [];

    if (isSupportedType(file?.blob_name)) {
      lightboxFiles.push(file?.file_url);
    } else {
      unsupportedFiles.push(file?.file_url);
    }

    // Open the unsupported files in new tabs
    unsupportedFiles.forEach((url) => window.open(url, "_blank"));

    // Open the supported files in FsLightbox
    if (lightboxFiles?.length > 0) {
      setLightboxSources(lightboxFiles);
      setToggler(!toggler);
    }

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "document_viewed",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const handleProfileClick = (event) => {
    setAnchorProfileEl(event.currentTarget);
  };

  const disableAppAccessHandler = () => {
    dispatch(disableAppAccessFunc(clientData?.id));
    setOpen(true);
    setDisableAppMessage("Client access revoked!");
    setEnabledAppAccess((prevState) => !prevState);
    setAnchorProfileEl(null);
    modalDisableAppCloseHandler();

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "client_access_revoked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const enableAppAccessHandler = () => {
    setEnabledAppAccess((prevState) => !prevState);
    dispatch(enableAppAccessFunc(clientData?.id));
    setOpen(true);
    setDisableAppMessage("Client access enabled!");
    setAnchorProfileEl(null);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "client_access_enabled",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const handleProfileClose = () => {
    setAnchorProfileEl(null);
  };

  const handleDocumentClick = (event) => {
    event.stopPropagation();
    setAnchorDocumentEl(event.currentTarget);
  };

  const handleRequestDocClick = (event) => {
    // console.log("EE", e);
    setAnchorRequestDocEl(event.currentTarget);
  };

  const handleDocumentClose = () => {
    setAnchorDocumentEl(null);
    setAnchorRequestDocEl(null);
  };

  const downlaodFolderPdf = async (folderId, selectedFile) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // responseType: "blob",
      };

      setAnchorDocumentEl(null);

      setSnackbarData({
        textContent: `Downloading ${selectedFile?.name}`,
        horizontal: "right",
        vertical: "bottom",
        severity: "success",
        color: "info",
      });
      setOpenSnackbar(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/storage/get-folder-as-pdf?client_id=${clientId}&folder_id=${folderId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error("Could not fetch the pdf link");
      }

      const pdfDownloadLink = response?.data?.pdfURL;
      // fetch(pdfDownloadLink)
      //   .then((response) => response.blob())
      //   .then((blob) => {
      //     const url = URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = pdfDownloadLink;
      anchor.download = `${selectedFile?.name}.pdf`; // Set the desired filename for downloaded file
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      console.error("Error creating or downloading the PDF:", error);
    }
  };

  const downloadImagePdfHandler = async (fileDetail) => {
    const imageName = onlyImageName(fileDetail?.file_name);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // responseType: "blob",
      };

      setAnchorDocumentEl(null);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/storage/get-file-as-pdf?client_id=${clientId}&file_id=${fileDetail?.id}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error("Could not fetch the pdf link");
      }

      setSnackbarData({
        textContent: `Downloading ${imageName}.pdf`,
        horizontal: "right",
        vertical: "bottom",
        severity: "success",
        color: "info",
      });
      setOpenSnackbar(true);

      const pdfDownloadLink = response?.data?.pdfURL;
      // fetch(pdfDownloadLink)
      //   .then((response) => response.blob())
      //   .then((blob) => {
      //     const url = URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = pdfDownloadLink;
      anchor.download = `${imageName}.pdf`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      //   URL.revokeObjectURL(url);
      // })
      // .catch((error) => {
      //   console.error("Error downloading PDF:", error);
      // });
    } catch (error) {
      console.error("Error creating or downloading the PDF:", error);
    }
  };

  const downloadImgAsPdfHandler = async () => {
    if (
      selectedDocument &&
      selectedDocument?.type === "folder" &&
      innerSelectedFile
    ) {
      downloadImagePdfHandler(innerSelectedFile);
    } else if (selectedDocument?.type === "folder") {
      const folderId = selectedDocument?.id;
      downlaodFolderPdf(folderId, selectedDocument);
    } else {
      downloadImagePdfHandler(selectedDocument);
    }
  };

  const handleDownloadClick = async () => {
    setAnchorDocumentEl(null);

    setSnackbarData({
      textContent: `Downloading ${
        innerSelectedFile ? innerSelectedFile?.name : selectedDocument?.name
      }`,
      horizontal: "right",
      vertical: "bottom",
      severity: "success",
      color: "info",
    });
    setOpenSnackbar(true);

    if (
      selectedDocument &&
      selectedDocument?.type === "folder" &&
      innerSelectedFile
    ) {
      const fileName = innerSelectedFile || selectedDocument;

      if (fileName) {
        const response = await fetch(fileName?.download_url);
        const blob = await response.blob();
        saveAs(blob, fileName?.name);
      }
    } else if (selectedDocument?.type === "file") {
      if (selectedDocument) {
        const response = await fetch(selectedDocument?.download_url);
        const blob = await response.blob();
        saveAs(blob, selectedDocument?.name);
      }
    } else {
      try {
        const folderId = selectedDocument?.id;
        // const folderData = await axios.get()

        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/storage/get-folder-contents?client_id=${clientId}&folder_id=${folderId}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(
            response?.data?.message || "Error while downloading folder"
          );
        }

        const zip = new JSZip();

        // Fetch all files and add them to the zip
        const fetchPromises = response?.data?.contents?.map(async (file) => {
          const response = await fetch(file?.download_url);
          const blob = await response.blob();
          zip.file(file?.file_name, blob);
        });

        // Wait for all files to be added to the zip
        await Promise.all(fetchPromises);

        // Generate the zip blob and initiate the download
        const zipBlob = await zip.generateAsync({ type: "blob" });
        saveAs(zipBlob, `${selectedDocument?.name}.zip`);

        // console.log("FFF", folderData);
      } catch (error) {
        const folderError =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        console.log(folderError);
      }

      //   // Assuming download logic is handled by another function
      //   // handleDownload(folderContents);
      // })
      // .catch((error) => {
      //   console.error("Error fetching folder contents:", error);
      // });

      // const zip = new JSZip();

      // // Fetch all files and add them to the zip
      // const fetchPromises = menuFileDownloadURLs?.map(async (file) => {
      //   const response = await fetch(file?.download_url);
      //   const blob = await response.blob();
      //   zip.file(file?.file_name, blob);
      // });

      // // Wait for all files to be added to the zip
      // await Promise.all(fetchPromises);

      // // Generate the zip blob and initiate the download
      // const zipBlob = await zip.generateAsync({ type: "blob" });
      // saveAs(zipBlob, `${selectedDocument?.name}.zip`);
    }

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "document_downloaded",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  // OPEN DOCUMENT DELETE CONFIRM MODAL
  const documentDeleteHandler = (item) => {
    setModalContentData({
      title: "Are you sure?",
      subtitle: innerSelectedFile
        ? "This will permanently delete this document."
        : selectedDocument?.type === "folder"
        ? "This will permanently delete this folder and all the documents in it."
        : "This will permanently delete this document.",
    });
    modalOpenHandler();
    setAnchorDocumentEl(null);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "delete_document_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  // OPEN PROFILE DELETE CONFIRM MODAL
  const openProfileDeleteConfirmHandler = () => {
    setModalContentData({
      title: "Are you sure?",
      subtitle: "This action will permanently delete this client.",
    });
    modalProfileOpenHandler();
    setAnchorProfileEl(null);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "delete_client_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  // DELETING PROFILE CLIENT HANDLER
  const deleteClientProfileHandler = () => {
    const clientDeleteData = { client_id: clientData?.id };
    dispatch(deleteClientFunc(clientDeleteData));

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "client_deleted",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  useEffect(() => {
    if (deletedClient?.ok) {
      navigate("/clients");
      modalProfileCloseHandler();

      dispatch(resetDeleteClient());
    }
  }, [deletedClient]);

  // OPEN DELETE PROFILE MODAL
  const modalProfileOpenHandler = () => {
    setOpenProfileModal(true);
  };

  const modalProfileCloseHandler = () => {
    setOpenProfileModal(false);
  };

  // OPEN DISABLE APP ACCESS MODAL

  const modalDisableAppOpenHandler = () => {
    setOpenDisableModal(true);
  };

  const modalDisableAppCloseHandler = () => {
    setOpenDisableModal(false);
  };

  //  OPEN DISABLE CONFIRM MODAL
  const openDisableConfirmModal = () => {
    setModalContentData({
      title: "Are you sure?",
      subtitle: `${clientData?.name} will no longer be able to access mobile app.`,
    });
    modalDisableAppOpenHandler();
    setAnchorProfileEl(null);
  };

  // TOGGLE OVERVIEW DRAWER
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOverviewDrawerState({ ...overviewDrawerState, [anchor]: open });
  };

  const navigateToCaseHandler = (caseId) => {
    if (caseId) {
      navigate(`/patient/${caseId}`);
    }
  };

  const [agent, setAgent] = useState({});

  const getAgentData = async (assigned_to) => {
    if (assigned_to) {
      const agent_id = JSON.parse(assigned_to)[0];
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/agent/get?agent_id=${agent_id}`,
        config
      );

      setAgent(data?.agent);
    }
  };

  const showInputHandler = () => {
    setShowInput(true);
  };

  // const addHobbiesTagHandler = async (e) => {
  //   e.preventDefault();

  //   let updatedItems;

  //   if (!hobbiesTag.includes(hobbyInput)) {
  //     updatedItems = [...hobbiesTag, hobbyInput];
  //     setFrontHobbies(
  //       JSON.stringify([...JSON.parse(frontHobbies), hobbyInput])
  //     );
  //     setHobbiesTag(updatedItems);
  //     setHobbyInput("");
  //     setShowInput(false);

  //     try {
  //       const config = {
  //         headers: {
  //           "Content-type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       };
  //       const client_obj = {
  //         hobbies: JSON.stringify(updatedItems),
  //       };
  //       const clientData = {
  //         client_id: rowData?.client?.id,
  //         client_profile: client_obj,
  //       };

  //       const res = await axios.post(
  //         `${process.env.REACT_APP_BASE_URL}/agent/update-client`,
  //         clientData,
  //         config
  //       );
  //       // console.log("RESSS", res);
  //       getClientData();

  //       // getClientsList();
  //       // setStatus({
  //       //   type: "success",
  //       //   message: `Client ${update ? "updated" : "added"}`,
  //       // });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  // const handleDelete = async (h) => {
  //   setFrontHobbies(
  //     JSON.stringify(JSON.parse(frontHobbies).filter((hobby) => hobby !== h))
  //   );

  //   const deletedItems = hobbiesTag.filter((hobby) => hobby !== h);

  //   setHobbiesTag(deletedItems);
  //   setHobbyInput("");
  //   setShowInput(false);

  //   try {
  //     const config = {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };
  //     const client_obj = {
  //       hobbies: JSON.stringify(deletedItems),
  //     };

  //     const clientData = {
  //       client_id: rowData?.client?.id,
  //       client_profile: client_obj,
  //     };

  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/agent/update-client`,
  //       clientData,
  //       config
  //     );
  //     // console.log("RESSS", res);
  //     getClientData();

  //     // setStatus({
  //     //   type: "success",
  //     //   message: `Client ${update ? "updated" : "added"}`,
  //     // });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const openEditDrawerHandler = () => {
    setUpdateClient(true);
    setOpenEditDrawer(true);
    setFromClientOverview(true);
    // dispatch(resetUpdatedTeamMember());
    // dispatch(resetUpdateCase());
    // setFromCaseOverview(true);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "edit_client_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const resendCredentialsHandler = () => {
    dispatch(resendClientCredentialsFunc(clientData?.id));
    handleProfileClose();
  };

  useEffect(() => {
    if (!loadingCredentials && clientCreds?.ok) {
      setOpen(true);
    }
  }, [clientCreds]);

  // ************* DOCUMENTS HANDLER  ******************

  // RENAME DOCUMENT FUNCTION
  const renameDocumentHandler = () => {
    setModalContentData({
      title: innerSelectedFile
        ? "Document Rename"
        : selectedDocument?.type === "folder"
        ? "Folder Rename"
        : "Document Rename",
    });
    // setFromInnerFolderFile(text ? true : false);
    setOpenRenameModal(true);
    setAnchorDocumentEl(null);
  };

  // DOCUMENTS LAYOUT VIEW HANDLER
  const documentViewHandler = (event, nextView) => {
    setDocumentView(nextView);
    // setSelectedCheckedDoc([]);
  };

  // OPEN CREATE FOLDER MODAL
  const createNewFolderHandler = (e) => {
    setModalContentData({
      title: "Create New Folder",
    });
    setOpenCreateFolderModal(true);
    setNewFolderName("");
  };

  const documentRequestTitleHandler = (doc) => {
    const matchedCase = clientData?.cases?.filter(
      (item) => item?.id === doc?.case_id
    );

    return matchedCase && matchedCase[0]?.title;
  };

  // DELETING PARTICULAR DOCUMENT HANDLER
  const deletingDocHandler = () => {
    let fileObj;
    let folderObj;
    let filesToDelete;
    let foldersToDelete;
    if (innerSelectedFile && !innerSelectedCheckedDoc?.length) {
      // DELETE SINGLE FILE INSIDE FOLDER
      fileObj = {
        client_id: clientId,
        file_ids: [innerSelectedFile?.id],
      };

      dispatch(deleteFileFunc(fileObj));
    } else if (innerSelectedCheckedDoc?.length) {
      // DELETE MULTIPLE FILES INSIDE FOLDER
      const fileIds = innerSelectedCheckedDoc?.map((file) => file?.id);
      fileObj = {
        client_id: clientId,
        file_ids: fileIds,
      };

      dispatch(deleteFileFunc(fileObj));
    } else if (selectedCheckedDoc?.length) {
      // DELETE MULTIPLE FILES OR FOLDERS IN ROOT
      filesToDelete = selectedCheckedDoc
        ?.filter((item) => item?.type === "file")
        ?.map((file) => file?.id);

      foldersToDelete = selectedCheckedDoc
        ?.filter((item) => item?.type === "folder")
        ?.map((file) => file?.id);

      fileObj = {
        client_id: clientId,
        file_ids: filesToDelete,
      };

      folderObj = {
        folder_ids: foldersToDelete,
      };

      filesToDelete?.length && dispatch(deleteFileFunc(fileObj));
      foldersToDelete?.length && dispatch(deleteFolderFunc(folderObj));
    } else {
      // DELETE SINGLE DOCUMENT IN ROOT

      fileObj = {
        client_id: clientId,
        file_ids: [selectedDocument?.id],
      };

      folderObj = { folder_ids: [selectedDocument?.id] };

      selectedDocument?.type === "file"
        ? dispatch(deleteFileFunc(fileObj))
        : dispatch(deleteFolderFunc(folderObj));
    }

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "document_deleted",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  useEffect(() => {
    if (deletedFile?.ok) {
      dispatch(getClientCasesDocsFunc(clientId));
      (innerSelectedCheckedDoc?.length || innerSelectedFile) &&
        dispatch(innerFolderViewFunc(clientId, selectedDocument?.id));

      modalCloseHandler();
      innerSelectedCheckedDoc?.length
        ? setInnerSelectedCheckedDoc([])
        : setSelectedCheckedDoc([]);
      dispatch(resetDeleteFile());
    }
  }, [deletedFile]);

  useEffect(() => {
    if (deletedFolder?.ok) {
      modalCloseHandler();
      dispatch(getClientCasesDocsFunc(clientId));

      setSelectedCheckedDoc([]);
      dispatch(resetDeleteFolder());
    }
  }, [deletedFolder]);

  // ************* RENAMING DOCUMENTS OR FOLDER ******************

  // RENAME DOCUMENT/FILENAME FUNCTION
  const renameFileFunctionHandler = () => {
    setFieldIsTouched(true);
    const fileObj = {
      client_id: clientId,
      file_id: innerSelectedFile?.id,
      new_file_name: newFileName + fileExtension,
    };

    if (!newFileName) return;

    dispatch(renameFileFunc(fileObj));
  };

  useEffect(() => {
    if (renameFileName?.ok) {
      innerSelectedFile
        ? dispatch(innerFolderViewFunc(clientId, selectedDocument?.id))
        : dispatch(getClientCasesDocsFunc(clientId));
      setOpenRenameModal(false);
      // setInnerFolderView(null);
      setNewFileName("");
      setFileExtension("");

      dispatch(resetRenameFile());
    }
  }, [renameFileName]);

  // RENAME FOLDER FUNCTION
  const renameFolderAndFileFunctionHandler = () => {
    setFieldIsTouched(true);
    let fileObj;
    if (selectedDocument?.type === "folder") {
      fileObj = {
        folder_id: selectedDocument?.id,
        new_name: newFolderName,
      };

      if (!newFolderName) return;

      dispatch(renameFolderFunc(fileObj));
    } else {
      const fileObj = {
        client_id: clientId,
        file_id: selectedDocument?.id,
        new_file_name: newFileName + fileExtension,
      };

      if (!newFileName) return;
      dispatch(renameFileFunc(fileObj));
    }
  };

  useEffect(() => {
    if (renameFolderName?.ok) {
      dispatch(getClientCasesDocsFunc(clientId));
      setOpenRenameModal(false);
      setNewFolderName("");
      // setOpenCreatedFolderMessage(true);

      dispatch(resetRenameFolder());
    }
  }, [renameFolderName]);

  // ****************** MOVING FILE TO FOLDER OR ROOT ****************

  // CHANGE FILE NAME HANDLER
  const changeFileNameHandler = (e) => {
    setNewFileName(e.target.value);
  };

  // CHANGE FOLDER NAME
  const changeFolderNameHandler = (e) => {
    setNewFolderName(e.target.value);
  };

  // MOVE FOLDER MODAL
  const moveToNewFolderHandler = (selectedFile) => {
    setModalContentData({
      title: "Move to New Folder",
    });
    setFileToMove(selectedFile);
    setMoveFolderOpenModal(true);
    setAnchorDocumentEl(null);
  };

  // MOVE FILE DIALOG MODAL
  const moveFileDialogHandler = () => {
    setModalContentData({
      title: "Move",
      lightText: innerSelectedFile
        ? `${innerSelectedFile?.name}`
        : selectedCheckedDoc?.length === 1
        ? `${selectedCheckedDoc[0]?.name}`
        : selectedCheckedDoc?.length >= 2
        ? `${selectedCheckedDoc?.length} files`
        : `${selectedDocument?.name}`,
    });
    setNewFolderName("");
    setFileToMove(null);
    setMoveFileDialog(true);
    setAnchorDocumentEl(null);
  };

  // MOVE FILE DIALOG MODAL
  const moveFilesInFolderDialogHandler = () => {
    setModalContentData({
      title: "Move",
      lightText:
        innerSelectedCheckedDoc?.length === 1
          ? `${innerSelectedCheckedDoc[0]?.file_name}`
          : `${innerSelectedCheckedDoc?.length} files`,
    });

    setMoveFileDialog(true);
    setAnchorDocumentEl(null);
  };

  // OPEN DOCUMENT DELETE CONFIRM MODAL
  const multipleDocumentsDeleteHandler = (e) => {
    setModalContentData({
      title: "Are you sure?",
      subtitle:
        selectedCheckedDoc?.length <= 1
          ? selectedCheckedDoc[0]?.type === "folder"
            ? `This will permanently delete "${selectedCheckedDoc[0]?.name}" folder and all the files inside it.`
            : `This will permanently delete "${selectedCheckedDoc[0]?.name}" file.`
          : "This will permanently delete the selected files and folders ( including files inside it )",
    });
    modalOpenHandler();
    setAnchorDocumentEl(null);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "delete_document_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  // OPEN DOCUMENT DELETE DIALOG WHEN INNNER FILES SELECTED
  const multipleFilesDeleteHandler = (e) => {
    setModalContentData({
      title: "Are you sure?",
      subtitle:
        innerSelectedCheckedDoc?.length <= 1
          ? `This will permanently delete "${innerSelectedCheckedDoc[0]?.name}" file.`
          : `This will permanently delete ${innerSelectedCheckedDoc?.length} files.`,
    });
    modalOpenHandler();
    setAnchorDocumentEl(null);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "delete_document_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  // SELECTED MOVE FOLDER LIST HANDLER
  const selectedMoveFolderListHandler = (doc) => {
    setBreadcrumbs((prevBreadcrumbs) => {
      const isAlreadySelected = prevBreadcrumbs.includes(doc?.name);

      if (isAlreadySelected) {
        setSelectedMoveFolderList(null);
        return prevBreadcrumbs.filter((breadcrumb) => breadcrumb !== doc?.name);
      } else {
        setSelectedMoveFolderList(doc);
        return ["Home", doc?.name];
      }
    });
  };

  // OPEN ADD DOCUMENTS DRAWER FROM ADD MORE DOCUMENTS
  const addMoreDocumentsHandler = () => {
    if (!innerFolderView) {
      setSelectedDocument(null);
    }
    toggleDrawer("right", true);
    // setInnerFolderView(false);
    setDrawerOpen(true);
    setAnchorDocumentEl(null);
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "new_document_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  // SEND REQUEST DOC REMINDER
  const sendRequestDocReminderHandler = () => {
    setAnchorRequestDocEl(null);
    const reminderObj = {
      client_id: clientId,
      document_request_id: selectedDocument?.id,
    };
    dispatch(sendDocReminderFunc(reminderObj));
    setSnackbarData({
      textContent: `Reminder Sent`,
      horizontal: "center",
      vertical: "top",
      severity: "success",
      color: "success",
    });
    setOpenSnackbar(true);
  };

  useEffect(() => {
    if (sentDocReminder?.ok) {
      resetSendDocReminder();
    }
  }, [sentDocReminder]);

  // CANCEL DOC REQUEST HANDLER
  const cancelDocRequestHandler = () => {
    const docReqObj = {
      document_request_id: selectedDocument?.id,
    };

    setAnchorRequestDocEl(null);
    dispatch(cancelDocRequestFunc(docReqObj));
    setSnackbarData({
      textContent: `Request Cancelled`,
      horizontal: "center",
      vertical: "top",
      severity: "success",
      color: "success",
    });
    setOpenSnackbar(true);
  };

  useEffect(() => {
    if (cancelledDocument?.ok) {
      dispatch(getClientDataFunc(clientId));
      resetCancelDocRequest();
    }
  }, [cancelledDocument]);

  // BREADCRUMB LIST HANDLER
  const emptyBreadcrumbHandler = () => {
    setSelectedMoveFolderList(null);
    setBreadcrumbs(["Home"]);
  };

  const handleBoxClick = (e) => {
    e.stopPropagation();
  };

  const changeCheckBoxHandler = (doc) => {
    const isItemSelected = selectedCheckedDoc?.some(
      (selectedItem) => selectedItem?.unique_id === `${doc?.type}-${doc?.id}`
    );

    if (isItemSelected) {
      const newSelectedCheckboxes = selectedCheckedDoc?.filter(
        (selectedItem) => selectedItem?.unique_id !== `${doc?.type}-${doc?.id}`
      );
      setSelectedCheckedDoc(newSelectedCheckboxes);
    } else {
      setSelectedCheckedDoc([
        ...selectedCheckedDoc,
        { ...doc, unique_id: `${doc?.type}-${doc?.id}` },
      ]);
    }
  };

  const changeInnerCheckBoxHandler = (innerDoc) => {
    const isItemSelected = innerSelectedCheckedDoc?.some(
      (selectedItem) => selectedItem?.index_id === innerDoc?.index_id
    );

    if (isItemSelected) {
      const newSelectedCheckBoxes = innerSelectedCheckedDoc?.filter(
        (selectedItem) => selectedItem?.index_id !== innerDoc?.index_id
      );
      setInnerSelectedCheckedDoc(newSelectedCheckBoxes);
    } else {
      setInnerSelectedCheckedDoc([...innerSelectedCheckedDoc, innerDoc]);
    }
  };

  // CREATING NEW FOLDER FUNCTION
  const creatingNewFolderHandler = () => {
    setFieldIsTouched(true);
    const doc_type = "client_level";

    const documentObj = {
      client_id: clientId,
      case_id: null,
      parent_id: clientData?.root_folder_id,
      folder_name:
        fileToMove && selectedDocument ? moveFolderName : newFolderName,
      folder_type: doc_type,
    };

    if (!newFolderName) return;

    dispatch(createEmptyFolderFunc(documentObj));
  };

  useEffect(() => {
    if (emptyFolder?.ok) {
      setFieldIsTouched(false);
      const createdFolderId = emptyFolder?.new_folder?.id;

      if (createdFolderId && fileToMove) {
        const fileObj = {
          client_id: clientId,
          destination_folder_id: createdFolderId,
          file_ids: [selectedDocument?.id],
        };
        dispatch(moveFileToFolderFunc(fileObj));
        setFileToMove(null);
        setMoveFolderOpenModal(false);
      }

      setMoveFolderName("");
      setShowNewFolderBox(false);
      setNewFolderName("");
      dispatch(getClientCasesDocsFunc(clientId));
      setOpenCreateFolderModal(false);
      setOpenCreatedFolderMessage(true);

      dispatch(resetCreateEmptyFolder());
    }
  }, [emptyFolder]);

  const showError = fieldIsTouched && newFolderName?.trim() === "";
  const moveToNewFileError = fieldIsTouched && moveFolderName?.trim() === "";
  const renameFileError = fieldIsTouched && newFileName?.trim() === "";

  // MOVE FILE TO FOLDER / ROOT FUNCTION HANDLER
  const moveFileToFolderFunctionHandler = () => {
    let fileObj;

    if (
      !selectedCheckedDoc?.length &&
      !innerSelectedFile &&
      !innerSelectedCheckedDoc?.length &&
      selectedMoveFolderList
    ) {
      // MOVE SINGLE FILE FROM ROOT TO FOLDER
      fileObj = {
        client_id: clientId,
        destination_folder_id: selectedMoveFolderList?.id,
        file_ids: [selectedDocument?.id],
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else if (innerSelectedFile && selectedMoveFolderList) {
      // MOVE SINGLE FILE FROM INSIDE FOLDER TO ANOTHER FOLDER
      fileObj = {
        client_id: clientId,
        destination_folder_id: selectedMoveFolderList?.id,
        file_ids: [innerSelectedFile?.id],
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else if (
      innerSelectedFile &&
      !selectedCheckedDoc?.length &&
      !selectedMoveFolderList
    ) {
      // MOVE SINGLE FILE FROM INSIDE FOLDER TO ROOT
      fileObj = {
        client_id: clientId,
        destination_folder_id: clientData?.root_folder_id,
        file_ids: [innerSelectedFile?.id],
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else if (selectedCheckedDoc?.length && selectedMoveFolderList) {
      // MOVE MULTIPLE FILES FROM ROOT TO FOLDER
      const fileIds = selectedCheckedDoc?.map((doc) => doc?.id);
      fileObj = {
        client_id: clientId,
        destination_folder_id: selectedMoveFolderList?.id,
        file_ids: fileIds,
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else if (innerSelectedCheckedDoc?.length && selectedMoveFolderList) {
      // MOVE MULTIPLE FILES FROM INSIDE FOLDER TO FOLDER
      const fileIds = innerSelectedCheckedDoc?.map((doc) => doc?.id);

      fileObj = {
        client_id: clientId,
        destination_folder_id: selectedMoveFolderList?.id,
        file_ids: fileIds,
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else if (innerSelectedCheckedDoc?.length && !selectedMoveFolderList) {
      // MOVE MULTIPLE FILES FROM INSIDE FOLDER TO ROOT
      const fileIds = innerSelectedCheckedDoc?.map((doc) => doc?.id);

      fileObj = {
        client_id: clientId,
        destination_folder_id: clientData?.root_folder_id,
        file_ids: fileIds,
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else {
      setMoveFileDialog(false);
      setSelectedCheckedDoc([]);
    }
  };

  useEffect(() => {
    if (moveFileToFolders?.ok) {
      (innerSelectedFile || innerSelectedCheckedDoc?.length) &&
        dispatch(innerFolderViewFunc(clientId, selectedDocument?.id));

      dispatch(getClientCasesDocsFunc(clientId));
      setMoveFileDialog(false);
      emptyBreadcrumbHandler();
      dispatch(resetMoveToFolder());
      setInnerSelectedCheckedDoc([]);
      setSelectedCheckedDoc([]);
      setInnerSelectedFile(null);
    } else {
      setFileMovingMessage(true);
      dispatch(resetMoveToFolder());
      setMoveFileDialog(false);
      emptyBreadcrumbHandler();
      setInnerSelectedCheckedDoc([]);
      setInnerSelectedFile(null);
    }
  }, [moveFileToFolders]);

  // CREATING MOVE FOLDER FUNCTION
  const creatingMoveFolderFunction = () => {
    setFieldIsTouched(true);
    if (!moveFolderName) return;

    creatingNewFolderHandler();
  };

  const parentCheckedState =
    selectedCheckedDoc?.length === 0
      ? "none"
      : selectedCheckedDoc?.length === documents?.length
      ? "all"
      : "some";

  const innerParentCheckedState =
    innerSelectedCheckedDoc?.length === 0
      ? "none"
      : innerSelectedCheckedDoc?.length === selectedDocument?.files?.length
      ? "all"
      : "some";

  // PARENT CHECK BOX HANDLER
  const changeParentCheckedBoxHandler = () => {
    if (innerSelectedCheckedDoc?.length) {
      const allSelected = innerFolderContents?.every((doc, i) =>
        innerSelectedCheckedDoc?.some((innerItem) => innerItem?.id === doc?.id)
      );
      const noneSelected = innerSelectedCheckedDoc?.length === 0;

      if (allSelected || noneSelected) {
        // Unselect all if all are selected or none are selected
        setInnerSelectedCheckedDoc([]);
      } else {
        // Select all if some are selected
        setInnerSelectedCheckedDoc(
          innerFolderContents?.map((item, i) => ({
            ...item,
            index_id: `${selectedDocument?.id}-${i}`,
          }))
        );
      }
    } else {
      const allSelected = documents?.every((item, index) =>
        selectedCheckedDoc?.some(
          (selectedItem) =>
            `${item?.type}-${item?.id}` === selectedItem?.unique_id
        )
      );
      const noneSelected = selectedCheckedDoc?.length === 0;

      if (allSelected || noneSelected) {
        // Unselect all if all are selected or none are selected
        setSelectedCheckedDoc([]);
      } else {
        // Select all if some are selected
        setSelectedCheckedDoc(
          documents?.map((item) => ({
            ...item,
            unique_id: `${item?.type}-${item?.id}`,
          }))
        );
      }
    }
  };

  const isFolderIncluded = selectedCheckedDoc?.some(
    (item) => item?.type === "folder"
  );

  return (
    <>
      {openEditDrawer && (
        <InviteDrawer
          anchor={"right"}
          open={openEditDrawer}
          setOpen={setOpenEditDrawer}
          onClose={() => setOpenEditDrawer(false)}
          update={updateClient}
          setUpdate={setUpdateClient}
          client={clientData}
          fromClientOverview={fromClientOverview}
          // getClientData={getClientData}
          // getClientsList={getClientsList}
        />
      )}
      {open && (
        <AlertMessage
          message={disableAppMessage}
          severity="success"
          open={open}
          handleClose={handleClose}
        />
      )}

      {!loadingCredentials && clientCreds?.ok && (
        <AlertMessage
          message="Credentials has been sent to your email!"
          severity="success"
          open={open}
          handleClose={handleClose}
        />
      )}
      {!loadingCredentials && errorCredentials && (
        <AlertMessage
          message={clientCreds?.message}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {/* DELETE ALERT MODAL */}
      {openModal && (
        <AlertDialog
          openModal={openModal}
          closeModal={modalCloseHandler}
          isLoading={loadingDeleteFolder || loadingDeleteFile}
          data={modalContentData}
          formSubmitFunction={deletingDocHandler}
        />
      )}

      {/* RENAME FILE NAME ALERT */}
      {openRenameModal && (
        <AlertDialog
          openModal={openRenameModal}
          closeModal={modalCloseHandler}
          data={modalContentData}
          submitText="Save"
          isLoading={
            selectedDocument?.type === "folder" && !innerSelectedFile
              ? loadingRenameFolder
              : loadingRenameFile
          }
          formSubmitFunction={
            innerSelectedFile
              ? renameFileFunctionHandler
              : renameFolderAndFileFunctionHandler
          }
        >
          {innerSelectedFile ? (
            <TextField
              size="medium"
              value={newFileName}
              onChange={changeFileNameHandler}
              label="Document name"
              error={renameFileError}
              fullWidth
            />
          ) : selectedDocument?.type === "folder" ? (
            <TextField
              size="medium"
              value={newFolderName}
              onChange={changeFolderNameHandler}
              label="Folder name"
              error={showError}
              fullWidth
            />
          ) : (
            <TextField
              size="medium"
              value={newFileName}
              onChange={changeFileNameHandler}
              label="Document name"
              error={renameFileError}
              fullWidth
            />
          )}
        </AlertDialog>
      )}

      {/* CREATE NEW FOLDER MODAL ALERT */}
      {openCreateFolderModal && (
        <AlertDialog
          openModal={openCreateFolderModal}
          closeModal={modalCloseHandler}
          data={modalContentData}
          submitText="Save"
          isLoading={loadingCreateEmptyFolder}
          formSubmitFunction={creatingNewFolderHandler}
        >
          <Stack gap="16px">
            <TextField
              size="medium"
              value={newFolderName}
              onChange={changeFolderNameHandler}
              label="Folder name"
              fullWidth
              error={showError}
            />
          </Stack>
        </AlertDialog>
      )}

      {/* CREATE AND MOVE NEW FOLDER MODAL ALERT */}
      {moveFolderOpenModal && (
        <AlertDialog
          openModal={moveFolderOpenModal}
          closeModal={modalCloseHandler}
          data={modalContentData}
          submitText="Create and Move"
          isLoading={loadingCreateEmptyFolder || loadingMoveFileToFolder}
          formSubmitFunction={creatingMoveFolderFunction}
        >
          <Stack gap="16px">
            <TextField
              size="medium"
              value={moveFolderName}
              onChange={(e) => setMoveFolderName(e.target.value)}
              label="Folder name"
              fullWidth
              error={moveToNewFileError}
            />
          </Stack>
        </AlertDialog>
      )}

      {/* MOVE FILE DIALOG  */}
      {moveFileDialog && (
        <AlertDialog
          openModal={moveFileDialog}
          closeModal={modalCloseHandler}
          data={modalContentData}
          submitText="Move Here"
          maxWidth="727px"
          isLoading={loadingMoveFileToFolder}
          padding="16px 32px 32px 32px"
          moveFileDialog={true}
          titlePaddingTop="32px"
          contentPaddingTop="0px !important"
          formSubmitFunction={moveFileToFolderFunctionHandler}
        >
          <MoveFileLayout
            breadcrumbs={breadcrumbs}
            emptyBreadcrumbHandler={emptyBreadcrumbHandler}
            showError={showError}
            setShowNewFolderBox={setShowNewFolderBox}
            showNewFolderBox={showNewFolderBox}
            documentRequestTitle={documentRequestTitleHandler}
            fromClientPage={true}
            onlyFoldersList={onlyFolders}
            selectedMoveFolderList={selectedMoveFolderList}
            selectedMoveFolderListHandler={selectedMoveFolderListHandler}
            createFolderHandler={creatingNewFolderHandler}
            newFolderName={newFolderName}
            setNewFolderName={setNewFolderName}
            loadingCreateEmptyFolder={loadingCreateEmptyFolder}
          />
        </AlertDialog>
      )}

      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={closeSnackbarHandler}
          anchorOrigin={{
            vertical: snackbarData?.vertical || "bottom",
            horizontal: snackbarData?.horizontal || "right",
          }}
        >
          <Alert
            onClose={closeSnackbarHandler}
            severity={snackbarData.severity}
            color={snackbarData.color}
            sx={{ width: "100%" }}
          >
            {snackbarData.textContent}
          </Alert>
        </Snackbar>
      )}

      <>
        <MUIButton
          variant="text"
          disableRipple
          disableFocusRipple
          startIcon={
            <Paper
              sx={{
                background: "#fff",
                boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
                borderRadius: "50%",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ArrowLeftIcon width="100%" height="100%" />
            </Paper>
          }
          color="secondary"
          sx={{
            textTransform: "capitalize",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent",
            },
          }}
          onClick={() => navigate("/clients")}
        >
          Go back
        </MUIButton>
        <Grid container>
          {/* CLIENT OVERVIEW PROFILE SECTION */}
          <Grid item xs={12} md={4} width="442px" sx={{}}>
            <Stack
              sx={{
                boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
                background: "#fff",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <Stack position="relative" direction="column">
                <Box
                  height="114px"
                  sx={{
                    background: loadingClient
                      ? "#fff"
                      : "radial-gradient( #800CF317, #0C80F317)",
                  }}
                ></Box>

                <StyledImgBox p="3px" bgcolor="#fff">
                  {loadingClient ? (
                    <Skeleton
                      sx={{ position: "absolute", top: 0, left: 0 }}
                      variant="circular"
                      width={108}
                      height={108}
                    />
                  ) : (
                    <Avatar
                      src={clientData?.profile_picture_url}
                      alt="company logo"
                      className="profile__img"
                    />
                  )}
                </StyledImgBox>
              </Stack>

              <Stack p="10px 24px">
                <Stack
                  justifyContent="flex-end"
                  // alignItems="flex-end"
                  direction="row"
                >
                  <IconButton onClick={openEditDrawerHandler}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    id="profile-btn"
                    aria-controls={openProfileMenu ? "profile-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openProfileMenu ? "true" : undefined}
                    onClick={handleProfileClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="profile-menu"
                    anchorEl={anchorProfileEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={openProfileMenu}
                    onClose={handleProfileClose}
                    MenuListProps={{
                      "aria-labelledby": "profile-button",
                    }}
                  >
                    {enabledAppAccess ? (
                      <MenuItem onClick={openDisableConfirmModal}>
                        Disable app access
                      </MenuItem>
                    ) : (
                      <MenuItem onClick={enableAppAccessHandler}>
                        Enable app access
                      </MenuItem>
                    )}

                    <MenuItem onClick={resendCredentialsHandler}>
                      Resend Credentials
                    </MenuItem>
                    <MenuItem
                      onClick={openProfileDeleteConfirmHandler}
                      sx={{ color: "#FF2D2D" }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </Stack>
              </Stack>

              <Stack direction="column" p="12px 32px">
                {loadingClient ? (
                  <RectangleSkeletons totalRows={2} height={30} width={175} />
                ) : (
                  <>
                    <HeaderText
                      fontSize="24px"
                      textContent={clientData?.name}
                      textAlign="-webkit-auto"
                      variant="h5"
                    />
                    <HeaderText
                      fontSize="14px"
                      textContent={clientData?.email}
                      variant="h6"
                      fontWeight="400"
                      textAlign="-webkit-auto"
                    />
                  </>
                )}
              </Stack>

              {/* <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              rowGap="12px"
              gap="8px"
              p="8px 32px 24px"
              // sx={{
              //   gap: "8px",
              // }}
            >
              {frontHobbies && (
                <>
                  {JSON.parse(frontHobbies).map((hobby, i) => (
                    <Chip
                      label={hobby}
                      key={i}
                      onDelete={() => handleDelete(hobby)}
                      sx={{
                        marginLeft: 0,
                        color: "#2196F3",
                        background: "rgba(33, 150, 243, 0.15)",
                        "& .MuiChip-deleteIcon": {
                          color: "#2196F3",
                        },
                      }}
                    />
                  ))}
                </>
              )}
              {showInput && (
                <Box component="form" onSubmit={addHobbiesTagHandler}>
                  <Textfield
                    // error={isTouched && !!error}
                    // helperText={error?.message}
                    margin="dense"
                    variant="standard"
                    name="hobbies"
                    value={hobbyInput}
                    onChange={(e) => setHobbyInput(e.target.value)}

                    // value={value || ''}
                    // onChange={onChange}
                  />
                </Box>
              )}

              <Tooltip title={"Add new hobbies"}>
                <IconButton
                  sx={{
                    background: "rgba(0, 0, 0, 0.08)",
                    color: "#aeaeae",
                    width: "24px",
                    height: "24px",
                    padding: "14px",
                  }}
                  variant="contained"
                  size="small"
                  onClick={showInputHandler}
                >
                  <AddCircleRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box> */}

              <Divider />

              <Box
                sx={{
                  // overflowY: "auto",
                  // height: "376px",
                  scrollbarWidth: "none", // For Firefox
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <Stack padding="24px 32px" gap="16px">
                  <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle2"
                      fontSize="18px"
                      color="disabled.customs"
                    >
                      Notes/Comments
                    </Typography>
                    {/* <IconButton size="small">
                    <AddRoundedIcon />
                  </IconButton> */}
                  </Stack>
                  {loadingClient ? (
                    <RectangleSkeletons totalRows={1} height={30} />
                  ) : (
                    <Typography variant="body1" color="disabled.customs">
                      {clientData?.additional_info
                        ? clientData.additional_info
                        : "Not found"}
                    </Typography>
                  )}
                </Stack>
                <Divider />

                <Stack padding="24px 32px" gap="32px">
                  {/* <Stack direction="column" gap="16px">
                  <HeaderText
                    fontSize="18px"
                    variant="h5"
                    textAlign="-webkit-auto"
                    textContent="Assigned to"
                  />
                  <Box display="flex" gap="8px" alignItems="center">
                    <Avatar src={agent?.profile_picture_url} />
                    <HeaderText
                      textContent={agent?.name}
                      fontWeight="400"
                      fontSize="14px"
                      variant="body1"
                    />
                  </Box>
                </Stack> */}

                  {loadingClient ? (
                    <ClientOverviewSkeleton />
                  ) : (
                    <>
                      {" "}
                      <Stack>
                        <Typography
                          variant="subtitle2"
                          fontSize="18px"
                          color="disabled.customs"
                        >
                          Basic Information
                        </Typography>
                        <Stack pt={0.5} flexDirection="row" flexWrap="wrap">
                          <Stack py={1} sx={{ width: "50%" }}>
                            <Typography
                              variant="subtitle2"
                              fontSize="14px"
                              color="disabled.boxHeading"
                            >
                              Contact Number
                            </Typography>
                            <Typography
                              variant="body1"
                              color="disabled.customs"
                            >
                              {clientData?.contact_number || "-"}
                            </Typography>
                          </Stack>

                          <Stack py={1} sx={{ width: "50%" }}>
                            <Typography
                              variant="subtitle2"
                              fontSize="14px"
                              color="disabled.boxHeading"
                            >
                              Date of Birth
                            </Typography>
                            <Typography
                              variant="body1"
                              color="disabled.customs"
                            >
                              {clientData?.dob
                                ? dateFormat(clientData?.dob)
                                : "-"}
                            </Typography>
                          </Stack>

                          <Stack py={1} sx={{ width: "100%" }}>
                            <Typography
                              variant="subtitle2"
                              fontSize="14px"
                              color="disabled.boxHeading"
                            >
                              Address
                            </Typography>
                            <Typography
                              variant="body1"
                              color="disabled.customs"
                            >
                              {clientData?.address || "-"}
                            </Typography>
                          </Stack>

                          <Stack py={1} sx={{ width: "50%" }}>
                            <Typography
                              variant="subtitle2"
                              fontSize="14px"
                              color="disabled.boxHeading"
                            >
                              Country
                            </Typography>
                            <Typography
                              variant="body1"
                              color="disabled.customs"
                            >
                              {clientData?.country || "-"}
                            </Typography>
                          </Stack>

                          <Stack py={1} sx={{ width: "50%" }}>
                            <Typography
                              variant="subtitle2"
                              fontSize="14px"
                              color="disabled.boxHeading"
                            >
                              State
                            </Typography>
                            <Typography
                              variant="body1"
                              color="disabled.customs"
                            >
                              {clientData?.region || "-"}
                            </Typography>
                          </Stack>

                          <Stack py={1} sx={{ width: "50%" }}>
                            <Typography
                              variant="subtitle2"
                              fontSize="14px"
                              color="disabled.boxHeading"
                            >
                              City
                            </Typography>
                            <Typography
                              variant="body1"
                              color="disabled.customs"
                            >
                              {clientData.city || "-"}
                            </Typography>
                          </Stack>

                          <Stack py={1} sx={{ width: "50%" }}>
                            <Typography
                              variant="subtitle2"
                              fontSize="14px"
                              color="disabled.boxHeading"
                            >
                              Zip Code
                            </Typography>
                            <Typography
                              variant="body1"
                              color="disabled.customs"
                            >
                              {clientData?.zipcode || "-"}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Typography
                          variant="subtitle2"
                          fontSize="18px"
                          color="disabled.customs"
                        >
                          Work Details
                        </Typography>
                        <Stack pt={0.5} flexDirection="row" flexWrap="wrap">
                          <Stack py={1} sx={{ width: "50%" }}>
                            <Typography
                              variant="subtitle2"
                              fontSize="14px"
                              color="disabled.boxHeading"
                            >
                              Job Title
                            </Typography>
                            <Typography
                              variant="body1"
                              color="disabled.customs"
                            >
                              {clientData?.job_title || "-"}
                            </Typography>
                          </Stack>

                          <Stack py={1} sx={{ width: "50%" }}>
                            <Typography
                              variant="subtitle2"
                              fontSize="14px"
                              color="disabled.boxHeading"
                            >
                              Company Name
                            </Typography>
                            <Typography
                              variant="body1"
                              color="disabled.customs"
                            >
                              {clientData?.company_name || "-"}
                            </Typography>
                          </Stack>

                          <Stack py={1} sx={{ width: "50%" }}>
                            <Typography
                              variant="subtitle2"
                              fontSize="14px"
                              color="disabled.boxHeading"
                            >
                              Annual Income
                            </Typography>
                            <Typography
                              variant="body1"
                              color="disabled.customs"
                            >
                              {clientData?.annual_income
                                ? `$ ${clientData?.annual_income}`
                                : "-"}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </>
                  )}
                </Stack>
              </Box>
            </Stack>
          </Grid>

          {/* ALERT CLIENT PROFILE DELETE MODAL */}
          {openProfileModal && (
            <AlertDialog
              openModal={openProfileModal}
              closeModal={modalProfileCloseHandler}
              isLoading={loadingDeleteClient}
              data={modalContentData}
              formSubmitFunction={deleteClientProfileHandler}
              color="error"
            />
          )}

          {/* ALERT DISABLED APP ACCESS MODAL */}
          {openDisableModal && (
            <AlertDialog
              openModal={openDisableModal}
              closeModal={modalDisableAppCloseHandler}
              data={modalContentData}
              formSubmitFunction={disableAppAccessHandler}
              color="error"
            />
          )}

          {/* CLIENT OVERVIEW DOCUMENT SECTION */}
          <Grid item xs={12} md={8} pl={3} width="640px">
            {/* RELATED CASE SECTION */}
            <Stack
              direction="column"
              boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
              p={3}
              gap="16px"
              borderRadius={2}
              sx={{ cursor: "pointer" }}
            >
              {loadingClient ? (
                <RectangleSkeletons totalRows={1} width={120} height={20} />
              ) : (
                <Typography variant="h5">
                  {clientData?.cases?.length > 1
                    ? "Related Cases"
                    : "Related Case"}
                </Typography>
              )}
              {loadingClient ? (
                <RectangleSkeletons totalRows={2} height={72} />
              ) : clientData?.cases?.length > 0 ? (
                <Stack gap="16px">
                  {clientData?.cases?.map((caseItem, i) => (
                    <Stack
                      key={i}
                      gap="4px"
                      borderRadius="8px"
                      bgcolor="#F4F6F8"
                      padding="12px"
                      onClick={() => navigateToCaseHandler(caseItem?.id)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="boxHeading" fontWeight={400}>
                          {caseItem?.title ? caseItem?.title : "Not found"}
                        </Typography>
                        <SendClientIcon />
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={caseItem?.status}
                          size="small"
                          sx={{
                            color: "#2196F3",
                            background: "rgba(33, 150, 243, 0.15)",
                          }}
                        />
                        <Typography
                          color="secondary"
                          variant="mainLabel"
                          fontWeight={400}
                        >
                          Created on -{" "}
                          {dayjs(caseItem?.created_at).format(
                            "DD MMM - hh:mm a"
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              ) : (
                <Box
                  bgcolor="#F4F6F8"
                  padding="12px"
                  height="76px"
                  borderRadius="8px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="body1" color="secondary">
                    No related case found{" "}
                  </Typography>
                </Box>
              )}
            </Stack>

            {drawerOpen && (
              <OverviewDrawer
                anchor={"right"}
                open={drawerOpen}
                setOpen={setDrawerOpen}
                onClose={toggleDrawer}
                agent={agent}
                client={clientData}
                selectedDocument={selectedDocument}
                // getClientsData={getClientInfo}
              />
            )}

            {/* CREATED NEW FOLDER MESSAGE */}
            {openCreatedFolderMessage && (
              <AlertMessage
                message="Folder created succesfully!"
                severity="success"
                open={openCreatedFolderMessage}
                handleClose={handleClose}
              />
            )}

            {/* DOCUMENT SECTION */}
            <Stack
              p={3}
              pb={12}
              boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
              borderRadius={2}
              mt={4}
              gap="8px"
              sx={{
                overflowY: "auto",
                height: "671px",
                scrollbarWidth: "none", // For Firefox
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {/* {loadingGetClientCasesDocs ? (
                  <CircularProgressLoading />
                ) : (
                  <>
                    {!loadingGetClientCasesDocs && documents.length === 0 ? (
                      // &&!clientData?.document_requests?.length
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                      >
                        <Avatar
                          src={EmptyBox}
                          alt="coming soon"
                          sx={{
                            width: "128px",
                            height: "128px",
                            paddingBottom: "24px",
                          }}
                        />
                        <Typography variant="body1" color="secondary" pb={2}>
                          All documents will show up here. you can also send
                          document request to clients
                        </Typography>
                        <Button
                          startIcon={<AddRoundedIcon />}
                          title="Request New"
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            toggleDrawer("right", true);
                            setDrawerOpen(true);

                            if (agentUser?.agent && agencyDetail?.agency) {
                              segmentTrack({
                                event: "new_document_clicked",
                                agent: agentUser?.agent,
                                agency: agencyDetail?.agency,
                              });
                            }
                          }}
                        />
                      </Stack>
                    ) : ( */}
              <>
                <Stack
                  justifyContent="space-between"
                  alignItems="center"
                  direction="row"
                  pb={3}
                >
                  <Typography variant="h5">Documents</Typography>
                  {documents?.length > 0 && (
                    <Button
                      startIcon={<AddRoundedIcon />}
                      title="Request New"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        toggleDrawer("right", true);
                        setDrawerOpen(true);
                      }}
                    />
                  )}
                </Stack>

                {/* SEARCH, GRID VIEW AND LIST VIEW LAYOUT ROW */}
                <Stack
                  direction="row"
                  // justifyContent="space-between"
                  // justifyContent="flex-end"
                  gap="16px"
                  alignItems="center"
                  pb={3}
                >
                  {/* <TextField
                      size="small"
                      placeholder="Coming soon"
                      label="Coming soon"
                      disabled={true}
                      // InputProps={{ readOnly: true }}
                    /> */}

                  {/* <TextField
                      sx={{
                        // minWidth: "180px",
                        width: "100%",
                      }}
                      variant="outlined"
                      value={selectedCase}
                      onChange={(e) => setSelectedCase(e.target.value)}
                      select
                      label="Related to"
                      size="small"
                    >
                      <MenuItem value="all-documents">All documents</MenuItem>
                      {clientData?.cases?.map((caseItem) => (
                        <MenuItem key={caseItem?.id} value={caseItem?.id}>
                          {caseItem?.title}
                        </MenuItem>
                      ))}
                     
                    </TextField> */}

                  <ToggleButtonGroup
                    sx={{ height: "40px" }}
                    exclusive
                    value={documentView}
                    onChange={documentViewHandler}
                    aria-label="view"
                  >
                    <ToggleButton value="grid" aria-label="grid">
                      <GridViewIcon />
                    </ToggleButton>
                    <ToggleButton value="list" aria-label="list">
                      <ListViewIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>

                  {!innerFolderView && (
                    <Box
                      borderRadius="4px"
                      component="button"
                      border="1px solid rgba(0, 0, 0, 0.12)"
                      padding="8px"
                      height="40px"
                      bgcolor="#fff"
                      sx={{ cursor: "pointer" }}
                      onClick={createNewFolderHandler}
                    >
                      <AddFolderIcon />
                    </Box>
                  )}
                </Stack>

                {/* MOVE FILES AND DELETED FILES WHEN SELECTED CHECKBOX */}
                {selectedCheckedDoc?.length ? (
                  <StyledContainerBox>
                    <Checkbox
                      onChange={changeParentCheckedBoxHandler}
                      checked={parentCheckedState === "all"}
                      indeterminate={parentCheckedState === "some"}
                      inputProps={{ "aria-label": "move files" }}
                    />
                    <Box flex="1">
                      {selectedCheckedDoc?.length > 1 ? (
                        <Typography
                          variant="subtitle1"
                          fontSize="16px"
                          fontWeight="400"
                        >
                          {selectedCheckedDoc?.length} Items Selected
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          fontSize="16px"
                          fontWeight="400"
                        >
                          "{selectedCheckedDoc[0]?.name}" Selected
                        </Typography>
                      )}
                    </Box>
                    {!isFolderIncluded &&
                      (selectedCheckedDoc?.length > 1 ? (
                        <Button
                          variant="text"
                          onClick={moveFileDialogHandler}
                          title="Move files"
                          size="small"
                        />
                      ) : (
                        <Button
                          variant="text"
                          onClick={moveFileDialogHandler}
                          title="Move file"
                          size="small"
                        />
                      ))}
                    <IconButton onClick={multipleDocumentsDeleteHandler}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledContainerBox>
                ) : (
                  <></>
                )}

                {/* MOVE INNER CHECKED FILES AND DELETED FILES WHEN SELECTED INNER CHECKBOX */}
                {innerSelectedCheckedDoc?.length ? (
                  <StyledContainerBox>
                    <Checkbox
                      onChange={changeParentCheckedBoxHandler}
                      checked={innerParentCheckedState === "all"}
                      indeterminate={innerParentCheckedState === "some"}
                      inputProps={{ "aria-label": "move files" }}
                    />
                    <Box flex="1">
                      {innerSelectedCheckedDoc?.length > 1 ? (
                        <Typography
                          variant="subtitle1"
                          fontSize="16px"
                          fontWeight="400"
                        >
                          {innerSelectedCheckedDoc?.length} Items Selected
                        </Typography>
                      ) : (
                        <Typography
                          variant="subtitle1"
                          fontSize="16px"
                          fontWeight="400"
                        >
                          "{innerSelectedCheckedDoc[0]?.file_name}" Selected
                        </Typography>
                      )}
                    </Box>

                    <Button
                      onClick={moveFilesInFolderDialogHandler}
                      variant="text"
                      title={
                        innerSelectedCheckedDoc?.length <= 1
                          ? "Move file"
                          : "Move files"
                      }
                      size="small"
                    />

                    <IconButton onClick={multipleFilesDeleteHandler}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledContainerBox>
                ) : (
                  <></>
                )}

                {/* REQUEST DOCUMENTS BOX */}
                {innerFolderView ? (
                  <InnerFolderView
                    documentView={documentView}
                    selectedDoc={selectedDocument}
                    fromClientPage={true}
                    documentRequestTitle={documentRequestTitleHandler}
                    loading={loadingFolderViewContent}
                    error={errorFolderViewContent}
                    setInnerFolderView={setInnerFolderView}
                    innerFolderContents={innerFolderContents}
                    setInnerFolderContents={setInnerFolderContents}
                    fsLightBoxViewHandler={fsLightBoxViewHandler}
                    handleDocumentClick={handleDocumentClick}
                    setMenuFileIndex={setMenuFileIndex}
                    setMenuFileDownloadURLs={setMenuFileDownloadURLs}
                    setSelectedDocument={setSelectedDocument}
                    lightboxSources={lightboxSources}
                    toggler={toggler}
                    openDocumentMenu={openDocumentMenu}
                    handleDocumentClose={handleDocumentClose}
                    anchorDocumentEl={anchorDocumentEl}
                    addMoreDocumentsHandler={addMoreDocumentsHandler}
                    moveFileDialogHandler={moveFileDialogHandler}
                    moveToNewFolderFunction={moveToNewFolderHandler}
                    renameDocumentHandler={renameDocumentHandler}
                    handleDownloadClick={handleDownloadClick}
                    documentDeleteHandler={documentDeleteHandler}
                    innerSelectedFile={innerSelectedFile}
                    setInnerSelectedFile={setInnerSelectedFile}
                    handleBoxClick={handleBoxClick}
                    changeCheckBoxHandler={changeCheckBoxHandler}
                    changeInnerCheckBoxHandler={changeInnerCheckBoxHandler}
                    innerSelectedCheckedDoc={innerSelectedCheckedDoc}
                    selectedCheckedDoc={selectedCheckedDoc}
                    downloadImgAsPdfHandler={downloadImgAsPdfHandler}
                  />
                ) : (
                  <>
                    {" "}
                    {/* GRID VIEW LAYOUT */}
                    {documentView === "grid" && (
                      <GridViewLayout
                        documentsRequest={clientData?.document_requests}
                        documentRequestTitle={documentRequestTitleHandler}
                        fromClientPage={true}
                        loading={loadingGetClientCasesDocs}
                        docsLoading={loadingGetClientCasesDocs}
                        documents={documents}
                        toggleDrawer={toggleDrawer}
                        setDrawerOpen={setDrawerOpen}
                        selectedDocument={selectedDocument}
                        setSelectedDocument={setSelectedDocument}
                        handleTitleClick={handleTitleClick}
                        fsLightBoxViewHandler={fsLightBoxViewHandler}
                        openDocumentMenu={openDocumentMenu}
                        openRequestDocMenu={openRequestDocMenu}
                        handleDocumentClick={handleDocumentClick}
                        handleRequestDocClick={handleRequestDocClick}
                        handleDownloadClick={handleDownloadClick}
                        setMenuFileIndex={setMenuFileIndex}
                        setMenuFileDownloadURLs={setMenuFileDownloadURLs}
                        toggler={toggler}
                        lightboxSources={lightboxSources}
                        anchorDocumentEl={anchorDocumentEl}
                        anchorRequestDocEl={anchorRequestDocEl}
                        handleDocumentClose={handleDocumentClose}
                        documentDeleteHandler={documentDeleteHandler}
                        renameDocumentHandler={renameDocumentHandler}
                        moveToNewFolderFunction={moveToNewFolderHandler}
                        moveFileDialogHandler={moveFileDialogHandler}
                        selectedCheckedDoc={selectedCheckedDoc}
                        addMoreDocumentsHandler={addMoreDocumentsHandler}
                        sendRequestDocReminderHandler={
                          sendRequestDocReminderHandler
                        }
                        cancelDocRequestHandler={cancelDocRequestHandler}
                        changeCheckBoxHandler={changeCheckBoxHandler}
                        handleBoxClick={handleBoxClick}
                        downloadImgAsPdfHandler={downloadImgAsPdfHandler}
                      />
                    )}
                    {/* LIST VIEW LAYOUT */}
                    {documentView === "list" && (
                      <ListViewLayout
                        documentsRequest={clientData?.document_requests}
                        documentRequestTitle={documentRequestTitleHandler}
                        fromClientPage={true}
                        loading={loadingGetClientCasesDocs}
                        docsLoading={loadingGetClientCasesDocs}
                        documents={documents}
                        toggleDrawer={toggleDrawer}
                        setDrawerOpen={setDrawerOpen}
                        selectedDocument={selectedDocument}
                        setSelectedDocument={setSelectedDocument}
                        handleTitleClick={handleTitleClick}
                        fsLightBoxViewHandler={fsLightBoxViewHandler}
                        openDocumentMenu={openDocumentMenu}
                        openRequestDocMenu={openRequestDocMenu}
                        handleDocumentClick={handleDocumentClick}
                        handleRequestDocClick={handleRequestDocClick}
                        handleDownloadClick={handleDownloadClick}
                        setMenuFileIndex={setMenuFileIndex}
                        setMenuFileDownloadURLs={setMenuFileDownloadURLs}
                        toggler={toggler}
                        lightboxSources={lightboxSources}
                        anchorDocumentEl={anchorDocumentEl}
                        anchorRequestDocEl={anchorRequestDocEl}
                        handleDocumentClose={handleDocumentClose}
                        documentDeleteHandler={documentDeleteHandler}
                        renameDocumentHandler={renameDocumentHandler}
                        moveToNewFolderFunction={moveToNewFolderHandler}
                        moveFileDialogHandler={moveFileDialogHandler}
                        selectedCheckedDoc={selectedCheckedDoc}
                        addMoreDocumentsHandler={addMoreDocumentsHandler}
                        changeCheckBoxHandler={changeCheckBoxHandler}
                        handleBoxClick={handleBoxClick}
                        sendRequestDocReminderHandler={
                          sendRequestDocReminderHandler
                        }
                        cancelDocRequestHandler={cancelDocRequestHandler}
                        downloadImgAsPdfHandler={downloadImgAsPdfHandler}
                      />
                    )}{" "}
                  </>
                )}

                {/* <FsLightbox toggler={toggler} sources={lightboxSources} />
                  <Menu
                    id="document-menu"
                    anchorEl={anchorDocumentEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={openDocumentMenu}
                    onClose={handleDocumentClose}
                    MenuListProps={{
                      "aria-labelledby": "document-button",
                    }}
                  >
                    <MenuItem onClick={handleDownloadClick}>Download</MenuItem>
                    <MenuItem
                      onClick={documentDeleteHandler}
                      sx={{ color: "#FF2D2D" }}
                    >
                      Delete
                    </MenuItem>
                  </Menu> */}
                {/* {documents?.length ? (
                          documents?.map((doc, i) => (
                            <Stack
                              key={i}
                              p={1.5}
                              direction="row"
                              alignItems="flex-end"
                              justifyContent="space-between"
                              sx={{
                                background: "#F4F6F8",
                                borderRadius: "8px",
                              }}
                            >
                              <Stack direction="row" alignItems="center">
                                <Box
                                  width="48px"
                                  height="48px"
                                  bgcolor="#fff"
                                  borderRadius="8px"
                                >
                                  <DocCircle
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      padding: " 5px",
                                    }}
                                  />
                                </Box>

                                <Stack pl={1.5}>
                                  <a>
                                    <Typography
                                      variant="boxHeading"
                                      color="disabled.customs"
                                      sx={{
                                        // cursor: "pointer",
                                        textDecoration: "none",
                                        // "&:hover": {
                                        //   textDecoration: "underline",
                                        // },
                                      }}
                                    >
                                      {doc?.document_title}
                                    </Typography>
                                  </a>
                                  <Typography
                                    pb={1}
                                    variant="caption"
                                    color="#ED6C02"
                                  >
                                    Waiting for client to upload the files.
                                  </Typography>
                                </Stack>
                              </Stack>
                              <Box display="flex" alignItems="center">
                                <Chip
                                  label={
                                    doc?.document_type === "client_level" //doc?.document_type
                                      ? "Personal"
                                      : clientData?.cases?.filter(
                                          (case_obj) =>
                                            case_obj?.id === doc?.case_id
                                        )[0]?.title
                                  }
                                  size="small"
                                  sx={{
                                    marginLeft: 0,
                                    padding: "4px",
                                    color: `${
                                      doc?.document_type === "client_level"
                                        ? "#2196F3"
                                        : "#3DC583"
                                    }`,
                                    background: `${
                                      doc?.document_type === "client_level"
                                        ? "rgba(33, 150, 243, 0.15)"
                                        : "rgba(61, 197, 131, 0.15)"
                                    }`,
                                  }}
                                />
                               <IconButton
                                  id="document-btn"
                                  aria-controls={
                                    openDocumentMenu
                                      ? "document-menu"
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={
                                    openDocumentMenu ? "true" : undefined
                                  }
                                  // onClick={(event) => {
                                  //   handleDocumentClick(event);
                                  //   setMenuFileIndex(i);
                                  //   setMenuFileDownloadURLs(doc.files);
                                  // }}
                                >
                                  <MoreVertIcon />
                                </IconButton> 
                              </Box>
                            </Stack>
                          ))
                        ) : (
                          <></>
                        )} */}

                {/* {filteredDocuments?.length > 0 ? (
                          <Stack gap={1}>
                            {filteredDocuments?.map((doc, i) => {
                              return (
                                <Stack
                                  component="a"
                                  onClick={() => handleTitleClick(doc.files)}
                                  key={i}
                                  p={1.5}
                                  direction="row"
                                  alignItems="flex-end"
                                  justifyContent="space-between"
                                  sx={{
                                    background: "#F4F6F8",
                                    borderRadius: "8px",
                                  }}
                                >
                                  <Stack direction="row" alignItems="center">
                                    <Box
                                      width="48px"
                                      height="48px"
                                      bgcolor="#fff"
                                      borderRadius="8px"
                                    >
                                      {doc.files.length > 1 ? (
                                        <FolderIcon className={classes.icon} />
                                      ) : (
                                        <img
                                          src={`${doc.icon}`}
                                          alt={doc.title}
                                          className={classes.icon}
                                          width="100%"
                                          height="100%"
                                        />
                                      )}
                                    </Box>

                                    <Stack pl={1.5}>
                                      <Typography
                                        variant="boxHeading"
                                        color="disabled.customs"
                                        sx={{
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                      >
                                        {doc.title}
                                      </Typography>

                                      <Typography
                                        pb={1}
                                        variant="tableSemiHeading"
                                        color="disabled.boxHeading"
                                      >
                                        {doc.files.length}{" "}
                                        {doc.files.length > 1
                                          ? "files"
                                          : "file"}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                  <Box>
                                    <Chip
                                      label={
                                        doc?.document_type === "client_level"
                                          ? "Personal"
                                          : clientData?.cases?.filter(
                                              (case_obj) =>
                                                case_obj?.id === doc?.case_id
                                            )[0]?.title
                                      }
                                      size="small"
                                      sx={{
                                        marginLeft: 0,
                                        padding: "4px",
                                        color: `${
                                          doc?.document_type === "client_level"
                                            ? "#2196F3"
                                            : "#3DC583"
                                        }`,
                                        background: `${
                                          doc?.document_type === "client_level"
                                            ? "rgba(33, 150, 243, 0.15)"
                                            : "rgba(61, 197, 131, 0.15)"
                                        }`,
                                      }}
                                    />

                                    <IconButton
                                      id="document-btn"
                                      aria-controls={
                                        openDocumentMenu
                                          ? "document-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        openDocumentMenu ? "true" : undefined
                                      }
                                      onClick={(event) => {
                                        handleDocumentClick(event);
                                        setMenuFileIndex(i);
                                        setMenuFileDownloadURLs(doc.files);
                                        setSelectedDocument(doc);
                                      }}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Box>
                                </Stack>
                              );
                            })}
                          </Stack>
                        ) : (
                          !clientData?.document_requests?.length && (
                            <Box
                              height="100%"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography variant="h4" fontSize="24px">
                                No documents found!
                              </Typography>
                            </Box>
                          )
                        )} */}
              </>
            </Stack>
          </Grid>
        </Grid>
      </>
    </>
  );
}
