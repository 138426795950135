import React, { useEffect, useState } from "react";
import BgBox from "../../../common/BgBox";
import HeaderText from "../../../common/HeaderText";
import CardUI from "../../../common/Card";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import Textfield from "../../../common/Textfield";
import PasswordConstraint from "../../../common/PasswordConstraint";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../../common/AlertMessage";
import { addpasswordFunc } from "../../../actions/Auth";
import MccLogo from "../../../components/MccLogo";
import { LoadingButton } from "@mui/lab";
import Cookies from "js-cookie";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.?])[A-Za-z\d!@#$%^&*.?]{8,}$/;

const CreatePassword = () => {
  const [fieldVals, setFieldVals] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validPassword, setValidPassword] = useState(true);

  const agentId = Cookies.get("agent_id_vita");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const addPassword = useSelector((state) => state.addPassword);
  const userLogin = useSelector((state) => state.userLogin);

  const agentData = state && state?.agent_data;
  const { loading, error, res } = addPassword;

  // console.log("USER INFO", userLogin?.userInfo);

  useEffect(() => {
    if (state === null) {
      navigate(-1);
    }
    Cookies.set("route", "/create-password");
  }, []);

  useEffect(() => {
    if (!error && !errorMsg && res?.data?.ok) {
      // navigate("/create-workspace", {
      //   state: { createWorkspace: true, agentData: agentData },
      // });
      navigate("/terms-of-service", {
        state: { createWorkspace: true, agentData: agentData },
      });
    }
  }, [res]);

  // console.log("AGENT DATA", state?.agent_data);

  const changePasswordHandler = (event) => {
    const { name, value } = event?.target;
    setFieldVals({ ...fieldVals, [name]: value });
    setValidPassword(passwordRegex.test(event.target.value));
  };

  const changeConfirmPasswordHandler = (event) => {
    const { name, value } = event?.target;
    setFieldVals({ ...fieldVals, [name]: value });
  };

  const submitPasswordHandler = (e) => {
    e.preventDefault();

    // navigate("/terms-of-service", {
    //   state: { createWorkspace: true, agentData: agentData },
    // });

    if (fieldVals.newPassword.length < 8) {
      setErrorMsg("Password should be at least 8 characters");
      return;
    }

    if (fieldVals.newPassword !== fieldVals.confirmNewPassword) {
      setErrorMsg("Password should match");
      return;
    }

    dispatch(
      addpasswordFunc(
        agentData ? agentData?.agent?.id?.toString() : agentId,
        fieldVals?.newPassword
      )
    );
    setErrorMsg("");
  };

  if (state === null) {
    return;
  }

  return (
    <>
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {!loading && errorMsg && (
        <AlertMessage
          message={errorMsg}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      <BgBox>
        <CardUI maxWidth={570} paddingTop="86px">
          {/* MCC LOGO */}
          <MccLogo />

          <Grid container component="main">
            <Box
              component="form"
              onSubmit={submitPasswordHandler}
              // item
              xs={12}
              display="flex"
              gap="40px"
              flexDirection="column"
              width="100%"
            >
              <Box>
                <Typography
                  variant="body1"
                  className="d-flex"
                  fontSize={16}
                  mb="16px"
                  color="#566875"
                >
                  Let's get you started
                </Typography>
                <HeaderText
                  variant="h3"
                  fontSize={48}
                  textContent="Set up your password"
                  lineHeight="116.7%"
                  textAlign="center"
                />
                <Typography
                  variant="body1"
                  className="d-flex"
                  fontSize={16}
                  mb="16px"
                  color="#566875"
                >
                  Password requirements: Minimum 8 characters, 1 lowercase & 1
                  uppercase letter, 1 number and special characters.{" "}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="column" gap="16px">
                <Box>
                  <Textfield
                    width="100%"
                    variant="outlined"
                    helperTextContent={<PasswordConstraint />}
                    errorContent={!validPassword && "Enter valid password"}
                    helperText={
                      !validPassword &&
                      "Password must be a combination of uppercase and lowercase characters, numbers and special characters(!, @, $, %, &, *, ?)"
                    }
                    name="newPassword"
                    value={fieldVals?.newPassword}
                    onChange={(e) => changePasswordHandler(e)}
                    placeholder="New Password"
                    label="New Password"
                    type={showPassword ? "text" : "password"}
                    // onKeyDown={(e) => e.key === 'Enter' && onSignIn()}
                  />
                </Box>
                <Textfield
                  autoComplete
                  placeholder="Confirm New Password"
                  label="Confirm New Password"
                  helperText={errorMsg && "Password does not match"}
                  errorContent={errorMsg && "Password does not match"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={fieldVals?.confirmNewPassword}
                  name="confirmNewPassword"
                  onChange={(e) => changeConfirmPasswordHandler(e)}
                  type={showPassword ? "text" : "password"}
                  width="100%"
                  // onKeyDown={(e) => e.key === 'Enter' && onSignIn()}
                />
              </Box>

              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                sx={{ padding: "8px 22px", fontSize: 15 }}
                disabled={!validPassword}
              >
                Create Password
              </LoadingButton>

              {/* <Button
      className="w-100"
      fontSize="15px"
      color="primary"
      
      title="Sign In"
      variant="contained"
      width="100%"
      height="48px"
      // disabled={!fieldVals?.email || !fieldVals?.password} // Disabling it off on purpose
      // onClick={() => onSignIn()}
      // loading={signInLoading}
      loadingColor="primary"
    /> */}
            </Box>
          </Grid>
        </CardUI>
      </BgBox>
    </>
  );
};

export default CreatePassword;
