import React, { useEffect, useState } from "react";
import BgBox from "../../../common/BgBox";
import HeaderText from "../../../common/HeaderText";
import CardUI from "../../../common/Card";
import {
  Box,
  Button,
  Grid,
  Typography,
  Link,
  Avatar,
  Stack,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Link as LinkTo, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MccLogo from "../../../components/MccLogo";
import Textfield from "../../../common/Textfield";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { stringAvatar, validateEmail } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import {
  createWorkspaceFunc,
  getAgentProfile,
  inviteAgentFunc,
  resetAgentInvite,
  resetCreateWorkSpace,
} from "../../../actions/Agency";
import AlertMessage from "../../../common/AlertMessage";
import { LoadingButton } from "@mui/lab";
import ExistingPractices from "../../../components/ExistingPracticesUI";
import axios from "axios";
import { BASE_URL } from "../../../actions";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*.?])[A-Za-z\d!@#$%^&*.?]{8,}$/;

const JoinWorkspace = () => {
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const organizationId = Cookies.get("org_id_vita");

  const [showPractiseBox, setShowPractiseBox] = useState(true);
  const [addingMorePractice, setAddingMorePractice] = useState(false);
  const [practiceName, setPracticeName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [open, setOpen] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setOpenSuccessMessage(false);
  };

  // const {
  //   state: { agent_data },
  // } = useLocation();
  const { state } = useLocation();

  const agentData = state && state?.agent_data;
  const existingAgencies = state && state?.existing_agencies;

  const createWorkspace = useSelector((state) => state.createWorkspace);
  const {
    loading: loadingAgency,
    error: errorAgency,
    workspace,
  } = createWorkspace;

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentProfile,
    error: errorAgentProfile,
    agentUser,
  } = agentProfile;

  const inviteAgent = useSelector((state) => state.inviteAgent);
  const {
    loading: loadingAgent,
    error: errorAgent,
    invitedAgent,
  } = inviteAgent;

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;
    setEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);
    if (emailInputValue && !emailIsValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  useEffect(() => {
    if (state === null) {
      navigate(-1);
      return;
    }
    Cookies.set("route", "/join-workspace");
  }, []);

  useEffect(() => {
    dispatch(getAgentProfile());
  }, []);

  useEffect(() => {
    if (existingAgencies && existingAgencies?.length) {
      setAgencies(existingAgencies);
    }
  }, [state]);

  const joinWorkspaceHandler = async () => {
    dispatch(resetAgentInvite());
    const data = await secretKeyCallFunction();

    if (data?.ok) {
      navigate("/billing-details", {
        state: { createAgentName: true, client_secret: data?.client_secret },
      });
    }

    // navigate("/password-created", { state: { createAgentName: true, secret_key: } });
  };

  const secretKeyCallFunction = async () => {
    try {
      const token = Cookies.get("token");
      // const agencyId = Cookies.get("agency_id_vita");
      if (token) {
        // userInfo = JSON.parse(userInfo);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const customerObj = {
          organization_id: organizationId,
          email: agentUser?.agent?.email,
          name: agentUser?.agent?.name,
        };

        const response = await axios.post(
          `${BASE_URL}/subscription/create-customer`,
          customerObj,
          config
        );
        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        return response?.data;
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const showMorePractiseHandler = () => {
    setShowPractiseBox(true);
    setAddingMorePractice(true);
  };

  const closePractiseBoxHandler = () => {
    setShowPractiseBox(false);
    setAddingMorePractice(false);
    setEmail("");
    setPracticeName("");
    setOwnerName("");
  };

  const addPracticeHandler = () => {
    const agencyObj = {
      agency: {
        name: practiceName,
        primary_email: email,
      },
      organization_id: organizationId,
    };

    dispatch(createWorkspaceFunc(agencyObj));
    setEmailErrorMsg("");
  };

  useEffect(() => {
    if (!loadingAgency && !workspace?.ok) {
      setEmailErrorMsg(workspace?.message);
      setOpen(true);
    }
  }, [workspace]);

  useEffect(() => {
    if (!loadingAgent && !invitedAgent?.ok) {
      setEmailErrorMsg(invitedAgent?.message);
      setOpen(true);
      // setShowPractiseBox(false);
      setEmail("");
      setPracticeName("");
      setOwnerName("");
    }
  }, [invitedAgent]);

  useEffect(() => {
    if (workspace?.data?.ok) {
      setAgencies((prevState) => [...prevState, workspace?.data?.agency]);
      const agentObj = {
        agency_id: workspace?.data?.agency?.id,
        send_credentials: true,
        agent_obj: {
          first_name: ownerName,
          email: email,
          role: "Owner",
          permissions: {
            access_patient_details: {
              create: true,
              read: true,
              edit: true,
              delete: true,
            },
            // can_chat_client: true,
            can_access_billing: false,
          },
        },
      };
      dispatch(inviteAgentFunc(agentObj));
      dispatch(resetCreateWorkSpace());
    }
  }, [workspace]);

  useEffect(() => {
    if (invitedAgent?.ok) {
      setOpenSuccessMessage(true);
      setSuccessMessage(invitedAgent?.message);
      setEmailErrorMsg("");
      setShowPractiseBox(false);
      setEmail("");
      setPracticeName("");
      setOwnerName("");
    }
  }, [invitedAgent]);

  if (state === null) {
    return;
  }

  return (
    <>
      {!loadingAgency && emailErrorMsg && (
        <AlertMessage
          message={emailErrorMsg}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {openSuccessMessage && (
        <AlertMessage
          message={successMessage}
          severity="success"
          open={openSuccessMessage}
          handleClose={handleClose}
        />
      )}

      <BgBox>
        <CardUI padding="40px" paddingTop="40px">
          <Grid container component="main">
            <Box
              component="form"
              xs={12}
              display="flex"
              gap="32px"
              flexDirection="column"
              width="100%"
            >
              <Stack>
                <Stack gap="16px">
                  <HeaderText
                    variant="h3"
                    fontSize={48}
                    textContent={
                      showPractiseBox ? "Add Practice" : "Your Practices"
                    }
                  />
                  <HeaderText
                    variant="body1"
                    fontSize={16}
                    fontWeight={400}
                    color="#566875"
                    textContent="As a care management group you can setup & manage multiple practices"
                    // lineHeight="24px"
                  />
                </Stack>

                {/* EXISTING PRACTICE COMPONENT */}
                {!showPractiseBox && (
                  <ExistingPractices
                    existingAgencies={existingAgencies}
                    agencies={agencies}
                    emailErrorMsg={emailErrorMsg}
                    showPractiseBox={showPractiseBox}
                    showMorePractiseHandler={showMorePractiseHandler}
                    addingMorePractice={addingMorePractice}
                  />
                )}
              </Stack>

              {showPractiseBox && (
                <Stack gap="16px">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" fontWeight={500}>
                      Add Practice
                    </Typography>
                    {addingMorePractice && (
                      <Button variant="text" onClick={closePractiseBoxHandler}>
                        Cancel
                      </Button>
                    )}
                  </Box>
                  <Textfield
                    variant="outlined"
                    name="practice_name"
                    label="Practice name"
                    value={practiceName}
                    onChange={(e) => setPracticeName(e.target.value)}
                    placeholder="Health care experts"
                    width="100%"
                  />
                  <Textfield
                    name="owner_email"
                    label="Owner's Email"
                    placeholder="example@gmail.com"
                    variant="outlined"
                    value={email}
                    onChange={emailChangeHandler}
                    errorContent={emailError && "Enter valid email"}
                    helperText={emailError && "Enter valid email"}
                  />
                  <Textfield
                    variant="outlined"
                    name="owner_name"
                    label="Owner's Name"
                    value={ownerName}
                    onChange={(e) => setOwnerName(e.target.value)}
                    placeholder="John Doe"
                    width="100%"
                  />

                  <Typography variant="body2" color="secondary" fontSize="15px">
                    Password will be automatically generated and sent to your
                    email address.
                  </Typography>
                </Stack>
              )}

              <Box display="flex" flexDirection="column" gap="16px">
                <LoadingButton
                  fullWidth
                  loading={
                    showPractiseBox ? loadingAgency || loadingAgent : false
                  }
                  variant="contained"
                  sx={{ padding: "8px 22px", fontSize: 15 }}
                  // disabled={
                  //   showPractiseBox
                  //     ? !practiceName || !email || !ownerName
                  //     : !agencies?.length
                  // }
                  onClick={
                    showPractiseBox ? addPracticeHandler : joinWorkspaceHandler
                  }
                >
                  {showPractiseBox ? "Add Practice" : "Continue"}
                </LoadingButton>
              </Box>

              {addingMorePractice && showPractiseBox && (
                <ExistingPractices
                  existingAgencies={existingAgencies}
                  agencies={agencies}
                  emailErrorMsg={emailErrorMsg}
                  showPractiseBox={showPractiseBox}
                  showMorePractiseHandler={showMorePractiseHandler}
                  addingMorePractice={addingMorePractice}
                />
              )}
            </Box>
          </Grid>
        </CardUI>
      </BgBox>
    </>
  );
};

export default JoinWorkspace;
