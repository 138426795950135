import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import RectangleSkeletons from "../../RectangleSkeletons";

const ReshareCredsModalContent = ({ practiceName }) => {
  const getAgencyEmail = useSelector((state) => state.getAgencyEmail);
  const {
    loading: loadingEmail,
    error: errorEmail,
    agencyEmail,
  } = getAgencyEmail;

  return (
    <Stack gap="24px">
      <Typography variant="body1">
        We will resend the new credentials to the registered email address
        associated with this practice.
      </Typography>
      <Stack>
        <Typography variant="body1" fontWeight={500}>
          Practice Name: {practiceName}{" "}
        </Typography>
        <Box display="flex" alignItems="center" gap="6px">
          <Typography variant="body1" fontWeight={500}>
            Email:{" "}
          </Typography>
          <Typography component="span" variant="body1" fontWeight={500}>
            {loadingEmail ? (
              <RectangleSkeletons height="12px" totalRows={1} width="200px" />
            ) : (
              agencyEmail?.email
            )}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ReshareCredsModalContent;
