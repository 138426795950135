import React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LoadingButton } from "@mui/lab";

const ImportButtonActions = ({ nextStepHandler, disabled, loading }) => {
  const navigate = useNavigate();

  const navigateToPatientListingsHandler = () => {
    navigate("/patients?tab=all");
  };

  return (
    <Box display="flex" alignItems="center" gap="10px">
      <Button variant="text" onClick={navigateToPatientListingsHandler}>
        Cancel
      </Button>
      <LoadingButton
        loading={loading}
        disabled={disabled}
        variant="contained"
        endIcon={<ChevronRightIcon />}
        onClick={nextStepHandler}
      >
        Next
      </LoadingButton>
    </Box>
  );
};

export default ImportButtonActions;
