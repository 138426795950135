import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CustomSelectFilter from "../../../common/CustomSelectFilter";
import { statusOptions, validateEmail } from "../../../utils/helpers";
import AddIcon from "@mui/icons-material/Add";
import { GridActionsCellItem } from "@mui/x-data-grid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";
import { EditIcon, EditPracticeIcon } from "../../../assets/constants";
import PractiseBoxCard from "../../PractiseBoxCard";
import styled from "@emotion/styled";
import AlertDialog from "../../AlertDialog";
import AddPractiseContent from "../../AddPractiseContent";
import { useDispatch } from "react-redux";
import {
  createWorkspaceFunc,
  getAgencyEmailFunc,
  inviteAgentFunc,
  resetAgentInvite,
  resetCreateWorkSpace,
  resetReshareAgencyCreds,
  reshareAgencyCredsFunc,
} from "../../../actions/Agency";
import CaseTableSkeleton from "../../CaseTableSkeleton";
import Cookies from "js-cookie";
import AlertMessage from "../../../common/AlertMessage";
import { getOrganizationFunc } from "../../../actions/OrganizationActions";
import EditPracticeDrawer from "../EditPracticeDrawer";
import ReshareIcon from "../../../assets/images/reshare-icon.png";
import ReshareCredsModalContent from "../ReshareCredsModalContent";
import AvatarGroups from "../../AvatarGroups";

const ITEM_HEIGHT = 54;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

const StyledTableCell = styled(TableCell)(({}) => {
  return {
    padding: "8px 16px",
  };
});

const PracticesTab = () => {
  const [statusValue, setStatusValue] = useState("all");
  const [openMorePractiseModal, setOpenMorePractiseModal] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [practiseName, setPractiseName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [OpenInviteMessage, setOpenInviteMessage] = useState(false);
  const [successInviteMessage, setSuccessInviteMessage] = useState(null);
  const organizationId = Cookies.get("org_id_vita");
  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openReshareCredsModal, setOpenReshareCredsModal] = useState(false);
  const [showCredSuccessMessage, setShowCredSuccessMessage] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const openMenuHandler = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const dispatch = useDispatch();

  const getOrganization = useSelector((state) => state.getOrganization);
  const {
    loading: loadingOrg,
    error: errorOrg,
    organizationDetail,
  } = getOrganization;

  const getAgencyEmail = useSelector((state) => state.getAgencyEmail);
  const { agencyEmail } = getAgencyEmail;

  // const getAgencyEmail = useSelector((state) => state.getAgencyEmail);
  // const {
  //   loading: loadingEmail,
  //   error: errorEmail,
  //   agencyEmail,
  // } = getAgencyEmail;

  const reshareAgencyCreds = useSelector((state) => state.reshareAgencyCreds);
  const {
    loading: loadingCreds,
    error: errorCreds,
    resharedCreds,
  } = reshareAgencyCreds;

  const createWorkspace = useSelector((state) => state.createWorkspace);
  const {
    loading: loadingAgency,
    error: errorAgency,
    workspace,
  } = createWorkspace;

  const inviteAgent = useSelector((state) => state.inviteAgent);
  const {
    loading: loadingAgent,
    error: errorAgent,
    invitedAgent,
  } = inviteAgent;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenModal(false);
    setShowCredSuccessMessage(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const addPracticeHandler = () => {
    const agencyObj = {
      agency: {
        name: practiseName,
        primary_email: email,
      },
      organization_id: organizationId,
    };

    dispatch(createWorkspaceFunc(agencyObj));
    setEmailErrorMsg("");
  };

  useEffect(() => {
    if (workspace?.data?.ok) {
      dispatch(resetCreateWorkSpace());
      const agentObj = {
        agency_id: workspace?.data?.agency?.id,
        send_credentials: true,
        agent_obj: {
          first_name: ownerName,
          email: email,
          role: "Owner",
          permissions: {
            access_patient_details: {
              create: true,
              read: true,
              edit: true,
              delete: true,
            },
            // can_chat_client: true,
            can_access_billing: false,
          },
        },
      };
      dispatch(inviteAgentFunc(agentObj));
      setEmailErrorMsg("");
    }
  }, [workspace]);

  useEffect(() => {
    if (!loadingAgency && !workspace?.ok) {
      setEmailErrorMsg(workspace?.message);
      // setOpenModal(true);
    }
  }, [workspace]);

  useEffect(() => {
    if (invitedAgent?.ok) {
      setOpenInviteMessage(true);
      setSuccessInviteMessage(invitedAgent?.message);
      setPractiseName("");
      setEmail("");
      setOwnerName("");
      setOpenMorePractiseModal(false);
      setEmailError(false);
      setEmailErrorMsg("");
      setOpenMorePractiseModal(false);
      dispatch(getOrganizationFunc());
      dispatch(resetAgentInvite());
    } else {
      setOpenMorePractiseModal(false);
      dispatch(resetAgentInvite());
    }
  }, [invitedAgent]);

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;
    setEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);
    if (emailInputValue && !emailIsValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  const columns = [
    { id: "name", label: "Name", minWidth: 300 },
    // { id: "status", label: "Status", minWidth: 112 },
    { id: "team", label: "Team", minWidth: 157 },
    {
      id: "practice_id",
      label: "Practice ID",
      minWidth: 165,

      // renderCell: ({ value }) => (
      //   <Typography
      //     display="flex"
      //     alignItems="center"
      //     // variant="body2"
      //     color="secondary"
      //     height="100%"
      //   >
      //     {value}
      //   </Typography>
      // ),
    },
    {
      id: "email",
      label: "Email",
      minWidth: 230,
      // align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "actions",
      label: "Actions",
      // minWidth: 190,

      align: "right",
      // format: (value) => value.toFixed(2),
    },
  ];

  const rows = organizationDetail?.organization?.agencies;
  // console.log("OOO", organizationDetail);

  const editPracticeHandler = (row) => {
    setOpenDrawer(true);
    setSelectedRow(row);
  };

  const getAgencyEmailHandler = (row) => {
    setOpenReshareCredsModal(true);
    setSelectedRow(row);
    if (selectedRow?.id !== row?.id) {
      dispatch(getAgencyEmailFunc(row?.id));
    }
    // setAnchorEl(null);
  };

  const reshareCredsHandler = () => {
    const agencyIdObj = {
      agency_id: selectedRow?.id,
    };

    dispatch(reshareAgencyCredsFunc(agencyIdObj));
  };

  useEffect(() => {
    if (resharedCreds?.ok) {
      setOpenReshareCredsModal(false);
      setShowCredSuccessMessage(true);
      dispatch(resetReshareAgencyCreds());
    }
  }, [resharedCreds]);

  return (
    <>
      {!loadingAgency && emailErrorMsg && (
        <AlertMessage
          message={emailErrorMsg}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {showCredSuccessMessage && (
        <AlertMessage
          message={`${selectedRow?.name}'s login credentials have been sent to the registered email address: ${agencyEmail?.email}`}
          severity="success"
          open={showCredSuccessMessage}
          handleClose={handleClose}
          backgroundColor="#EDF7ED"
          width="380px"
          textAlign="inherit"
        />
      )}

      {openDrawer && (
        <EditPracticeDrawer
          anchor={"right"}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      )}

      {OpenInviteMessage && (
        <AlertMessage
          message={successInviteMessage}
          severity="success"
          open={OpenInviteMessage}
          handleClose={() => setOpenInviteMessage(false)}
        />
      )}

      {openMorePractiseModal && (
        <AlertDialog
          data={{ title: "Add Practice" }}
          openModal={openMorePractiseModal}
          disabled={!practiseName && !email && !ownerName}
          isLoading={loadingAgency || loadingAgent}
          closeModal={() => {
            setPractiseName("");
            setEmail("");
            setOwnerName("");
            setOpenMorePractiseModal(false);
            setEmailError(false);
          }}
          moveFileDialog={true}
          formSubmitFunction={addPracticeHandler}
          submitText="Add Practice"
          maxWidth="538px"
          titlePadding="24px !important"
          closeOverlay={false}
          padding="24px"
          contentPadding="16px 24px"
          height="668px"
        >
          <AddPractiseContent
            email={email}
            emailChangeHandler={emailChangeHandler}
            emailError={emailError}
            practiseName={practiseName}
            setPractiseName={setPractiseName}
            ownerName={ownerName}
            setOwnerName={setOwnerName}
          />
        </AlertDialog>
      )}

      {openReshareCredsModal && (
        <AlertDialog
          data={{ title: "Reshare Credentials" }}
          openModal={openReshareCredsModal}
          // disabled={!practiseName && !email && !ownerName}
          isLoading={loadingCreds}
          closeModal={() => {
            setOpenReshareCredsModal(false);
          }}
          moveFileDialog={true}
          formSubmitFunction={reshareCredsHandler}
          submitText="Send"
          maxWidth="444px"
          titlePadding="16px 24px !important"
          closeOverlay={false}
          padding="8px"
          contentPadding="0 24px 24px 24px"
          contentPaddingTop="0"
          // height="668px"
        >
          <ReshareCredsModalContent practiceName={selectedRow?.name} />{" "}
        </AlertDialog>
      )}

      <Stack
        border="1px solid #E7E7E7"
        bgcolor="#fff"
        padding="24px"
        paddingBottom="20px"
        height="560px"
        borderRadius="8px"
        gap="16px"
      >
        <Box
          padding="16px 0"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="16px"
        >
          {/* <Box display="flex" alignItems="center" gap="16px">
          <FormControl
            sx={{ minWidth: "300px" }}
            size="small"
            variant="outlined"
          >
            <InputLabel htmlFor="search-agents">Search</InputLabel>
            <OutlinedInput
              //   autoFocus={query ? true : false}
              id="search-agents"
              placeholder="Name or Email"
              //   value={query}
              //   onChange={(e) => dispatch(setQuery(e.target.value))}
              label="Search"
              size="small"
              sx={{ backgroundColor: "#FFF" }}
            />
          </FormControl>
          <CustomSelectFilter
            menuItems={statusOptions}
            changeSelectValueHandler={(e) => setStatusValue(e.target.value)}
            selectValue={statusValue}
            id="status"
            minWidth={180}
            size="small"
            label="Status"
            MenuProps={MenuProps}
          />
        </Box> */}
          <Button
            disableElevation
            sx={{
              padding: "8px 16px",
              background: "linear-gradient(90deg, #0C80F3 0%, #2954F2 100%)",
              "&:hover": {
                background: "linear-gradient(180deg, #0C80F3 0%, #5865E1 100%)",
              },
            }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenMorePractiseModal(true)}
          >
            Add Practice
          </Button>
        </Box>

        {/* TABLE */}
        {loadingOrg ? (
          <CaseTableSkeleton
            padding="8px 16px"
            marginTop="0"
            headers={["Name", "Team", "Practice ID", "Email", "Actions"]}
            numRows={6}
            cellWidths={[302, 157, 165, 230, 104]}
          />
        ) : (
          <TableContainer sx={{ maxHeight: "100%", height: "100%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns?.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index}>
                      <StyledTableCell>
                        <PractiseBoxCard
                          mb={0}
                          title={row?.name}
                          padding="0"
                          // open={open}
                          logoType={row?.logo_type}
                          iconName={row?.icon_name}
                          iconColor={row?.icon_color}
                          logoUrl={row?.logo_url}
                          organizationPortal={true}
                          // switchWorkspaceHandler={switchWorkspaceHandler}
                          // practiseId={item?.id}
                          // icon={
                          //   item?.id === Number(currentAgencyId) && (
                          //     <TickCircleIcon />
                          //   )
                          // }
                        />
                        {/* {row?.logo_type === "custom" ? (
                            <Box
                              width="40px"
                              height="40px"
                              borderRadius="4px"
                              overflow="hidden"
                            >
                              <img
                                width="100%"
                                height="100%"
                                src={row?.logo_url}
                                alt="practise logo"
                              />
                            </Box>
                          ) : iconName === null || iconName === "avatar_icon" ? (
                            <Avatar
                              variant="rounded"
                              sx={{ background: getIconColor(iconColor) }}
                              {...stringAvatar(title)}
                            />
                          ) : (
                            <Box
                              width="40px"
                              height="40px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                background: getIconColor(iconColor),
                              }}
                              borderRadius="4px"
                            >
                              {getIconName(iconName)}
                            </Box>
                          )}
                          <Typography variant="body1"></Typography> */}
                        {/* {row?.name} */}
                      </StyledTableCell>
                      <StyledTableCell>
                        {" "}
                        <AvatarGroups
                          maxNumber={2}
                          fontSize="12px"
                          color="rgba(0, 0, 0, 0.38)"
                          assigned_to_details={row?.agents}
                          avatarBgColor={row?.avatar_color}
                          width={24}
                          height={24}
                          avatarFontSize="13px"
                        />
                      </StyledTableCell>
                      <StyledTableCell sx={{ color: "#566875 !important" }}>
                        {row?.id}
                      </StyledTableCell>
                      <StyledTableCell sx={{ color: "#566875 !important" }}>
                        {row?.primary_email ? row?.primary_email : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <IconButton
                            sx={{ padding: "6px" }}
                            onClick={() => editPracticeHandler(row)}
                          >
                            <EditPracticeIcon />
                          </IconButton>
                          {/* <IconButton
                            sx={{ padding: "6px" }}
                            onClick={() => getAgencyEmailHandler(row)}
                          >
                            <Box
                              width="20px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <img
                                width="100%"
                                src={ReshareIcon}
                                alt="reshare_icon"
                              />
                            </Box>
                          </IconButton> */}

                          {/* <IconButton
                            sx={{ padding: "4px" }}
                            onClick={(event) => openMenuHandler(event, row)}
                          >
                            <MoreVertIcon />{" "}
                          </IconButton> */}
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {/* {Boolean(anchorEl) && (
                <Menu
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={reshareCredsHandler}>
                    Reshare Credentials
                  </MenuItem>
                  <MenuItem
                    sx={{ color: "#FF2D2D" }}
                    // onClick={() => openCaseDeleteHandler(selectedRow)}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              )} */}
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
};

export default PracticesTab;
