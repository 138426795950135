import {
  CREATE_ORGANIZATION_FAIL,
  CREATE_ORGANIZATION_REQUEST,
  CREATE_ORGANIZATION_SUCCESS,
  GET_BILL_BREAKDOWN_FAIL,
  GET_BILL_BREAKDOWN_REQUEST,
  GET_BILL_BREAKDOWN_SUCCESS,
  GET_BILL_INVOICES_FAIL,
  GET_BILL_INVOICES_REQUEST,
  GET_BILL_INVOICES_SUCCESS,
  GET_CURRENT_BILL_FAIL,
  GET_CURRENT_BILL_REQUEST,
  GET_CURRENT_BILL_SUCCESS,
  GET_ORG_AGENTS_FAIL,
  GET_ORG_AGENTS_REQUEST,
  GET_ORG_AGENTS_SUCCESS,
  GET_ORG_PATIENTS_FAIL,
  GET_ORG_PATIENTS_REQUEST,
  GET_ORG_PATIENTS_SUCCESS,
  GET_ORG_PAYMENT_DETAILS_FAIL,
  GET_ORG_PAYMENT_DETAILS_REQUEST,
  GET_ORG_PAYMENT_DETAILS_SUCCESS,
  GET_ORGANIZATION_FAIL,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  GET_PAYMENT_INVOICE_FAIL,
  GET_PAYMENT_INVOICE_REQUEST,
  GET_PAYMENT_INVOICE_SUCCESS,
  RESET_BILL_BREAKDOWN,
  RESET_CREATE_ORGANIZATION,
  RESET_GET_BILL_INVOICES,
  RESET_GET_CURRENT_BILL,
  RESET_GET_ORG_AGENTS,
  RESET_GET_ORGANIZATION,
  RESET_ORG_PATIENTS,
  RESET_ORG_PAYMENT_DETAILS,
  RESET_PAYMENT_INVOICE,
  RESET_UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_FAIL,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
} from "../../assets/constants/organizationConstants";

//================   CREATE ORGANIZATION REDUCER ================
export const createOrganizationReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ORGANIZATION_REQUEST:
      return { loading: true };
    case CREATE_ORGANIZATION_SUCCESS:
      return { loading: false, organization: action.payload };
    case CREATE_ORGANIZATION_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CREATE_ORGANIZATION:
      return {};
    default:
      return state;
  }
};

//================   GET ORGANIZATION REDUCER ================
export const getOrganizationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_REQUEST:
      return { loading: true };
    case GET_ORGANIZATION_SUCCESS:
      return { loading: false, organizationDetail: action.payload };
    case GET_ORGANIZATION_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_ORGANIZATION:
      return {};
    default:
      return state;
  }
};

//================   GET ORGANIZATION PATIENTS COUNT REDUCER ================
export const getOrgPatientsCountReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORG_PATIENTS_REQUEST:
      return { loading: true };
    case GET_ORG_PATIENTS_SUCCESS:
      return { loading: false, orgPatientsCount: action.payload };
    case GET_ORG_PATIENTS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ORG_PATIENTS:
      return {};
    default:
      return state;
  }
};

//================   GET ORGANIZATION AGENTS COUNT REDUCER ================
export const getOrgAgentsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORG_AGENTS_REQUEST:
      return { loading: true };
    case GET_ORG_AGENTS_SUCCESS:
      return { loading: false, orgAgents: action.payload };
    case GET_ORG_AGENTS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_ORG_AGENTS:
      return {};
    default:
      return state;
  }
};

//================   UPDATE ORGANIZATION REDUCER ================
export const updateOrganizationReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ORGANIZATION_REQUEST:
      return { loading: true };
    case UPDATE_ORGANIZATION_SUCCESS:
      return { loading: false, updatedOrganization: action.payload };
    case UPDATE_ORGANIZATION_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UPDATE_ORGANIZATION:
      return {};
    default:
      return state;
  }
};

//================   GET CURRENT BILL REDUCER ================
export const getCurrentBillReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CURRENT_BILL_REQUEST:
      return { loading: true };
    case GET_CURRENT_BILL_SUCCESS:
      return { loading: false, currentMonthBill: action.payload };
    case GET_CURRENT_BILL_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_CURRENT_BILL:
      return {};
    default:
      return state;
  }
};

//================   GET CURRENT BILL REDUCER ================
export const getOrgPaymentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORG_PAYMENT_DETAILS_REQUEST:
      return { loading: true };
    case GET_ORG_PAYMENT_DETAILS_SUCCESS:
      return { loading: false, orgPaymentDetails: action.payload };
    case GET_ORG_PAYMENT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ORG_PAYMENT_DETAILS:
      return {};
    default:
      return state;
  }
};

//================   GET BILL BREAKDOWN REDUCER ================
export const getBillBreakDownReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BILL_BREAKDOWN_REQUEST:
      return { loading: true };
    case GET_BILL_BREAKDOWN_SUCCESS:
      return { loading: false, billBreakdown: action.payload };
    case GET_BILL_BREAKDOWN_FAIL:
      return { loading: false, error: action.payload };
    case RESET_BILL_BREAKDOWN:
      return {};
    default:
      return state;
  }
};

//================   GET PAYMENT INVOICE REDUCER ================
export const getPaymentInvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PAYMENT_INVOICE_REQUEST:
      return { loading: true };
    case GET_PAYMENT_INVOICE_SUCCESS:
      return { loading: false, paymentInvoice: action.payload };
    case GET_PAYMENT_INVOICE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_PAYMENT_INVOICE:
      return {};
    default:
      return state;
  }
};

//================   GET PAYMENT INVOICE REDUCER ================
export const getBillInvoicesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BILL_INVOICES_REQUEST:
      return { loading: true };
    case GET_BILL_INVOICES_SUCCESS:
      return { loading: false, invoiceDetails: action.payload };
    case GET_BILL_INVOICES_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_BILL_INVOICES:
      return {};
    default:
      return state;
  }
};
