import { Box, Chip, Stack, Typography } from "@mui/material";
import React from "react";

const BillingCard = ({
  title,
  subtitle,
  numValue,
  children,
  padding = "32px",
  border = "1px solid #EBEBEB",
  bgColor = "#fff",
  width = "100%",
  maxWidth,
  loadingBill,
}) => {
  return (
    <Stack
      border={border}
      padding={padding}
      borderRadius="8px"
      gap="16px"
      justifyContent="center"
      //   minHeight="190px"
      height="100%"
      maxWidth={maxWidth}
      width={width}
      bgcolor={bgColor}
    >
      {children ? (
        children
      ) : (
        <>
          {" "}
          <Typography variant="h6" fontSize="20px">
            {title}
          </Typography>
          <Box>
            <Chip
              label={subtitle}
              variant="filled"
              sx={{
                // width:""
                backgroundColor: "rgba(12, 243, 170, 0.08)",
                color: "#3DC583",
                fontWeight: "bold",
                borderRadius: "4px",
                padding: "2px 8px",
                height: "auto",
                "& .MuiChip-label": {
                  padding: "2px",
                },
              }}
            />
          </Box>
          <Typography variant="h5" color="#07417A" fontWeight={500}>
            {numValue}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default BillingCard;
