// export const CREATE_AGENCY_REQUEST = "CREATE_AGENCY_REQUEST";
// export const CREATE_AGENCY_SUCCESS = "CREATE_AGENCY_SUCCESS";
// export const CREATE_AGENCY_FAIL = "CREATE_AGENCY_FAIL";

export const CREATE_WORKSPACE_REQUEST = "CREATE_WORKSPACE_REQUEST";
export const CREATE_WORKSPACE_SUCCESS = "CREATE_WORKSPACE_SUCCESS";
export const CREATE_WORKSPACE_FAIL = "CREATE_WORKSPACE_FAIL";
export const RESET_CREATE_WORKSPACE = "RESET_CREATE_WORKSPACE";

export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_CLIENTS_FAIL = "GET_ALL_CLIENTS_FAIL";
export const RESET_ALL_CLIENTS = "RESET_ALL_CLIENTS";

export const GET_ALL_CLIENTS_UNPAGINATED_REQUEST =
  "GET_ALL_CLIENTS_UNPAGINATED_REQUEST";
export const GET_ALL_CLIENTS_UNPAGINATED_SUCCESS =
  "GET_ALL_CLIENTS_UNPAGINATED_SUCCESS";
export const GET_ALL_CLIENTS_UNPAGINATED_FAIL =
  "GET_ALL_CLIENTS_UNPAGINATED_FAIL";

export const GET_AGENCY_REQUEST = "GET_AGENCY_REQUEST";
export const GET_AGENCY_SUCCESS = "GET_AGENCY_SUCCESS";
export const GET_AGENCY_FAIL = "GET_AGENCY_FAIL";

export const ADD_SCAN_REQUEST = "ADD_SCAN_REQUEST";
export const ADD_SCAN_SUCCESS = "ADD_SCAN_SUCCESS";
export const ADD_SCAN_FAIL = "ADD_SCAN_FAIL";

export const GET_QR_CODE_REQUEST = "GET_QR_CODE_REQUEST";
export const GET_QR_CODE_SUCCESS = "GET_QR_CODE_SUCCESS";
export const GET_QR_CODE_FAIL = "GET_QR_CODE_FAIL";

export const DOWNLOAD_QR_CODE_REQUEST = "DOWNLOAD_QR_CODE_REQUEST";
export const DOWNLOAD_QR_CODE_SUCCESS = "DOWNLOAD_QR_CODE_SUCCESS";
export const DOWNLOAD_QR_CODE_FAIL = "DOWNLOAD_QR_CODE_FAIL";

export const GET_AGENCY_EMAIL_REQUEST = "GET_AGENCY_EMAIL_REQUEST";
export const GET_AGENCY_EMAIL_SUCCESS = "GET_AGENCY_EMAIL_SUCCESS";
export const GET_AGENCY_EMAIL_FAIL = "GET_AGENCY_EMAIL_FAIL";

export const RESHARE_AGENCY_CREDS_REQUEST = "RESHARE_AGENCY_CREDS_REQUEST";
export const RESHARE_AGENCY_CREDS_SUCCESS = "RESHARE_AGENCY_CREDS_SUCCESS";
export const RESHARE_AGENCY_CREDS_FAIL = "RESHARE_AGENCY_CREDS_FAIL";
export const RESET_RESHARE_AGENCY_CREDS = "RESET_RESHARE_AGENCY_CREDS";

export const UPDATE_AGENCY_REQUEST = "UPDATE_AGENCY_REQUEST";
export const UPDATE_AGENCY_SUCCESS = "UPDATE_AGENCY_SUCCESS";
export const UPDATE_AGENCY_FAIL = "UPDATE_AGENCY_FAIL";

export const GET_ALL_AGENCIES_REQUEST = "GET_ALL_AGENCIES_REQUEST";
export const GET_ALL_AGENCIES_SUCCESS = "GET_ALL_AGENCIES_SUCCESS";
export const GET_ALL_AGENCIES_FAIL = "GET_ALL_AGENCIES_FAIL";
export const RESET_ALL_AGENCIES = "RESET_ALL_AGENCIES";
