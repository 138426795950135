import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import BillingTab from "../Organization/BillingTab";
import BillingManaged from "../BillingManaged";

const BillingOrgTab = () => {
  const getOrganization = useSelector((state) => state.getOrganization);
  const {
    loading: loadingOrg,
    error: errorOrg,
    organizationDetail,
  } = getOrganization;

  return (
    <>
      {loadingOrg ? (
        <Stack
          boxShadow="0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
          bgcolor="#fff"
          height="100%"
          alignItems="center"
          justifyContent="center"
          gap="24px"
        ></Stack>
      ) : organizationDetail?.organization?.signed_up_as === "organization" ? (
        <BillingManaged />
      ) : (
        <BillingTab showTable={false} />
      )}
    </>
  );
};

export default BillingOrgTab;
