import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import RectangleSkeletons from "../RectangleSkeletons";
import EmptyBoxImage from "../../assets/images/empty-box.png";
import { shortDateTimeFormat } from "../../utils/helpers";

const OrgUpdateCard = () => {
  const getOrganization = useSelector((state) => state.getOrganization);
  const {
    loading: loadingOrg,
    error: errorOrg,
    organizationDetail,
  } = getOrganization;

  return (
    <Stack
      border="1px solid #E7E7E7"
      padding="24px"
      borderRadius="8px"
      maxWidth="100%"
      width="100%"
      bgcolor="#fff"
      gap="16px"
      height="350px"
    >
      <Typography variant="subtitle2" fontSize="18px" color="#0D0D0D">
        Important updates
      </Typography>

      {loadingOrg ? (
        <RectangleSkeletons />
      ) : organizationDetail?.organization?.organization_logs?.length ? (
        <Stack overflow="auto" gap="16px">
          {organizationDetail?.organization?.organization_logs?.map(
            (log, index) => (
              <Stack
                gap="10px"
                key={index}
                padding="16px"
                bgcolor={
                  log?.log_type === "failed_payment"
                    ? "rgba(255, 133, 45, 0.10)"
                    : "#F4F6F8"
                }
                borderRadius="8px"
              >
                <Typography variant="body2" color="secondary">
                  {log?.log_text}
                </Typography>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="8px"
                >
                  <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
                    {shortDateTimeFormat(log?.created_at)}
                  </Typography>
                  {(log?.log_type === "failed_payment" ||
                    log?.log_type === "successful_payment") && (
                    <Button
                      onClick={() => {
                        window.open(
                          process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL,
                          "_blank"
                        );
                      }}
                      sx={{
                        textTransform: "capitalize",
                        textDecoration: "underline",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {log?.log_type === "failed_payment"
                        ? "Update Billing Details"
                        : "View Billing Details"}
                    </Button>
                  )}
                </Box>
              </Stack>
            )
          )}
        </Stack>
      ) : (
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Box width="52px" height="52px">
            <img width="100%" height="100%" src={EmptyBoxImage} alt="no data" />
          </Box>
          <Typography variant="body2" color="#0D0D0D">
            No updates available
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default OrgUpdateCard;
