import React, { useEffect, useState } from "react";
import BgBox from "../../../common/BgBox";
import HeaderText from "../../../common/HeaderText";
import CardUI from "../../../common/Card";
import { Box, Button, Grid, Typography, Link } from "@mui/material";
import Textfield from "../../../common/Textfield";
import { Link as LinkTo, useLocation, useNavigate } from "react-router-dom";
import CircularProgressLoading from "../../../common/CircularProgress";
import AlertMessage from "../../../common/AlertMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkspaceFunc,
  resetUpdateAgentProfile,
} from "../../../actions/Agency";
import MccLogo from "../../../components/MccLogo";
import { LoadingButton } from "@mui/lab";
import Cookies from "js-cookie";

const CreateWorkspace = () => {
  const [firmName, setFirmName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const { state } = useLocation();

  const agentData = state && state?.agentData;

  const dispatch = useDispatch();
  const createWorkspace = useSelector((state) => state.createWorkspace);
  const { loading, error, workspace } = createWorkspace;

  useEffect(() => {
    if (state === null) {
      navigate(-1);
      return;
    }
    Cookies.set("route", "/create-workspace");
  }, []);

  useEffect(() => {
    if (!error && workspace?.data?.ok) {
      navigate("/create-agentname", {
        state: { createWorkspace: true, agentData: agentData },
      });
    }
  }, [workspace]);

  // useEffect(() => {
  //   if (agentData?.existing_agencies.length) {
  //     navigate("/");
  //   }
  // }, [agentData]);

  const [open, setOpen] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const createWorkspaceHandler = async (e) => {
    e.preventDefault();

    dispatch(resetUpdateAgentProfile());

    // navigate("/create-agentname", { state: { createWorkspace: true } });

    if (!firmName) {
      setEmailError(true);
      return;
    }

    setEmailError(false);

    const agencyObj = {
      agency: {
        name: firmName,
      },
      independent_practice: true,
    };

    dispatch(createWorkspaceFunc(agencyObj));
    setOpen(true);
  };

  if (state === null) {
    return;
  }

  return (
    <>
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {!loading && errorMessage && (
        <AlertMessage
          message={errorMessage}
          severity="warning"
          open={open}
          handleClose={handleClose}
        />
      )}
      <BgBox>
        <CardUI maxWidth={570} paddingTop="86px">
          {/* MCC LOGO */}
          <MccLogo />

          <Grid container component="main">
            <Box
              component="form"
              onSubmit={createWorkspaceHandler}
              xs={12}
              display="flex"
              gap="40px"
              flexDirection="column"
              width="100%"
            >
              <Box>
                <Typography
                  variant="body1"
                  className="d-flex"
                  fontSize={16}
                  mb="16px"
                  color="#566875"
                >
                  Step 1 of 2
                </Typography>
                <HeaderText
                  variant="h3"
                  fontSize={48}
                  textContent="What is the name of your Practice?"
                  textAlign="initial"
                />
                <Typography
                  variant="body1"
                  className="d-flex"
                  fontSize={16}
                  mt="16px"
                  color="#566875"
                >
                  This will also be the name of your workspace.
                </Typography>
              </Box>

              <Box display="flex" flexDirection="column" gap="16px">
                <Textfield
                  value={firmName}
                  onChange={(e) => setFirmName(e.target.value)}
                  variant="outlined"
                  name="firmName"
                  helperText={
                    !firmName &&
                    emailError &&
                    "Please enter your practice name!"
                  }
                  errorContent={
                    !firmName &&
                    emailError &&
                    "Please enter your practice name!"
                  }
                />
              </Box>
              <LoadingButton
                loading={loading}
                fullWidth
                type="submit"
                variant="contained"
                sx={{ padding: "8px 22px", fontSize: 15 }}
              >
                Next
              </LoadingButton>

              {/* <Button
        className="w-100"
        fontSize="15px"
        color="primary"

        title="Sign In"
        variant="contained"
        width="100%"
        height="48px"
        // disabled={!fieldVals?.email || !fieldVals?.password} // Disabling it off on purpose
        // onClick={() => onSignIn()}
        // loading={signInLoading}
        loadingColor="primary"
      /> */}
            </Box>
          </Grid>
        </CardUI>
      </BgBox>
    </>
  );
};

export default CreateWorkspace;
