import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import CircularProgressLoading from "../../common/CircularProgress";
import { useDispatch } from "react-redux";
import { setToggleMinimize } from "../../actions/AppConfig";
import { useNavigate } from "react-router-dom";

const LoadingBulkPatients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const minimiseHandler = () => {
    dispatch(setToggleMinimize(true));
    navigate("/patients");
  };

  return (
    <Stack gap="24px" alignItems="center" justifyContent="center" height="100%">
      <CircularProgressLoading size={58} />
      <Stack gap="4px" alignItems="center">
        <Typography variant="body1">
          Please wait while we are importing patients.
        </Typography>
        <Box>
          <Button
            onClick={minimiseHandler}
            variant="text"
            sx={{
              textTransform: "capitalize",
              textDecoration: "underline",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            Minimise
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default LoadingBulkPatients;
