import axios from "axios";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  Tooltip,
  Menu,
  Chip,
  Snackbar,
  Alert,
  FormControlLabel,
  Switch,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ArrowLeftIcon, WarningUnenrollIcon } from "../../../assets/constants";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChatIcon from "../../../icons/ChatIcon";
import FolderGreyIcon from "../../../icons/FolderGreyIcon";
import MoreInfoIcon from "../../../icons/MoreInfoIcon";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CardUI from "../../../common/Card";
import HeaderText from "../../../common/HeaderText";
import companyLogo from "../../../assets/images/company-logo.jpg";
import ProfilePicture from "../../../common/ProfilePicture";
import { EditIcon } from "../../../assets/constants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinearProgressbar from "../../../common/LinearProgressbar";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OverviewTab from "../OverviewTab";
import MoreInfoTab from "../MoreInfoTab";
import ChatTab from "../ChatTab";
import DocumentTab from "../DocumentTab";
import { useDispatch, useSelector } from "react-redux";
import {
  addEducationSetupFunc,
  addEquipSetupFunc,
  addRecordConsentFunc,
  deleteCaseFunc,
  getCaseClientDocsFunc,
  getCaseDetailByCaseId,
  getClientPainlogsFunc,
  getNextPatientFunc,
  getPieChartStatsFunc,
  resetAllCases,
  resetCaseClientDocs,
  resetCaseDetailByCaseId,
  resetClientPainlogs,
  resetEvidenceReport,
  resetNextPatient,
  resetPieChartStats,
  resetRecordConsent,
  resetUpdateCase,
  setNextPatientObjData,
  updateCaseFunc,
} from "../../../actions/Cases";
import AlertMessage from "../../../common/AlertMessage";
import EditCaseDrawer from "../EditCaseDrawer";
import AlertDialog from "../../../components/AlertDialog";
import {
  getAgencyDetailFunc,
  getAgentsListFunc,
} from "../../../actions/Agency";
import { segmentTrack } from "../../../utils/helpers/tracking_utils";
import {
  calculateAppStatus,
  caseStatusBgColor,
  caseStatusColor,
  caseValueBgColor,
  caseValueColor,
  dateFormat,
  formatCaseTypeText,
  formatElapsedTime,
  getEvidenceDownloadDateRanges,
  getRandomAvatarBackgroundColor,
  getSortApiText,
  phoneStatusContent,
  showPhoneStatusColors,
  stringAvatar,
} from "../../../utils/helpers";
import SkeletonGrid from "../../../components/SkeletonGrid";
import CaseHeaderSkeleton from "../../../components/CaseHeaderSkeleton";
import RectangleSkeletons from "../../../components/RectangleSkeletons";
import CaseSummaryBoxContent from "../../../components/CaseSummaryBoxContent";
import {
  intakeQuestionsAnswersFunc,
  resetIntakeQuestionsAnswers,
} from "../../../actions/ClientIntakeActions";
import {
  closeTimeModal,
  createCustomNotesFunc,
  elapsedTimeFunc,
  getAllBodyPartsFunc,
  getMobileStatusFunc,
  openTimeModal,
  resetAllBodyParts,
  setEndTime,
  setGoBackFunc,
  setGoNextPatientFunc,
  setIsPaused,
  setIsRunning,
  setIsStopped,
  setNavigateItemFunc,
  setPausedTime,
  setPauseStartTime,
  setPreviousPageFunc,
  setStartTime,
} from "../../../actions/AppConfig";

import Cookies from "js-cookie";
import dayjs from "dayjs";

import PlayIcon from "../../../icons/PlayIcon";
import PauseIcon from "../../../icons/PauseIcon";
import StopIcon from "../../../icons/StopIcon";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import BillingTab from "../BillingTab";
import BillingTabIcon from "../../../icons/BillingTabIcon";
import CptDrawer from "../../../components/CptDrawer";
import {
  createTimeEntryFunc,
  updateTimeEntryFunc,
} from "../../../actions/TimeEntry";
import ConsentScreenContent from "../../../components/ConsentScreenContent";
import {
  initCptSetupFunc,
  resetInitCptSetup,
} from "../../../actions/CptCodesActions";
import ConsentIcon from "../../../icons/ConsentIcon";
import {
  resendClientCredentialsFunc,
  resetResendClientCredsState,
} from "../../../actions/Auth";
import OverlapTimeEntryCard from "../../../components/OverlapTimeEntryCard";
import NetworkStatusBanner from "../../../components/NetworkStatusBanner";
import ExportEvidenceReportContent from "../../../components/ExportEvidenceReportContent";
import PendingIconContent from "../../../components/PendingIconContent";
import InactiveIconContent from "../../../components/InactiveIconContent";
import ActiveIconContent from "../../../components/ActiveIconContent";
import DownloadReportStatus from "../../../components/DownlaodReportStatus";

const CaseOverview = () => {
  const token = Cookies.get("token");
  const agencyId = Cookies.get("agency_id_vita");

  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({});
  const [playIconClicked, setPlayIconClicked] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [tosConsentChecked, setTosContentChecked] = useState(false);
  const [initSetupChecked, setInitSetupChecked] = useState(false);
  const [initSetup2Checked, setInitSetup2Checked] = useState(false);

  const [isSticky, setIsSticky] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab") || "overview";

  // const [questionsAnswers, setQuestionsAnswers] = useState([]);
  const [tabValue, setTabValue] = useState("overview");
  const [caseValue, setCaseValue] = useState("high");
  const [caseStatus, setCaseStatus] = useState("open");
  const [changedCaseStatus, setChangedCaseStatus] = useState(false);
  const [changedCaseValue, setChangedCaseValue] = useState(false);
  const [fromCaseOverview, setFromCaseOverview] = useState(false);
  const [modalContentData, setModalContentData] = useState({});
  const [openDeleteCaseModal, setOpenDeleteCaseModal] = useState(false);
  const [openCaseSummaryDialogBox, setOpenCaseSummaryDialogBox] =
    useState(false);
  const [documentValue, setDocumentValue] = useState([]);
  const [bodyPartsValue, setBodyPartsValue] = useState([]);
  const [fromPdfDate, setFromPdfDate] = useState(null);
  const [toPdfDate, setToPdfDate] = useState(dayjs());
  const [dateFieldIsTouched, setDateFieldIsTouched] = useState(false);
  const [fromPdfButton, setFromPdfButton] = useState(false);
  const [allBodyPartsData, setAllBodyPartsData] = useState([]);
  const [checkedPainLogs, setCheckedPainLogs] = useState(true);
  const [checkedEventLogs, setCheckedEventLogs] = useState(true);
  const [pieChartStatsState, setPieChartStatsState] = useState({});
  const [documentsResponse, setDocumentsResponse] = useState(null);
  const [loadingDocumentURLs, setLoadingDocumentURLs] = useState(false);
  const [billingActive, setBillingActive] = useState(false);
  const [caseAddedInPlan, setCaseAddedInPlan] = useState(false);
  const [showBillingPlan, setShowBillingPlan] = useState(false);
  const [openCancelBillingBox, setOpenCancelBillingBox] = useState(false);
  // const [openStopTimeModal, setOpenStopTimeModal] = useState(false);
  // const [startTimeValue, setStartTimeValue] = useState(dayjs());
  const [dateValue, setDateValue] = useState(dayjs());
  const [selectValue, setSelectValue] = useState("");
  const [notesValue, setNotesValue] = useState("");
  const [timeDetails, setTimeDetails] = useState(dayjs());
  const [permissionMessage, setPermissionMessage] = useState(null);
  const [appStatus, setAppStatus] = useState(null);

  // const [pausedTime, setPausedTime] = useState(null);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessageContent, setSuccessMessageContent] = useState("");

  const inputRef = useRef(null);
  // const [elapsedTime, setElapsedTime] = useState(0);
  // const [isRunning, setIsRunning] = useState(true);
  // const [isPaused, setIsPaused] = useState(false);
  // const [goBackTimer, setGoBackTimer] = useState(false);

  const intervalRef = useRef(null);

  // const queryParams = new URLSearchParams(location.search);
  // const selectedTab = queryParams.get("tab") || "overview";

  // const selectedRowId =
  //   location &&
  //   location.state &&
  //   location.state.rowData &&
  //   location.state.rowData.id;

  const tabsData = [
    {
      label: "Overview",
      value: "overview",
      icon: <VisibilityOutlinedIcon />,
    },
    {
      label: "Chat",
      value: "chat",
      icon: <ChatIcon />,
    },
    {
      label: "More Info",
      value: "more_info",
      icon: <MoreInfoIcon />,
    },
    {
      label: "Billing",
      value: "billing",
      icon: <BillingTabIcon />,
    },
  ];

  const pieRef = useRef();

  const [anchorMenuEl, setAnchorMenuEl] = useState(null);

  const [openConsentModal, setOpenConsentModal] = useState(false);
  const [cptCodeNotes, setCptCodeNotes] = useState("");
  const [isHoveredConsent, setIsHoveredConsent] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [openCredsMessage, setOpenCredsMessage] = useState(false);
  const [overlapTimeEntries, setOverlapTimeEntries] = useState([]);
  const [openEvidenceReportModal, setOpenEvidenceReportModal] = useState(false);
  const [selectedMonthValue, setSelectedMonthValue] = useState("this_month");
  const [openAppStatusModal, setOpenAppStatusModal] = useState(false);
  const [editTimeObjectData, setEditTimeObjectData] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);
  const [exportReportError, setExportReportError] = useState(false);
  const [downloadReportDone, setDownloadReportDone] = useState(false);
  const [downloadReportMonth, setDownloadReportMonth] = useState(null);
  const [progressNum, setProgressNum] = useState(0);
  const [warningModalContent, setWarningModalContent] = useState(null);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [painLogsCheckedValue, setPainLogsCheckedValue] = useState(false);
  const [stepCountsCheckedValue, setStepCountsCheckedValue] = useState(false);
  const [DetailedChatCheckedValue, setDetailedChatCheckedValue] =
    useState(false);
  const [timeSheetCheckedValue, setTimeSheetCheckedValue] = useState(false);
  const [appointmentsCheckedValue, setAppointmentsCheckedValue] =
    useState(false);
  const [notesCheckedValue, setNotesCheckedValue] = useState(false);
  const [painRemindersCheckedValue, setPainRemindersCheckedValue] =
    useState(false);
  // const [nextPatientObject, setNextPatientObject] = useState(null);
  const [clickedNextPatient, setClickedNextPatient] = useState(false);
  const [trackConsentGivenClicked, setTrackConsentGivenClicked] =
    useState(false);

  const openCaseOverviewMenu = Boolean(anchorMenuEl);
  const [loadingPdfDoc, setLoadingPdfDoc] = useState(false);

  const dispatch = useDispatch();
  const caseDetailByCaseId = useSelector((state) => state.caseDetailByCaseId);
  const { loading, error, caseDetail } = caseDetailByCaseId;

  const timerModal = useSelector((state) => state.timerModal);
  const {
    openModal,
    startTimeValue,
    isRunning,
    isPaused,
    isStopped,
    elapsedTime,
    pausedTime,
    pausedStartTime,
    previousPage,
    initialStartTime,
  } = timerModal;

  const getAgentsList = useSelector((state) => state.getAgentsList);
  const { allAgents } = getAgentsList;

  const getNextPatient = useSelector((state) => state.getNextPatient);
  const { loading: loadingNextPatient, nextPatientData } = getNextPatient;

  const patientListingState = useSelector((state) => state.patientListingState);
  const {
    tab: activeTab,
    page,
    pageSize,
    assignedTo,
    enrollmentStatus,
    query,
    cptCodeItems,
    sortedItems,
    nextPatientObject,
  } = patientListingState;

  const addRecordConsent = useSelector((state) => state.addRecordConsent);
  const { loading: loadingConsent, consentRecorded } = addRecordConsent;

  const getCaseSummaryLink = useSelector((state) => state.getCaseSummaryLink);
  const {
    loading: loadingCaseSummaryLink,
    error: errorCaseSummaryLink,
    caseSummaryPdfLink,
  } = getCaseSummaryLink;

  const getPieChartStats = useSelector((state) => state.getPieChartStats);
  const {
    loading: loadingPieChartStats,
    error: errorPieChartStats,
    pieChartStats,
  } = getPieChartStats;

  const initCptSetup = useSelector((state) => state.initCptSetup);
  const {
    loading: loadingInitCptSetup,
    error: errorInitCptSetup,
    cptSetup,
  } = initCptSetup;

  const getCaseClientDocs = useSelector((state) => state.getCaseClientDocs);
  const {
    loading: loadingCaseClientDocs,
    error: errorCaseClientDocs,
    caseClientDocs,
  } = getCaseClientDocs;

  const getClientPainlogs = useSelector((state) => state.getClientPainlogs);
  const {
    loading: loadingPain,
    error: errorPain,
    painlogs,
  } = getClientPainlogs;

  const getMobileStatus = useSelector((state) => state.getMobileStatus);
  const {
    loading: loadingMobileStatus,
    error: errorMobileStatus,
    mobileStatus,
  } = getMobileStatus;

  const resendClientCredentials = useSelector(
    (state) => state.resendClientCredentials
  );
  const {
    loading: loadingCredentials,
    error: errorCredentials,
    clientCreds,
  } = resendClientCredentials;

  const allFolderAndFiles = caseClientDocs?.contents?.filter(
    (item) =>
      item?.type === "file" ||
      (item?.type === "folder" && item?.number_of_children > 0)
  );

  const updateCase = useSelector((state) => state.updateCase);
  const {
    loading: loadingUpdateCase,
    error: errorUpdateCase,
    updatedCase,
  } = updateCase;

  const createTimeEntry = useSelector((state) => state.createTimeEntry);
  const {
    loading: loadingCreateTimeEntry,
    error: errorCreateTimeEntry,
    createdTimeEntry,
  } = createTimeEntry;

  const updateTimeEntry = useSelector((state) => state.updateTimeEntry);
  const {
    loading: loadingUpdateTimeEntry,
    error: errorUpdateTimeEntry,
    updatedTimeEntry,
  } = updateTimeEntry;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAllBodyParts = useSelector((state) => state.getAllBodyParts);
  const {
    loading: loadingBodyParts,
    error: errorBodyParts,
    allBodyParts,
  } = getAllBodyParts;

  const removeCaseFromSubscription = useSelector(
    (state) => state.removeCaseFromSubscription
  );
  const {
    loading: loadingCaseFromSubsc,
    error: errorCaseFromSubsc,
    caseRemovedFromSubscription,
  } = removeCaseFromSubscription;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const changedAgencyId = useSelector((state) => state.changedAgencyId);
  const { changed } = changedAgencyId;

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "case_overview",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  const agentUserRef = useRef();

  useEffect(() => {
    agentUserRef.current = agentUser;
  }, [agentUser]);

  useEffect(() => {
    if (id) {
      setClickedNextPatient(false);
      dispatch(getCaseDetailByCaseId(id));
      dispatch(setNavigateItemFunc(null));
      const nextPatientObj = {
        agency_id: agencyId,
        patient_id: id,
        page: page + 1,
        pageSize: pageSize,
        search: query,
        assigned_to: assignedTo,
        enrollment_status: enrollmentStatus,
        cpt_codes: cptCodeItems,
        sort: getSortApiText(sortedItems),
        tab: activeTab,
      };
      dispatch(getNextPatientFunc(nextPatientObj));
      if (!allAgents) {
        dispatch(getAgentsListFunc());
      }
      dispatch(getMobileStatusFunc(id));
    }
  }, [navigate]);

  useEffect(() => {
    if (nextPatientData?.ok && clickedNextPatient) {
      navigate(`/patient/${nextPatientObject?.id}?tab=overview`);
    } else if (nextPatientData?.ok) {
      dispatch(setNextPatientObjData(nextPatientData?.next_patient));
      dispatch(resetNextPatient());
    }
  }, [nextPatientData]);

  useEffect(() => {
    if (mobileStatus?.ok) {
      const appStatus = calculateAppStatus(
        mobileStatus?.first_login,
        mobileStatus?.last_reading_time
      );

      setAppStatus(appStatus);
    }
  }, [mobileStatus]);

  useEffect(() => {
    if (id && caseDetail?.patient?.id) {
      setOpenConsentModal(!caseDetail?.patient?.initial_setup_done);

      setTosContentChecked(caseDetail?.patient?.tracking_consent_given);
      setInitSetupChecked(caseDetail?.patient?.equipment_setup);
      setInitSetup2Checked(caseDetail?.patient?.education_given);

      if (!caseDetail?.patient?.initial_setup_done) {
        dispatch(setIsRunning(false));
        dispatch(setIsPaused(false));
        dispatch(setIsStopped(true));
        dispatch(elapsedTimeFunc(0));
      }

      dispatch(getAllBodyPartsFunc(id));
      dispatch(getAgencyDetailFunc());
      setFromPdfDate(dayjs(caseDetail?.patient?.created_at));
      // setBillingActive(caseDetail?.patient?.added_to_subscription || false);
      // if (!isPaused && caseDetail?.patient?.initial_setup_done) {
      //   dispatch(setIsRunning(true));

      // }
      if (
        !isPaused &&
        caseDetail?.patient?.enrollment_status === "enrolled" &&
        caseDetail?.patient?.initial_setup_done
      ) {
        dispatch(setIsRunning(true));
        dispatch(setIsStopped(false));
        dispatch(setStartTime(dayjs()));
      } else {
        dispatch(setIsRunning(false));
      }
    }
  }, [id, caseDetail?.patient?.id]);

  useEffect(() => {
    if (caseDetail?.patient?.name)
      document.title = `VitaTrax | ${caseDetail?.patient?.name}`;
  }, [caseDetail]);

  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    searchParams.set("tab", newValue);
    navigate({ search: `?${searchParams.toString()}` });
    setTabValue(newValue);
  };

  useEffect(() => {
    if (selectedTab === "overview") {
      setTabValue("overview");
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "overview_tab",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    } else if (selectedTab === "chat") {
      setTabValue("chat");
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "chat_tab",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    } else if (selectedTab === "more_info") {
      setTabValue("more_info");
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "more_info_tab",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    } else if (selectedTab === "billing") {
      setTabValue("billing");
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "billing_tab",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
    // else {
    //   setTabValue("more_info");
    //   if (agentUser?.agent && agencyDetail?.agency) {
    //     segmentTrack({
    //       event: "info_tab",
    //       agent: agentUser?.agent,
    //       agency: agencyDetail?.agency,
    //     });
    //   }
    // }
  }, [selectedTab]);

  // useEffect(() => {
  //   if (updatedCase?.ok && changedCaseStatus) {
  //     setOpen(true);
  //     dispatch(getCaseDetailByCaseId(id));
  //   }
  // }, [updatedCase]);

  const openEditDrawerHandler = () => {
    const access =
      agentUserRef.current?.agent?.permissions?.access_patient_details?.edit;
    if (!access) {
      setPermissionMessage("You do not have permission to edit a patient!");
      setOpen(true);
    } else {
      setPermissionMessage(null);
      setFromCaseOverview(true);
      setOpenEditDrawer(true);

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "edit_case_clicked",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  // ==============================================================================
  // OPEN CONFIRM BILLING CANCEL MODAL
  const modalCloseHandler = () => {
    setOpenCancelBillingBox(false);
    setBillingActive(true);
  };

  // const cancelBillingSubscriptionHandler = () => {
  //   const caseObjId = {
  //     case_ID: caseDetail?.case?.id,
  //   };
  //   dispatch(removeCaseFromBillingSubscriptionFunc(caseObjId));
  // };

  useEffect(() => {
    if (caseRemovedFromSubscription?.ok) {
      setCaseAddedInPlan(false);
      setOpenCancelBillingBox(false);
      // dispatch(resetRemoveCaseToSubscription());
    }
  }, [caseRemovedFromSubscription]);

  const modalopenBillingCancelHandler = () => {
    setOpenCancelBillingBox(true);
  };

  const openBillingCancelModal = () => {
    setModalContentData({
      title: "Are you sure?",
      subtitle:
        "If you remove this case from your plan, access will be restricted. Are you sure you want to proceed?",
    });
    modalopenBillingCancelHandler();
  };

  // =====================================================================================

  const openMenuHandler = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const closeMenuHandler = () => {
    setAnchorMenuEl(null);
  };

  // OPEN DELETE CASE PROFILE MODAL
  const modalDeleteCaseOpenHandler = () => {
    setOpenDeleteCaseModal(true);
  };

  const modalDeleteCaseCloseHandler = () => {
    setOpenDeleteCaseModal(false);
    setOpenCaseSummaryDialogBox(false);
    // setFromPdfDate(null);
    // setToPdfDate(null);
    // setDocumentValue([]);
    setBodyPartsValue(allBodyPartsData);
    setCheckedEventLogs(true);
    setCheckedPainLogs(true);
    setDateFieldIsTouched(false);
  };

  // OPEN DELETE CASE CONFIRM MODAL
  const openDeleteCaseModalHandler = () => {
    setModalContentData({
      title: "Are you sure?",
      subtitle:
        "This will permanently delete the patient and all the data associated with it.",
    });
    modalDeleteCaseOpenHandler();
    setAnchorMenuEl(null);
  };

  // DELETING CASE HANDLER
  const deleteCaseHandler = () => {
    // const clientDeleteData = { client_id: clientData?.id };
    dispatch(deleteCaseFunc(id));
    // dispatch(deleteClientFunc(clientDeleteData));
    dispatch(setIsRunning(false));
    dispatch(setIsPaused(false));
    dispatch(setIsStopped(true));
    dispatch(elapsedTimeFunc(0));

    navigate(`/patients?tab=${activeTab}`);

    modalDeleteCaseCloseHandler();
  };

  // RESHARE CREDS
  const reshareCredsHandler = () => {
    const patientObj = {
      patient_id: id,
      agency_id: agencyId,
    };
    dispatch(resendClientCredentialsFunc(patientObj));
    setAnchorMenuEl(null);
  };

  const openEvidenceReportHandler = () => {
    setDownloadReportDone(false);
    setOpenEvidenceReportModal(true);
    setAnchorMenuEl(null);
  };

  useEffect(() => {
    if (clientCreds?.ok) {
      setOpenCredsMessage(true);
      dispatch(resetResendClientCredsState());
    }
  }, [clientCreds]);

  const openCaseSummaryDialogHandler = () => {
    setOpenCaseSummaryDialogBox(true);
    setModalContentData({
      title: "Client Summary Report",
      lightText:
        "Configure the report and export it according to your requirements",
    });
  };

  useEffect(() => {
    if (allBodyParts?.ok) {
      var pain_entries = allBodyParts?.pain_entries;
      var body_parts = [];

      for (const pain_entry of pain_entries) {
        if (pain_entry?.body_parts_data) {
          var body_parts_data = JSON.parse(pain_entry?.body_parts_data);
          if (body_parts_data && body_parts_data?.length) {
            body_parts?.push(
              ...body_parts_data?.map(
                (body_part) => `${body_part?.side} ${body_part?.name}`
              )
            );
          }
        }
      }

      body_parts = [...new Set(body_parts)].map(
        (bp) => bp.charAt(0).toUpperCase() + bp.slice(1)
      );
      setAllBodyPartsData(body_parts);
      setBodyPartsValue(body_parts);
      dispatch(resetAllBodyParts());
    }
  }, [allBodyParts]);

  // const generatePdf = async () => {
  //   setDateFieldIsTouched(true);
  //   setFromPdfButton(true);

  //   if (!fromPdfDate || !toPdfDate || !bodyPartsValue?.length) {
  //     return;
  //   }

  //   const startDate = fromPdfDate?.format("YYYY-MM-DD");
  //   const endDate = toPdfDate?.format("YYYY-MM-DD");
  //   const extractedDocumentValues = documentValue?.map((doc) => ({
  //     document_type: doc.type,
  //     id: doc.id,
  //   }));

  //   dispatch(
  //     getClientPainlogsFunc(
  //       id,
  //       caseDetail?.case?.client?.id,
  //       startDate,
  //       endDate,
  //       "all"
  //     )
  //   );

  //   dispatch(
  //     getPieChartStatsFunc(caseDetail?.case?.client?.id, id, startDate, endDate)
  //   );

  //   const config = {
  //     headers: {
  //       "Content-type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };

  //   setLoadingDocumentURLs(true);

  //   const documents_response = await axios.post(
  //     `${process.env.REACT_APP_BASE_URL}/storage/get-all-document-urls`,
  //     {
  //       client_id: caseDetail?.case?.client?.id,
  //       documents: extractedDocumentValues,
  //     },
  //     config
  //   );

  //   setDocumentsResponse(documents_response?.data);
  //   setLoadingDocumentURLs(false);

  //   setDateFieldIsTouched(false);
  // };

  // useEffect(() => {
  //   if (
  //     // fromPdfButton &&
  //     painlogs?.ok

  //     // documentsResponse?.ok
  //   ) {
  //     const formState = {
  //       startDate: fromPdfDate?.format("YYYY-MM-DD"),
  //       endDate: toPdfDate?.format("YYYY-MM-DD"),
  //       bodyParts: bodyPartsValue,
  //       showPainLogsTable: checkedPainLogs,
  //     };

  //     const localStorageState = {
  //       caseDetail: caseDetail,
  //       formState: formState,
  //       intakeQuestionsAnswers: allQuestionsAnswers?.intake_answers,
  //       painLogs: painlogs?.pain_entries,
  //       pieChartStatsState: pieChartStats?.report_stats,
  //       document_urls: documentsResponse?.document_urls,
  //     };
  //     localStorage.setItem(
  //       "newTabPageState",
  //       JSON.stringify(localStorageState)
  //     );

  //     const url = `/client-summary-report/${caseDetail?.case?.id}`;
  //     const newTab = window.open(url, "_blank");

  //     newTab.onload = () => {
  //       newTab.localStorage.removeItem("newTabPageState");
  //     };

  //     // Reset states
  //     setFromPdfButton(false);
  //     setOpenCaseSummaryDialogBox(false);
  //     setFromPdfDate(null);
  //     setToPdfDate(null);
  //     setDocumentValue([]);
  //     setBodyPartsValue(allBodyPartsData);
  //     setCheckedEventLogs(true);
  //     setCheckedPainLogs(true);
  //     setDateFieldIsTouched(false);
  //   }
  // }, [painlogs, pieChartStats, documentsResponse]);

  const billingActiveHandler = (e) => {
    setBillingActive(e.target.checked);
    if (e.target.checked) {
      setShowBillingPlan(true);
    } else {
      openBillingCancelModal();
      setBillingActive(false);
    }
  };

  // ************** TIMER FUNCTIONS START **************************************************************

  const openStopTimeModalHandler = () => {
    dispatch(setIsStopped(false));
    dispatch(setGoBackFunc(false));
    dispatch(setGoNextPatientFunc(false));
    setModalContentData({
      title: "End Session",
    });

    if (!isPaused) {
      dispatch(setIsRunning(true));
      dispatch(setPauseStartTime(null));
      dispatch(setPausedTime(0));
    }
    const initialTime = initialStartTime || startTimeValue;
    dispatch(setStartTime(initialTime));

    dispatch(setEndTime(dayjs()));
    dispatch(openTimeModal());
    setSelectValue("");
  };

  const pauseTimerHandler = () => {
    dispatch(setIsRunning(false));
    dispatch(setIsPaused(true));
    dispatch(setPauseStartTime(null));
    dispatch(setPausedTime(0));

    // setEndTimeValue(dayjs());
    // clearInterval(intervalRef.current);
  };

  const playTimeHandler = () => {
    dispatch(setPauseStartTime(null));
    dispatch(setIsRunning(true));
    dispatch(setIsPaused(false));
    dispatch(setIsStopped(false));
    if (!isRunning && !isPaused && isStopped) {
      dispatch(setStartTime(dayjs()));
    }
  };

  useEffect(() => {
    if (isRunning) {
      const start = Date.now() - elapsedTime;
      intervalRef.current = setInterval(() => {
        dispatch(elapsedTimeFunc(Date.now() - start));
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [isRunning]);

  const handleBeforeUnload = (event) => {
    if (isPaused || isRunning) {
      event.preventDefault();
      event.returnValue = "";
      dispatch(setIsRunning(false));
      dispatch(setPauseStartTime(Date.now()));
      dispatch(setPausedTime(0));
      dispatch(setGoBackFunc(true));
    }

    setModalContentData({
      title: "End Session",
    });
    setSelectValue("");
    dispatch(openTimeModal());
  };

  useEffect(() => {
    if (isRunning || isPaused) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch, isStopped, isRunning, isPaused]);

  const handlePopState = (e) => {
    e.preventDefault();
    e.returnValue = "";

    handleBeforeUnload(e);
    if (isRunning || isPaused) {
      window.history.pushState(null, null, window.location.pathname);
    }
  };

  useEffect(() => {
    if (isRunning || isPaused) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", handlePopState);
    }
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [dispatch, isRunning, isPaused, openModal]);

  // ************** TIMER FUNCTIONS END **************************************************************

  const onSubmitAddTimeEntryHandler = (timeEntryObj) => {
    dispatch(createTimeEntryFunc(timeEntryObj));
  };

  useEffect(() => {
    if (createdTimeEntry?.ok) {
      // dispatch(resetCreateTimeEntry());
      setOpenDrawer(false);
      setSuccessMessageContent("Time log added successfully");
      setShowSuccessMessage(true);
      // dispatch(
      //   getTimeEntryFunc(
      //     caseDetail?.patient?.id,
      //     dayjs().subtract(31, "day").format("YYYY-MM-DD"),
      //     dayjs().format("YYYY-MM-DD")
      //   )
      // );
    }
    // else if (errorCreateTimeEntry?.message) {
    //   setShowErrorMessage(true);
    //   setOverlapTimeEntries(errorCreateTimeEntry?.overlapEntries);
    // }
  }, [createdTimeEntry, errorCreateTimeEntry]);

  const onSubmitEditTimeHandler = (timeEntryObj) => {
    setEditTimeObjectData(timeEntryObj);
    dispatch(updateTimeEntryFunc(timeEntryObj));

    // if (timeDetails?.cpt_codes?.length) {
    //   setOpenWarningModal(true);
    // } else {
    //   dispatch(updateTimeEntryFunc(timeEntryObj));
    // }
  };

  useEffect(() => {
    if (updatedTimeEntry?.ok) {
      // dispatch(resetUpdateTimeEntry());
      setOpenDrawer(false);
      setOpenWarningModal(false);

      // getTimeEntryDetails();
      // dispatch(
      //   getTimeEntryFunc(
      //     caseDetail?.patient?.id,
      //     dayjs().subtract(31, "day").format("YYYY-MM-DD"),
      //     dayjs().format("YYYY-MM-DD")
      //   )
      // );
      setSuccessMessageContent("Time log edited successfully");
      setShowSuccessMessage(true);
    } else if (errorUpdateTimeEntry?.message) {
      setShowErrorMessage(true);
      setOpenWarningModal(false);
      setOverlapTimeEntries(errorUpdateTimeEntry?.overlapEntries);
      // dispatch(resetUpdateTimeEntry());
    }
  }, [updatedTimeEntry, errorUpdateTimeEntry]);

  const activatePatientHandler = () => {
    if (caseDetail?.patient?.tracking_consent_given) {
      const cptCodeObj = {
        patient_id: caseDetail?.patient?.id,
        agency_id: agencyId,
        initial_setup_notes: cptCodeNotes,
        tracking_consent_given: true,
      };

      dispatch(initCptSetupFunc(cptCodeObj));
    } else {
      const recordObj = {
        patient_id: caseDetail?.patient?.id,
      };
      dispatch(addRecordConsentFunc(recordObj));
    }
  };

  const closeConsentModalHandler = () => {
    dispatch(setIsRunning(false));
    dispatch(setIsPaused(false));
    dispatch(setIsStopped(true));
    dispatch(elapsedTimeFunc(0));
    if (cptCodeNotes) {
      const notesObj = {
        patient_id: caseDetail?.patient?.id,
        agency_id: agencyId,
        note: cptCodeNotes,
      };

      dispatch(createCustomNotesFunc(notesObj));
    }

    if (!caseDetail?.patient?.tracking_consent_given && tosConsentChecked) {
      setTrackConsentGivenClicked(true);
      const recordObj = {
        patient_id: caseDetail?.patient?.id,
      };
      dispatch(addRecordConsentFunc(recordObj));
    } else if (!caseDetail?.patient?.equipment_setup && initSetupChecked) {
      // EQUIP SETUP CHECKED
      const recordObj = {
        patient_id: caseDetail?.patient?.id,
      };
      dispatch(addEquipSetupFunc(recordObj));
      setOpenConsentModal(false);
      navigate(`/patients?tab=${activeTab}`);
    } else if (!caseDetail?.patient?.education_given && initSetup2Checked) {
      // EDUCATION DONE CHECKED
      const recordObj = {
        patient_id: caseDetail?.patient?.id,
      };
      dispatch(addEducationSetupFunc(recordObj));
      setOpenConsentModal(false);
      navigate(`/patients?tab=${activeTab}`);
    } else {
      setOpenConsentModal(false);
      navigate(`/patients?tab=${activeTab}`);
    }
  };

  useEffect(() => {
    if (consentRecorded?.ok && trackConsentGivenClicked) {
      dispatch(resetRecordConsent());
      setOpenConsentModal(false);
      navigate(`/patients?tab=${activeTab}`);
      setTrackConsentGivenClicked(false);
    } else if (consentRecorded?.ok) {
      const cptCodeObj = {
        patient_id: caseDetail?.patient?.id,
        agency_id: agencyId,
        initial_setup_notes: cptCodeNotes,
        tracking_consent_given: true,
      };

      dispatch(initCptSetupFunc(cptCodeObj));
      dispatch(resetRecordConsent());
    }
  }, [consentRecorded]);

  useEffect(() => {
    if (cptSetup?.ok) {
      setOpenConsentModal(false);
      dispatch(getCaseDetailByCaseId(id));
      dispatch(resetInitCptSetup());
    }
  }, [cptSetup]);

  const openAppStatusHandler = () => {
    setOpenAppStatusModal(true);
  };

  const exportEvidenceReportHandler = async () => {
    setExportReportError(false);
    setOpenEvidenceReportModal(false);
    setLoadingReport(true);
    setProgressNum(0);

    const { startDate, endDate } = getEvidenceDownloadDateRanges(
      selectedMonthValue,
      caseDetail?.patient?.initial_setup_done_at
    );

    const getReportMonth = dayjs(startDate).format("MMMM");
    setDownloadReportMonth(getReportMonth);

    const reportObj = {
      patient_id: caseDetail?.patient?.id,
      start_date: startDate,
      end_date: endDate,
      include_pain_logs: painLogsCheckedValue,
      include_step_counts: stepCountsCheckedValue,
      include_chat: DetailedChatCheckedValue,
      include_timesheet: timeSheetCheckedValue,
      include_appointments: appointmentsCheckedValue,
      include_notes: notesCheckedValue,
      include_reminders: painRemindersCheckedValue,
    };

    let progressInterval = null;

    try {
      let timeElapsed = 0;
      progressInterval = setInterval(() => {
        timeElapsed += 2000;

        if (timeElapsed <= 10000) {
          setProgressNum((prev) => prev + 10);
        } else {
          setProgressNum(50);
          clearInterval(progressInterval);
        }
      }, 2000);

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        };

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/patient/generate-eoc`,
          reportObj,
          config
        );

        if (response?.status !== 200) {
          throw new Error("Failed to generate PDF report");
        }
        //

        clearInterval(progressInterval);
        setProgressNum(100);

        setTimeout(() => {
          const blob = response.data;
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          setLoadingReport(false);
          setDownloadReportDone(true);
          link.href = url;
          link.setAttribute(
            "download",
            `${caseDetail?.patient?.name} - ${getReportMonth} - Evidence of Care.pdf`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setSelectedMonthValue("this_month");
          setPainLogsCheckedValue(false);
          setStepCountsCheckedValue(false);
          setDetailedChatCheckedValue(false);
          setTimeSheetCheckedValue(false);
          setAppointmentsCheckedValue(false);
          setNotesCheckedValue(false);
          setPainRemindersCheckedValue(false);
        }, 500);
      }
    } catch (error) {
      setLoadingReport(false);
      setExportReportError(true);
      clearInterval(progressInterval);
      console.log(
        "ERROR",
        error,
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

  return (
    <>
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      <NetworkStatusBanner />

      {openAppStatusModal && (
        <AlertDialog
          data={{
            title:
              caseDetail?.patient?.enrollment_status === "enrolled" ||
              caseDetail?.patient?.enrollment_status === "pending"
                ? `Mobile App ${
                    appStatus === "inactive" || appStatus === "pending"
                      ? "Inactive"
                      : appStatus === "active" &&
                        !mobileStatus?.ever_pain_logged
                      ? "Inactive"
                      : "Active"
                  }`
                : "Mobile App Access Revoked",
          }}
          openModal={openAppStatusModal}
          dialogActions={false}
          // isLoading={loadingCreateTimeEntry}
          closeModal={() => {
            setOpenAppStatusModal(false);
          }}
          moveFileDialog={true}
          submitText="Save"
          maxWidth="521px"
          titlePadding="16px 24px 0 24px !important"
          titlePaddingTop="16px"
          showCancelIcon={true}
          resetTime={true}
          closeOverlay={false}
          ever_pain_logged={mobileStatus?.ever_pain_logged}
          enrollment_status={caseDetail?.patient?.enrollment_status}
        >
          {phoneStatusContent(
            appStatus,
            mobileStatus?.ever_pain_logged,
            caseDetail?.patient?.enrollment_status,
            caseDetail?.patient?.id,
            caseDetail?.patient?.name,
            caseDetail?.patient?.contact_number,
            caseDetail?.patient?.email,
            mobileStatus?.first_login,
            mobileStatus?.last_app_opened,
            mobileStatus?.app_version,
            mobileStatus?.os_version
          )}
          {/* {appStatus === "pending" ||
          caseDetail?.patient?.enrollment_status === "unenrolled" ? (
            <PendingIconContent
              is_enrolled={
                caseDetail?.patient?.enrollment_status === "enrolled" ||
                caseDetail?.patient?.enrollment_status === "pending"
              }
              patientName={caseDetail?.patient?.name}
              patientId={caseDetail?.patient?.id}
              patientContactNumber={caseDetail?.patient?.contact_number}
              patientEmail={caseDetail?.patient?.email}
            />
          ) : appStatus === "inactive" ? (
            <InactiveIconContent
              patientName={caseDetail?.patient?.name}
              patientId={caseDetail?.patient?.id}
              patientContactNumber={caseDetail?.patient?.contact_number}
              patientEmail={caseDetail?.patient?.email}
              firstLogin={mobileStatus?.first_login}
              lastAppOpened={mobileStatus?.last_app_opened}
              appVersion={mobileStatus?.app_version}
              osVersion={mobileStatus?.os_version}
            />
          ) : (
            <ActiveIconContent
              firstLogin={mobileStatus?.first_login}
              lastAppOpened={mobileStatus?.last_app_opened}
              appVersion={mobileStatus?.app_version}
              osVersion={mobileStatus?.os_version}
            />
          )} */}
        </AlertDialog>
      )}

      {!loading && permissionMessage && (
        <AlertMessage
          message={permissionMessage}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {(loadingReport || downloadReportDone || exportReportError) && (
        <DownloadReportStatus
          monthName={downloadReportMonth}
          downloadReportDone={downloadReportDone}
          setDownloadReportDone={setDownloadReportDone}
          exportReportError={exportReportError}
          setExportReportError={setExportReportError}
          progressNum={progressNum}
          retryExportHandler={exportEvidenceReportHandler}
        />
      )}

      {openEvidenceReportModal && (
        <AlertDialog
          //  isLoading={loadingReport}
          // disabled={!radioValue}
          openModal={openEvidenceReportModal}
          closeModal={() => {
            setOpenEvidenceReportModal(false);
            setSelectedMonthValue("this_month");
            setPainLogsCheckedValue(false);
            setStepCountsCheckedValue(false);
            setDetailedChatCheckedValue(false);
            setTimeSheetCheckedValue(false);
            setAppointmentsCheckedValue(false);
            setNotesCheckedValue(false);
            setPainRemindersCheckedValue(false);
          }}
          data={{ title: "Export Evidence of care." }}
          submitText="Generate"
          padding="24px !important"
          titlePaddingTop="24px !important"
          maxWidth="538px"
          cancelColor="primary"
          contentPadding="24px 24px 0 !important"
          contentPaddingTop="24px !important"
          closeOverlay={false}
          formSubmitFunction={exportEvidenceReportHandler}
        >
          <ExportEvidenceReportContent
            selectedMonthValue={selectedMonthValue}
            setSelectedMonthValue={setSelectedMonthValue}
            enrolledOn={caseDetail?.patient?.initial_setup_done_at}
            painLogsCheckedValue={painLogsCheckedValue}
            setPainLogsCheckedValue={setPainLogsCheckedValue}
            stepCountsCheckedValue={stepCountsCheckedValue}
            setStepCountsCheckedValue={setStepCountsCheckedValue}
            DetailedChatCheckedValue={DetailedChatCheckedValue}
            setDetailedChatCheckedValue={setDetailedChatCheckedValue}
            timeSheetCheckedValue={timeSheetCheckedValue}
            setTimeSheetCheckedValue={setTimeSheetCheckedValue}
            appointmentsCheckedValue={appointmentsCheckedValue}
            setAppointmentsCheckedValue={setAppointmentsCheckedValue}
            notesCheckedValue={notesCheckedValue}
            setNotesCheckedValue={setNotesCheckedValue}
            painRemindersCheckedValue={painRemindersCheckedValue}
            setPainRemindersCheckedValue={setPainRemindersCheckedValue}
          />
        </AlertDialog>
      )}

      {/* OPEN SNACKBAR DATA  */}
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={closeSnackbarHandler}
          anchorOrigin={{
            vertical: snackbarData.vertical,
            horizontal: snackbarData.horizontal,
          }}
        >
          <Alert
            onClose={closeSnackbarHandler}
            severity={snackbarData.severity}
            color={snackbarData.color}
            sx={{ width: "100%" }}
          >
            {snackbarData.textContent}
          </Alert>
        </Snackbar>
      )}

      {showErrorMessage && (
        <AlertMessage
          width="682px"
          severity="error"
          open={true}
          autoHideDurationTime={6000}
          handleClose={() => setShowErrorMessage(false)}
          textAlign="normal"
        >
          <Stack gap="14px">
            <Stack>
              <Typography variant="body1" fontWeight={500} color="#5F2120">
                Session can't be saved
              </Typography>
              <Typography variant="body2" color="#5F2120">
                {errorUpdateTimeEntry?.message}{" "}
              </Typography>
            </Stack>
            <Stack
              gap="8px"
              height={overlapTimeEntries?.length <= 3 ? "auto" : "190px"}
              overflow="auto"
            >
              {overlapTimeEntries?.map((item, index) => (
                <OverlapTimeEntryCard
                  key={index}
                  heading={item?.category}
                  notesValue={item?.notes}
                  startTime={item?.start_time}
                  endTime={item?.end_time}
                />
              ))}
            </Stack>
          </Stack>{" "}
        </AlertMessage>
      )}

      <AlertDialog
        data={{
          title: `Enroll Patient`,
          lightText: "Please Enroll the patient before you proceed.",
        }}
        openModal={openConsentModal && !loading}
        loadingConsent={loading}
        isLoading={loadingInitCptSetup || loadingConsent}
        // showDiscardTimeEntryModalHandler={showDiscardTimeEntryModalHandler}
        closeModal={closeConsentModalHandler}
        // moveFileDialog={true}
        formSubmitFunction={activatePatientHandler}
        submitText="Enroll"
        disabled={!tosConsentChecked || !initSetupChecked || !initSetup2Checked}
        // cancelText={goBackClicked ? "Discard and Leave" : "Discard"}
        maxWidth="528px"
        height="806px"
        padding="0 24px 24px"
        contentPaddingTop="24px !important"
        closeOverlay={false}
        cancelColor="primary"
        showAppIcon={true}
        appStatus={appStatus}
        showCancelIcon={false}
        cancelText="Save changes and close"
        openAppModalHandler={openAppStatusHandler}
        // resetTime={true}
      >
        <ConsentScreenContent
          patientDetail={caseDetail}
          initSetupChecked={initSetupChecked}
          setInitSetupChecked={setInitSetupChecked}
          initSetup2Checked={initSetup2Checked}
          setInitSetup2Checked={setInitSetup2Checked}
          tosConsentChecked={tosConsentChecked}
          setTosContentChecked={setTosContentChecked}
          cptCodeNotes={cptCodeNotes}
          setCptCodeNotes={setCptCodeNotes}
          appStatus={appStatus}
        />
      </AlertDialog>

      {openCredsMessage && (
        <AlertMessage
          width="448px"
          message={
            caseDetail?.patient?.email && caseDetail?.patient?.contact_number
              ? `${caseDetail?.patient?.name}'s login credentials have been sent to email address (${caseDetail?.patient?.email}) and phone number (${caseDetail?.patient?.contact_number})`
              : caseDetail?.patient?.email
              ? `${caseDetail?.patient?.name}'s login credentials have been sent to email address : ${caseDetail?.patient?.email}`
              : `${caseDetail?.patient?.name}'s login credentials have been sent to phone number : ${caseDetail?.patient?.contact_number}`
          }
          severity="success"
          open={openCredsMessage}
          handleClose={() => setOpenCredsMessage(false)}
          textAlign="normal"
          autoHideDurationTime={8000}
        />
      )}

      {!loading && changedCaseStatus && (
        <AlertMessage
          message="Case status updated"
          severity="success"
          open={open}
          handleClose={handleClose}
        />
      )}

      {!loading && changedCaseValue && (
        <AlertMessage
          message="Case value updated"
          severity="success"
          open={open}
          handleClose={handleClose}
        />
      )}

      {/* ALERT DELETE CASE MODAL */}
      {openDeleteCaseModal && (
        <AlertDialog
          submitID="confirm-delete-case"
          openModal={openDeleteCaseModal}
          closeModal={modalDeleteCaseCloseHandler}
          data={modalContentData}
          formSubmitFunction={deleteCaseHandler}
          color="error"
        />
      )}

      {/* EDIT CASE DRAWER */}
      {openEditDrawer && (
        <EditCaseDrawer
          anchor={"right"}
          openEditCase={openEditDrawer}
          caseDetail={caseDetail}
          setOpenEditCase={setOpenEditDrawer}
          selectedRowCaseId={id}
          fromCaseOverview={fromCaseOverview}
          // open={open}
          // setOpen={setOpen}
          onClose={() => setOpenEditDrawer(false)}
        />
      )}

      <CptDrawer
        openDrawer={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor={"right"}
        setOpenDrawer={setOpenDrawer}
        // cptCodeDetail={cptCodeDetail}
        patientDetail={caseDetail}
        timeDetails={timeDetails}
        drawerType={"add_time"}
        onSubmitEditTime={onSubmitEditTimeHandler}
        onSubmitAddTimeEntry={onSubmitAddTimeEntryHandler}
      />

      <AlertMessage
        message={successMessageContent}
        severity="success"
        open={showSuccessMessage}
        handleClose={() => setShowSuccessMessage(false)}
      />

      <Stack
        alignItems="flex-start"
        gap="18px"
        padding="20px 40px"
        paddingBottom="40px"
      >
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Button
            variant="text"
            disableRipple
            disableFocusRipple
            startIcon={
              <Paper
                sx={{
                  background: "#fff",
                  boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "1px",
                  "& svg": {
                    fill: "#566875",
                  },
                }}
              >
                <ChevronLeftRoundedIcon />
              </Paper>
            }
            color="secondary"
            sx={{
              textTransform: "capitalize",
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
            onClick={() => {
              dispatch(resetAllCases());
              dispatch(resetAllBodyParts());
              if (!isRunning && !isPaused) {
                navigate(`/patients?tab=${activeTab}`);
              } else {
                openStopTimeModalHandler();
                dispatch(setGoBackFunc(true));
              }
            }}
          >
            Back to listing
          </Button>
          {loadingNextPatient || loading ? (
            <Box width="30%">
              <RectangleSkeletons totalRows={1} height={27} width="100%" />
            </Box>
          ) : (
            nextPatientObject?.first_name && (
              <Button
                variant="text"
                disableRipple
                disableFocusRipple
                endIcon={
                  <Paper
                    sx={{
                      background: "#fff",
                      boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "1px",
                      "& svg": {
                        fill: "#566875",
                      },
                    }}
                  >
                    <ChevronRightRoundedIcon />
                  </Paper>
                }
                color="secondary"
                sx={{
                  textTransform: "capitalize",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "transparent",
                  },
                }}
                onClick={() => {
                  dispatch(resetAllCases());
                  dispatch(resetAllBodyParts());
                  if (!isRunning && !isPaused) {
                    const nextPatientObj = {
                      agency_id: agencyId,
                      patient_id: caseDetail?.patient?.id,
                      page: page + 1,
                      pageSize: pageSize,
                      search: query,
                      assigned_to: assignedTo,
                      enrollment_status: enrollmentStatus,
                      cpt_codes: cptCodeItems,
                      sort: getSortApiText(sortedItems),
                      tab: activeTab,
                    };
                    dispatch(getNextPatientFunc(nextPatientObj));
                    setClickedNextPatient(true);
                  } else {
                    openStopTimeModalHandler();
                    dispatch(setGoNextPatientFunc(true));
                  }
                }}
              >
                Next Patient :{" "}
                {`${nextPatientObject?.first_name} ${nextPatientObject?.last_name}`}
              </Button>
            )
          )}
        </Box>

        {/* CASE OVERVIEW HEADER */}
        <Box
          boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
          bgcolor="#fff"
          borderRadius="8px"
          overflow="hidden"
          gap="24px"
          width="100%"
          position="sticky"
          top="82px"
          zIndex="99"
          // className={`${
          //   isSticky ? { position: "sticky", top: "82px", zIndex: "1" } : ""
          // }`}
        >
          {/* SUB HEADER SECTION */}
          <Stack gap="12px">
            <Stack
              sx={{
                height: loading ? "128px" : "auto",
                padding: "24px 32px 12px",
                background:
                  caseDetail?.patient?.enrollment_status === "enrolled" ||
                  caseDetail?.patient?.enrollment_status === "pending" ||
                  loading
                    ? "radial-gradient(85.47% 105.71% at 15.61% 87.28%, rgba(128, 12, 243, 0.09) 0%, rgba(12, 128, 243, 0.09) 100%)"
                    : "#FFF4E5",
              }}
            >
              {loading ? (
                <CaseHeaderSkeleton />
              ) : (
                <Box
                  display="flex"
                  // alignItems="flex-s"
                  justifyContent="space-between"
                >
                  <Stack alignItems="flex-start" gap="8px">
                    <Box display="flex" gap="10px">
                      {caseDetail?.patient?.profile_picture_url ? (
                        <Avatar
                          src={caseDetail?.patient?.profile_picture_url}
                          alt={caseDetail?.patient?.name}
                        />
                      ) : (
                        <Avatar
                          {...stringAvatar(caseDetail?.patient?.name)}
                          sx={{
                            backgroundColor: caseDetail?.patient?.avatar_color,
                          }}
                        />
                      )}
                      <Stack>
                        <Box display="flex" alignItems="center" gap="8px">
                          <HeaderText
                            textContent={caseDetail?.patient?.name}
                            variant="h1"
                            fontSize="28px"
                          />
                          <IconButton onClick={openAppStatusHandler}>
                            {showPhoneStatusColors(
                              appStatus,
                              mobileStatus?.ever_pain_logged,
                              caseDetail?.patient?.enrollment_status
                            )}
                          </IconButton>
                        </Box>

                        {(caseDetail?.patient?.enrollment_status ===
                          "enrolled" ||
                          caseDetail?.patient?.enrollment_status ===
                            "pending") &&
                        caseDetail?.patient?.diagnostic_codes?.length ? (
                          <Typography
                            variant="body1"
                            color="secondary"
                          >{`Diagnosis Code:  ${
                            caseDetail?.patient?.diagnostic_codes?.length > 1
                              ? caseDetail?.patient?.diagnostic_codes?.map(
                                  (item) => ` ${item?.code}`
                                )
                              : `${
                                  caseDetail?.patient?.diagnostic_codes[0]?.code
                                }${
                                  caseDetail?.patient?.diagnostic_codes[0]
                                    ?.description
                                    ? ` - ${caseDetail?.patient?.diagnostic_codes[0]?.description}`
                                    : ""
                                }`
                          }`}</Typography>
                        ) : (
                          <></>
                        )}
                      </Stack>

                      {/* {caseDetail?.case?.case_origin === "clio" && ( // Assuming you have a similar property to check
                        <Chip
                          label="Synced with Clio"
                          size="small"
                          sx={{
                            fontSize: "0.75rem",
                            height: "auto",
                            padding: "2px 4px",
                            backgroundColor: "#e0e0e0",
                            color: "#000000",
                            borderRadius: "16px",
                          }}
                        />
                      )} */}
                    </Box>
                  </Stack>

                  <Stack gap="8px">
                    {caseDetail?.patient?.enrollment_status === "enrolled" ||
                    caseDetail?.patient?.enrollment_status === "pending" ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="8px"
                      >
                        <Typography
                          variant="body1"
                          color="#4B7DAE"
                          fontSize="29px"
                        >
                          {formatElapsedTime(elapsedTime)}
                        </Typography>
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          sx={{
                            height: "17px",
                          }}
                        />
                        {!isRunning && !isPaused && !isStopped && (
                          <Box display="flex" alignItems="center" gap="8px">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              bgcolor="#F87E4A"
                              padding="8px"
                              borderRadius="6px"
                              sx={{ cursor: "pointer" }}
                              onClick={pauseTimerHandler}
                            >
                              <PauseIcon />
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              bgcolor="#ED5C5C"
                              padding="8px"
                              borderRadius="6px"
                              sx={{ cursor: "pointer" }}
                              onClick={openStopTimeModalHandler}
                            >
                              <StopIcon />
                            </Box>
                          </Box>
                        )}
                        {isStopped && !isRunning && !isPaused && (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            bgcolor="#3DC583"
                            padding="8px"
                            borderRadius="6px"
                            sx={{ cursor: "pointer" }}
                            onClick={playTimeHandler}
                          >
                            <PlayIcon />
                          </Box>
                        )}
                        {isRunning && !isPaused && (
                          <Box display="flex" alignItems="center" gap="8px">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              bgcolor="#F87E4A"
                              padding="8px"
                              borderRadius="6px"
                              sx={{ cursor: "pointer" }}
                              onClick={pauseTimerHandler}
                            >
                              <PauseIcon />
                            </Box>

                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              bgcolor="#ED5C5C"
                              padding="8px"
                              borderRadius="6px"
                              sx={{ cursor: "pointer" }}
                              onClick={openStopTimeModalHandler}
                            >
                              <StopIcon />
                            </Box>
                          </Box>
                        )}

                        {!isRunning && isPaused && (
                          <Box display="flex" alignItems="center" gap="8px">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              bgcolor="#3DC583"
                              padding="8px"
                              borderRadius="6px"
                              sx={{ cursor: "pointer" }}
                              onClick={playTimeHandler}
                            >
                              <PlayIcon />
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              bgcolor="#ED5C5C"
                              padding="8px"
                              borderRadius="6px"
                              sx={{ cursor: "pointer" }}
                              onClick={openStopTimeModalHandler}
                            >
                              <StopIcon />
                            </Box>
                          </Box>
                        )}

                        <Divider
                          orientation="vertical"
                          variant="middle"
                          sx={{
                            height: "17px",
                          }}
                        />
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          bgcolor="#0C80F3"
                          padding="8px"
                          borderRadius="6px"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setOpenDrawer(true);
                            setTimeDetails(dayjs());
                          }}
                        >
                          <AddRoundedIcon
                            sx={{
                              width: "14px",
                              height: "14px",
                              color: "#fff",
                            }}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Alert
                        sx={{ alignItems: "center" }}
                        icon={<WarningUnenrollIcon />}
                        severity="warning"
                        // sx={{ "& .MuiAlert-message": { py: 1 } }}
                      >
                        <Typography variant="body2">
                          {caseDetail?.patient?.name} has been un-enrolled from
                          the RTM program.
                        </Typography>
                      </Alert>
                      // <Alert severity="warning">
                      //   {caseDetail?.patient?.name} has been un-enrolled from
                      //   the RTM program.
                      // </Alert>
                    )}

                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      gap="16px"
                    >
                      <IconButton
                        // sx={{ padding: "0", paddingLeft: "8px" }}
                        onClick={openEditDrawerHandler}
                        id="edit-case"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        sx={{ padding: "0" }}
                        onClick={openMenuHandler}
                        id="case-overview-menu"
                        aria-controls={
                          openCaseOverviewMenu
                            ? "case-overview-menu"
                            : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                          openCaseOverviewMenu ? "true" : undefined
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="case-overview-menu"
                        anchorEl={anchorMenuEl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={openCaseOverviewMenu}
                        onClose={closeMenuHandler}
                        MenuListProps={{
                          "aria-labelledby": "case-overview-button",
                        }}
                      >
                        <MenuItem
                          id="delete-case"
                          onClick={openEvidenceReportHandler}
                        >
                          Export Evidence of care
                        </MenuItem>
                        {caseDetail?.patient?.enrollment_status ===
                          "enrolled" && (
                          <MenuItem
                            id="delete-case"
                            onClick={reshareCredsHandler}
                          >
                            Reshare Credentials
                          </MenuItem>
                        )}
                        <MenuItem
                          id="delete-case"
                          onClick={openDeleteCaseModalHandler}
                          sx={{ color: "#FF2D2D" }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Stack>
                </Box>
              )}
            </Stack>

            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              gap="24px"
              padding="0 32px"
              alignSelf="flex-end"
            >
              {/* CASE HEALTH */}
              {/* <Box display="flex" alignItems="center" gap="8px">
                  <HeaderText
                    fontSize="16px"
                    textContent="Case Health :"
                    variant="h6"
                    fontWeight="400"
                  />
                  <Box
                    height="20px"
                    width="350px"
                    borderRadius="24px"
                    boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset"
                    padding="4px"
                    position="relative"
                  >
                    <LinearProgressbar />
                    <Typography
                      position="absolute"
                      right="7px"
                      top="2px"
                      fontSize="12px"
                      color="#fff"
                    >
                      100%
                    </Typography>
                  </Box>
                </Box> */}

              {/* {loading ? (
                <RectangleSkeletons totalRows={1} height={27} />
              ) : (
                <Box display="flex" justifyContent="space-between" gap="24px">
                  <Box display="flex" alignItems="center" gap="11px">
                    <Typography fontSize="16px" variant="h6" fontWeight="400">
                      Priority :
                    </Typography>

                    <FormControl>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={caseValue}
                        IconComponent={ExpandCircleDownIcon}
                        size="small"
                        sx={{
                          fontSize: "13px",
                          fontWeight: 500,
                          padding: "0 !Important",
                          color: `${caseValueColor(caseValue)} !important`,
                          borderRadius: "100px !important",
                          background: `${caseValueBgColor(
                            caseValue
                          )} !important`,
                          "&:before": {
                            borderColor: "white !important",
                          },
                          "&:after": {
                            borderColor: "white !important",
                          },
                          boxShadow: "none !important",
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "0  !important",
                          },
                          ".MuiSelect-outlined": {
                            padding: "4px 0",
                            paddingRight: "0 !important",
                            paddingLeft: "10px",
                            width: "91px",
                          },

                          ".MuiSvgIcon-root ": {
                            width: "20px",
                            fill: `${caseValueColor(caseValue)} !important`,
                          },
                        }}
                        onChange={caseValueChangeHandler}
                      >
                        <MenuItem value="high">High</MenuItem>
                        <MenuItem value="moderate">Moderate</MenuItem>
                        <MenuItem value="low">Low</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box display="flex" alignItems="center" gap="11px">
                    <Typography fontSize="16px" variant="h6" fontWeight="400">
                      Case Status :
                    </Typography>
                    <FormControl>
                      <Select
                        labelId="case-status"
                        id="case-status"
                        value={caseStatus}
                        IconComponent={ExpandCircleDownIcon}
                        size="small"
                        sx={{
                          fontSize: "13px",
                          fontWeight: 600,
                          marginRight: "10px !important",
                          color: `${caseStatusColor(caseStatus)} !important`,
                          borderRadius: "100px !important",
                          background: `${caseStatusBgColor(
                            caseStatus
                          )} !important`,
                          "&:before": {
                            borderColor: "white !important",
                          },
                          "&:after": {
                            borderColor: "white !important",
                          },
                          boxShadow: "none !important",
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "0  !important",
                          },
                          ".MuiSelect-outlined": {
                            padding: "4px 0",
                            paddingRight: "0 !important",
                            paddingLeft: "10px",
                            width: "91px",
                          },
                          ".MuiSvgIcon-root ": {
                            width: "20px",
                            fill: `${caseStatusColor(caseStatus)} !important`,
                          },
                        }}
                        onChange={caseStatusChangeHandler}
                      >
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                        <MenuItem value="settled">Settled</MenuItem>
                        <MenuItem value="on hold">On Hold</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              )} */}
            </Box>
          </Stack>

          <Box padding="0 32px">
            <Tabs
              // sx={{
              //   color: "#fff",
              //   // "& button:active": { background: "orange" },
              // }}
              value={tabValue}
              onChange={handleChange}
              sx={{ minHeight: "0 !important" }}
              indicatorColor="primary"
              // textColor="secondary"
              // aria-label="secondary tabs example"
            >
              {tabsData?.map((tabData, i) => (
                <Tab
                  icon={React.cloneElement(tabData.icon, {
                    color:
                      selectedTab === tabData.value ? "#0C80F3" : "#566875",
                  })}
                  iconPosition="start"
                  sx={{
                    minHeight: "0 !important",
                    "& svg": {
                      marginRight: "8px !important",
                    },
                  }}
                  disableRipple
                  key={i}
                  value={tabData.value}
                  label={tabData.label}
                  to={`/patient/${id}?tab=${tabData.value}`}
                  // to={`/case/${id}?tab=${tabData.value}`}
                />
              ))}
            </Tabs>
          </Box>
        </Box>

        {/* openCancelBillingBox && (
          <AlertDialog
            openModal={openCancelBillingBox}
            closeModal={modalCloseHandler}
            isLoading={loadingCaseFromSubsc}
            data={modalContentData}
            formSubmitFunction={cancelBillingSubscriptionHandler}
            color="error"
          />
        ) */}

        {/* TABS CONTENT  */}
        {/* TAB 1 OVERVIEW SECTION  */}
        {selectedTab === "overview" && (
          <OverviewTab
            caseDetail={caseDetail}
            allBodyPartsData={allBodyPartsData}
            billingActive={billingActive}
            setBillingActive={setBillingActive}
            setShowBillingPlan={setShowBillingPlan}
            showBillingPlan={showBillingPlan}
            caseAddedInPlan={caseAddedInPlan}
            setCaseAddedInPlan={setCaseAddedInPlan}
            // billingSubscription={billingSubscription}
            // loadingBilling={loadingBilling}
            // datesInRange={datesInRange}
            // setDatesInRange={setDatesInRange}
          />
        )}

        {/* TAB 2 CHAT SECTION CONTENT */}
        {selectedTab === "chat" && (
          <ChatTab caseDetail={caseDetail} loadingCaseDetail={loading} />
        )}

        {/* TAB 3 MORE INFO SECTION CONTENT */}
        {selectedTab === "more_info" && (
          <MoreInfoTab
            loading={loading}
            error={error}
            caseDetail={caseDetail}
          />
        )}

        {/* TAB 4 BILLING SECTION  CONTENT */}
        {selectedTab === "billing" && (
          <BillingTab
            caseDetail={caseDetail}
            setOpenDrawer={setOpenDrawer}
            openDrawer={openDrawer}
            showSuccessMessage={showSuccessMessage}
            setShowSuccessMessage={setShowSuccessMessage}
            setSuccessMessageContent={setSuccessMessageContent}
            successMessageContent={successMessageContent}
            timeDetails={timeDetails}
            setTimeDetails={setTimeDetails}
            warningModalContent={warningModalContent}
            setWarningModalContent={setWarningModalContent}
            openWarningModal={openWarningModal}
            setOpenWarningModal={setOpenWarningModal}
            editTimeObjectData={editTimeObjectData}
          />
        )}
      </Stack>
    </>
  );
};

export default CaseOverview;
