export const CREATE_ORGANIZATION_REQUEST = "CREATE_ORGANIZATION_REQUEST";
export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";
export const CREATE_ORGANIZATION_FAIL = "CREATE_ORGANIZATION_FAIL";
export const RESET_CREATE_ORGANIZATION = "RESET_CREATE_ORGANIZATION";

export const GET_ORGANIZATION_REQUEST = "GET_ORGANIZATION_REQUEST";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_FAIL = "GET_ORGANIZATION_FAIL";
export const RESET_GET_ORGANIZATION = "RESET_GET_ORGANIZATION";

export const GET_ORG_AGENTS_REQUEST = "GET_ORG_AGENTS_REQUEST";
export const GET_ORG_AGENTS_SUCCESS = "GET_ORG_AGENTS_SUCCESS";
export const GET_ORG_AGENTS_FAIL = "GET_ORG_AGENTS_FAIL";
export const RESET_GET_ORG_AGENTS = "RESET_GET_ORG_AGENTS";

export const GET_ORG_PATIENTS_REQUEST = "GET_ORG_PATIENTS_REQUEST";
export const GET_ORG_PATIENTS_SUCCESS = "GET_ORG_PATIENTS_SUCCESS";
export const GET_ORG_PATIENTS_FAIL = "GET_ORG_PATIENTS_FAIL";
export const RESET_ORG_PATIENTS = "RESET_ORG_PATIENTS";

export const UPDATE_ORGANIZATION_REQUEST = "UPDATE_ORGANIZATION_REQUEST";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_FAIL = "UPDATE_ORGANIZATION_FAIL";
export const RESET_UPDATE_ORGANIZATION = "RESET_UPDATE_ORGANIZATION";

export const GET_BILL_INVOICES_REQUEST = "GET_BILL_INVOICES_REQUEST";
export const GET_BILL_INVOICES_SUCCESS = "GET_BILL_INVOICES_SUCCESS";
export const GET_BILL_INVOICES_FAIL = "GET_BILL_INVOICES_FAIL";
export const RESET_GET_BILL_INVOICES = "RESET_GET_BILL_INVOICES";

export const GET_CURRENT_BILL_REQUEST = "GET_CURRENT_BILL_REQUEST";
export const GET_CURRENT_BILL_SUCCESS = "GET_CURRENT_BILL_SUCCESS";
export const GET_CURRENT_BILL_FAIL = "GET_CURRENT_BILL_FAIL";
export const RESET_GET_CURRENT_BILL = "RESET_GET_CURRENT_BILL";

export const GET_BILL_BREAKDOWN_REQUEST = "GET_BILL_BREAKDOWN_REQUEST";
export const GET_BILL_BREAKDOWN_SUCCESS = "GET_BILL_BREAKDOWN_SUCCESS";
export const GET_BILL_BREAKDOWN_FAIL = "GET_BILL_BREAKDOWN_FAIL";
export const RESET_BILL_BREAKDOWN = "RESET_BILL_BREAKDOWN";

export const GET_PAYMENT_INVOICE_REQUEST = "GET_PAYMENT_INVOICE_REQUEST";
export const GET_PAYMENT_INVOICE_SUCCESS = "GET_PAYMENT_INVOICE_SUCCESS";
export const GET_PAYMENT_INVOICE_FAIL = "GET_PAYMENT_INVOICE_FAIL";
export const RESET_PAYMENT_INVOICE = "RESET_PAYMENT_INVOICE";

export const GET_ORG_PAYMENT_DETAILS_REQUEST =
  "GET_ORG_PAYMENT_DETAILS_REQUEST";
export const GET_ORG_PAYMENT_DETAILS_SUCCESS =
  "GET_ORG_PAYMENT_DETAILS_SUCCESS";
export const GET_ORG_PAYMENT_DETAILS_FAIL = "GET_ORG_PAYMENT_DETAILS_FAIL";
export const RESET_ORG_PAYMENT_DETAILS = "RESET_ORG_PAYMENT_DETAILS";
