import { Box, Stack } from "@mui/material";
import React, { useEffect } from "react";
import OrgHomeCard from "../../OrgHomeCard";
import { HomeCard1Icon, HomeCard2Icon } from "../../../assets/constants";
import OrgHomePracticeTable from "../../OrgHomePracticeTable";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getOrgAgentsFunc,
  getOrgPatientsCountFunc,
} from "../../../actions/OrganizationActions";
import OrgUpdateCard from "../../OrgUpdateCard";

const HomeTab = () => {
  const dispatch = useDispatch();
  const getOrganization = useSelector((state) => state.getOrganization);
  const {
    loading: loadingOrg,
    error: errorOrg,
    organizationDetail,
  } = getOrganization;

  const getOrgPatientsCount = useSelector((state) => state.getOrgPatientsCount);
  const {
    loading: loadingOrgPatientsCount,
    error: errorOrgPatientsCount,
    orgPatientsCount,
  } = getOrgPatientsCount;

  const getOrgAgents = useSelector((state) => state.getOrgAgents);
  const {
    loading: loadingOrgAgents,
    error: errorOrgAgents,
    orgAgents,
  } = getOrgAgents;

  console.log("OOO", organizationDetail);

  const rows = organizationDetail?.organization?.agencies;

  useEffect(() => {
    dispatch(getOrgPatientsCountFunc());
    dispatch(getOrgAgentsFunc());
  }, []);

  return (
    <Stack gap="24px" height="100%">
      <Box display="flex" alignItems="center" gap="18px">
        <OrgHomeCard
          headerText="Total Practices"
          number={rows?.length}
          imgName={<HomeCard1Icon />}
          bgColor="rgba(243, 186, 12, 0.12)"
          color="#F1B62E"
          // chiplabel="Premium Plan"
          loading={loadingOrg}
        />
        <OrgHomeCard
          headerText="Total Team Members"
          number={orgAgents?.agents?.length}
          imgName={<HomeCard2Icon />}
          // chiplabel="Premium Plan"
          bgColor="rgba(243, 186, 12, 0.12)"
          color="#F1B62E"
          loading={loadingOrgAgents}
        />
        <OrgHomeCard
          headerText="Total Patients"
          number={orgPatientsCount?.patients_count}
          imgName={<HomeCard1Icon />}
          chiplabel="Across all practices"
          bgColor="rgba(12, 243, 170, 0.08)"
          color="#3DC583"
          loading={loadingOrgPatientsCount}
        />
      </Box>
      <Box display="flex" alignItems="flex-start" gap="24px" height="100%">
        <OrgHomePracticeTable />
        <OrgUpdateCard />
      </Box>
    </Stack>
  );
};

export default HomeTab;
